import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";

const apiURL = "module";

export const getAllModules = () => {
	return new Promise((resolve, reject) => {
		const requestUrl = `${url}/${apiURL}`;

		axios
			.get(requestUrl, {
				...AuthHeader,
			})
			.then((response: any) => {
				if (response && response.data) {
					resolve(response.data);
				} else {
					resolve(null);
				}
			})
			.catch((error: any) => {
				console.error("Error fetching modules: ", error);
				reject(error);
			});
	});
};

export const getModule = (tenant_role_key: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${apiURL}`, {
				...AuthHeader,
				params: {
					filter: {
						tenant_role_key: tenant_role_key,
					},
				},
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const createModule = (data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${url}/${apiURL}`, data, AuthHeader)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const updateModule = (id: any, data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.put(
				`${url}/${apiURL}`,
				{
					filter: { _id: id },
					data: data,
					options: { upsert: true, new: true },
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};
