import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "discussion";

export const getTaskComments = (tenantKey: any, taskId: any) => {
	return new Promise((resolve, reject) => {
		const requestURL = `${url}/${tenantKey}/${apiURL}`;

		axios
			.get(requestURL, {
				params: {
					filter: {
						task_id: taskId,
					},
				},
				...AuthHeader,
			})
			.then((_data: any) => {
				const filteredData = _data.data.filter(
					(comment: any) => comment.task_id && !comment.deliverable_id
				);
				resolve(filteredData);

				console.log("Task comments:", filteredData);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getDeliverableComments = (
	tenantKey: any,
	taskId: any,
	deliverableId: any
) => {
	return new Promise((resolve, reject) => {
		if (!taskId || !deliverableId) {
			resolve([]);
			return;
		}

		const requestUrl = `${url}/${tenantKey}/${apiURL}`;

		axios
			.get(requestUrl, {
				params: {
					filter: {
						task_id: taskId,
						deliverable_id: deliverableId,
					},
				},
				...AuthHeader,
			})
			.then((_data: any) => {
				const filteredData = _data.data.filter(
					(comment: any) =>
						comment.task_id === taskId &&
						comment.deliverable_id === deliverableId
				);

				resolve(filteredData);
				console.log("Deliverable comments:", filteredData);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addTaskComment = (
	tenantKey: any,
	taskId: any,
	user: any,
	comment: any
) => {
	return new Promise((resolve, reject) => {
		const requestURL = `${url}/${tenantKey}/${apiURL}`;

		axios
			.post(
				requestURL,
				{ tenant_key: tenantKey, task_id: taskId, user, comment },
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addDeliverableComment = (
	tenantKey: any,
	taskId: any,
	deliverableId: any,
	user: any,
	comment: any
) => {
	return new Promise((resolve, reject) => {
		const requestUrl = `${url}/${tenantKey}/${apiURL}`;

		axios
			.post(
				requestUrl,
				{
					tenant_key: tenantKey,
					task_id: taskId,
					deliverable_id: deliverableId,
					user,
					comment,
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const editComment = (tenantKey: any, commentId: any, comment: any) => {
	return new Promise((resolve, reject) => {
		const requestURL = `${url}/${tenantKey}/${apiURL}`;

		axios
			.put(
				requestURL,
				{
					filter: { _id: commentId },
					data: { comment },
					options: { upsert: true, new: true },
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const deleteComment = (tenantKey: any, commentId: any) => {
	return new Promise((resolve, reject) => {
		const requestURL = `${url}/${tenantKey}/${apiURL}`;

		axios
			.delete(requestURL, {
				params: {
					filter: {
						_id: commentId,
					},
				},
				...AuthHeader,
			})
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};
