import type { GetProps } from "antd";
import { Button, DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useContext, useState } from "react";
import { generateTimesheet } from "../services/api-server/timewriting";
import Emitter from "../utils/emitter";
import { HomeContext } from "../containers/Home";

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;
type ExportExcelButtonT = {
	title: string;
	project_id: string;
	tenant_key: string;
};

const disabledDate: RangePickerProps["disabledDate"] = (current) => {
	return current && current > dayjs().endOf("month");
};

function ExportExcelButton(props: ExportExcelButtonT) {
	const { userInfo }: any = useContext(HomeContext);

	const [openMonthPicker, setOpenMonthPicker] = useState<boolean>(false);
	const [date, setDate] = useState<dayjs.Dayjs | null>(null);

	const handleChooseMonth = (
		project_id: string,
		tenant_key: string,
		date: Dayjs | null,
		month: string
	) => {
		console.log("Month", month);
		if (date) {
			const firstDay = date
				.startOf("month")
				.hour(0)
				.minute(0)
				.second(0)
				.utc()
				.toISOString();
			const lastDay = date
				.endOf("month")
				.hour(23)
				.minute(59)
				.second(59)
				.utc()
				.toISOString();
			generateTimesheet(
				project_id,
				tenant_key,
				month,
				firstDay,
				lastDay,
				userInfo?.user
			)
				.then(() => {
					Emitter.emit("alert", {
						type: "success",
						message: `Timesheet for ${month} has been successfully exported.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				})
				.catch(() => {
					Emitter.emit("alert", {
						type: "alert",
						message: `Fail to generate timesheet.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				});
		} else {
			Emitter.emit("alert", {
				type: "error",
				message: "Please select a valid date.",
				description: "A date must be selected to generate the timesheet.",
				top: true,
				closeable: false,
				timeout: 3000,
			});
		}
	};

	return (
		<>
			<div>
				<Button onClick={() => setOpenMonthPicker(true)}>{props.title}</Button>
				<DatePicker
					value={date}
					open={openMonthPicker}
					picker="month"
					onChange={(date, dateString) => {
						setDate(date);
						handleChooseMonth(
							props.project_id,
							props.tenant_key,
							date,
							dateString as string
						);
					}}
					onOpenChange={(open) => {
						if (!open) {
							setDate(null);
						}
						setOpenMonthPicker(open);
					}}
					disabledDate={disabledDate}
					className={"custom-picker"}
					style={{ width: "0px", padding: "0px", borderStyle: "none" }}
					suffixIcon={null}
				/>
			</div>
		</>
	);
}

export default ExportExcelButton;
