import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Popconfirm } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../containers/Home";
import usePermission from "../../hooks/usePermission";
import Emitter from "../../utils/emitter";
import { getCrudaClass } from "../../utils/lookup_list";
import { sanitizeInput } from "../../utils/sanitizeInput";
import CustomForm from "../CustomForm";
import FormButtonSave from "../FormButtonSave";

type WellConfigModalT = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	wellRecord?: any | null;
	setWellRecord?: React.Dispatch<React.SetStateAction<boolean>> | null;
	activeRowKey?: string | null;
	extraData: any;
	redirectOnDelete?: () => void;
};

function WellConfigurationModal({
	open,
	setOpen,
	wellRecord,
	setWellRecord,
	activeRowKey,
	extraData,
	redirectOnDelete,
}: WellConfigModalT) {
	const context: any = useContext(HomeContext);
	const [formRef] = useForm();

	const [formReset, setFormReset] = useState<boolean>(false);
	const [initialForm, setInitialForm] = useState<any>(false);
	const [cancelPopover, setCancelPopover] = useState<boolean>(false);
	const [deletePopover, setDeletePopover] = useState<boolean>(false);
	const [deleteDisable, setDeleteDisable] = useState<boolean>(true);

	const hasDeleteWellPermission = usePermission("Delete.Well");

	useEffect(() => {
		wellRecord && open ? resetForm(wellRecord) : resetForm();
	}, [open]);

	const handleFormSave = (data: any) => {
		const sanitizedData = sanitizeInput(data);
		getCrudaClass("well").then((cruda: any) => {
			// Update Well
			if (activeRowKey) {
				const formFormat = cruda.getFormList("well");
				const formList: any = [];
				formFormat.forEach((element: any) => {
					if (element.children && !("name" in element)) {
						element.children.forEach((child_element: any) => {
							if (child_element.name) formList.push(child_element.name);
						});
					} else {
						if (element.name) formList.push(element.name);
					}
				});

				cruda
					.updateLookupItem(
						"well",
						null,
						activeRowKey,
						context?.currentTenantKey,
						sanitizedData,
						initialForm,
						{},
						context?.userInfo.user,
						formList
					)
					.then((updatedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Well has been successfully updated.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						resetForm(updatedItem, true);
						context.setCurrentWell(updatedItem);
						setWellRecord?.(updatedItem);
					})
					.catch((error: any) => {
						if (error?.status === 409) {
							Emitter.emit("alert", {
								type: "error",
								message: `This well already exists, please ensure the well name is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
			}
			// Insert Well
			else {
				cruda
					.addLookupItem(
						"well",
						null,
						sanitizedData,
						{},
						context?.currentTenantKey,
						null,
						context?.userInfo.user
					)
					.then((addedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Well has been successfully saved.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						resetForm(addedItem, true);
						Emitter.emit("reloadWells", null);
					})
					.catch((error: any) => {
						if (error?.status === 409) {
							Emitter.emit("alert", {
								type: "error",
								message: `This well already exists, please ensure the well name is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
			}
		});
	};

	const handleFormDelete = (data: any) => {
		getCrudaClass("well").then((cruda: any) => {
			cruda
				.deleteLookupItem(
					"task",
					null,
					activeRowKey,
					context?.currentTenantKey,
					context?.userInfo.user
				)
				.then(() => {
					Emitter.emit("alert", {
						type: "success",
						message: `Well has been successfully deleted.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					setOpen(false);
					setDeletePopover(false);
					resetForm();
					redirectOnDelete?.();
				})

				.catch(() => {
					Emitter.emit("alert", {
						type: "error",
						message: "Changes are not saved. Please try again.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				});
		});
	};

	const handleFormCancel = () => {
		setOpen(false);
		setCancelPopover(false);
	};

	const resetForm = (values: any = null, reset: any = true) => {
		values && setDeleteDisable(false);
		setFormReset(true);
		formRef.resetFields();
		formRef.setFieldsValue(values);
		setInitialForm(values);
	};

	return (
		<>
			<Modal
				closable={false}
				title={"Well configuration"}
				open={open}
				centered
				destroyOnClose
				footer={() => {
					return (
						<>
							{hasDeleteWellPermission && (
								<Popconfirm
									placement="topLeft"
									title={
										<div style={{ maxWidth: "300px", padding: "10px" }}>
											<>
												Permanently delete this well?
												<br />
												This action cannot be undone.
											</>
										</div>
									}
									open={deletePopover}
									onConfirm={handleFormDelete}
									overlayClassName="popconfirm-danger"
									onCancel={() => {
										setDeletePopover(false);
									}}
									okText="Delete"
									okType="danger"
									icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
									cancelText={<span>Cancel</span>}
									cancelButtonProps={{ ghost: true }}
								>
									<Button
										disabled={deleteDisable}
										className="ant-btn-secondary"
										style={{ marginRight: "auto" }}
										danger
										onClick={() => {
											setDeletePopover(true);
										}}
									>
										Delete
									</Button>
								</Popconfirm>
							)}

							<Popconfirm
								icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
								title={
									<div style={{ maxWidth: "300px", padding: "10px" }}>
										<>
											You have unsaved changes. Discard them or continue
											editing?
										</>
									</div>
								}
								open={cancelPopover}
								placement="topRight"
								cancelButtonProps={{ ghost: true }}
								cancelText="Discard changes"
								onCancel={() => {
									setCancelPopover(false);
									setTimeout(() => {
										handleFormCancel();
									}, 300);
								}}
								okText="Continue editing"
								okType="default"
								onConfirm={() => {
									setCancelPopover(false);
								}}
							>
								<Button
									className="ant-btn-secondary"
									onClick={() => {
										if (formReset) {
											handleFormCancel();
										} else {
											setCancelPopover(true);
										}
									}}
								>
									Cancel
								</Button>
							</Popconfirm>

							<FormButtonSave
								activeKey={activeRowKey}
								form={formRef}
								handleFormSave={handleFormSave}
								formReset={formReset}
								setFormReset={setFormReset}
							/>
						</>
					);
				}}
			>
				<div style={{ height: "50vh" }}>
					<CustomForm
						tabKey={"well"}
						formRef={formRef}
						setFormReset={setFormReset}
						dataOptions={extraData}
						activeRowKey={activeRowKey}
					/>
				</div>
			</Modal>
		</>
	);
}

export default WellConfigurationModal;
