import axios from "axios";
import { AuthHeader, url } from "../../utils/_exports";

const api = axios.create({
	baseURL: `${url}/timewriting`,
	...AuthHeader
});

export const getTimeEntries = (filter: any = {}) => {
	return new Promise((resolve, reject) => {
		api.get('/', {
			params: { filter: JSON.stringify(filter) },
		})
			.then((response: any) => {
				if (response && response.data) {
					resolve(response.data);
				} else {
					resolve(null);
				}
				console.log("response: ", response);
			})
			.catch((error: any) => {
				console.error("Error fetching time entries: ", error);
				reject(error);
			});
	});
};

export const createTimeEntry = (data: any) => {
	return new Promise((resolve, reject) => {
		// Sanitize the data based on time_type
		const sanitizedData = { ...data };

		if (sanitizedData.time_type === "duration") {
			sanitizedData.start_time = null;
			sanitizedData.end_time = null;
		} else if (sanitizedData.time_type === "startend") {
			sanitizedData.duration_hours = null;
			sanitizedData.duration_mins = null;
		}

		api
			.post(`/`, sanitizedData, AuthHeader)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				console.error("Error occurred:", e); // Log the error for debugging
				const errorMessage =
					e?.response?.data?.error || "An unexpected error occurred";
				reject(errorMessage);
			});
	});
};

export const updateTimeEntry = (
	/* Parameters have to be in an ascending order when passing data */
	uuid: any,
	data: any,
	prevData: any
	// formList: any
) => {
	return new Promise((resolve, reject) => {

		// Exclude `_id` and `uuid` from the data payload
		const sanitizedData = { ...data };
		delete sanitizedData.uuid; // Remove `uuid` (preventing duplication)
		delete sanitizedData._id; // Remove `_id`(preventing duplication)

		if (sanitizedData.time_type === "duration") {
			sanitizedData.start_time = null;
			sanitizedData.end_time = null;
		} else if (sanitizedData.time_type === "startend") {
			sanitizedData.duration_hours = null;
			sanitizedData.duration_mins = null;
		}

		api
			.put(
				`/`,
				{
					filter: { _id: uuid },
					data: sanitizedData,
					options: { upsert: true, new: true },
					prevData: prevData,
					// formList,
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const deleteTimeEntry = (uuid: any) => {
	return new Promise((resolve, reject) => {
		api
			.delete(`/`, {
				params: {
					_id: uuid,
				},
				...AuthHeader,
			})
			.then((_data) => {
				console.log("Deleting data: ", _data);
				resolve(true);
			})
			.catch((e: any) => {
				console.error("Error deleting time entry:", e); // Log the error
				reject(e);
			});
	});
};

export const updateUserWeeklyApproval = (
	project_id: string,
	email: string,
	properties: {
		reviewer?: string;
		firstDayOfWeek: string;
		lastDayOfWeek: string;
	},
	status: string,
	comment?: string
) => {
	return new Promise((resolve, reject) => {
		let updatedData: {
			status: string;
			comment?: string;
			submitted_date?: string;
			reviewed_date?: string;
			reviewed_by?: string;
		} = { status: status };

		if (status === "approved" || status === "rejected") {
			updatedData = {
				...updatedData,
				comment: comment,
				reviewed_date: new Date().toISOString(),
				reviewed_by: properties.reviewer
			}
		}
		if (status === "resubmitted") {
			updatedData = {
				...updatedData,
				submitted_date: new Date().toISOString()
			}
		}

		const data = {
			filter: {
				email,
				project_id,
				date: {
					$gte: properties.firstDayOfWeek,
					$lte: properties.lastDayOfWeek,
				},
			},
			data: updatedData,
			option: { upsert: false },
		};

		api
			.put("/", data, AuthHeader)
			.then((response) => {
				console.log(
					"User weekly approval status updated successfully: ",
					response.data
				);
				resolve(response.data);
			})
			.catch((error) => {
				console.error("Error updating user weekly approval status: ", error);
				reject(error);
			});
	});
};

export const generateTimesheet = (
	project_id: string,
	tenant_key: string,
	month: string,
	first_day: string,
	last_day: string,
	user: any
) => {
	return new Promise((resolve, reject) => {
		const data = {
			project_id,
			tenant_key,
			month,
			first_day,
			last_day,
			generated_by: user.email,
			generated_on: new Date(),
		};
		api
			.post('/generate_excel', data, { ...AuthHeader, responseType: "blob" })
			.then((response: any) => {
				// Extract file name from content-disposition header
				const contentDisposition = response.headers["content-disposition"];
				let filename = "Timesheet.xlsx"; // Default file name
				if (typeof contentDisposition === "string") {
					const filenameMatch = contentDisposition.match(/filename="(.+)"/);
					if (filenameMatch) {
						filename = filenameMatch[1];
						console.log("Extracted filename: ", filename);
					} else {
						console.log("Filename not found in content-disposition header.");
					}
				} else {
					console.log("content-disposition header is not a string.");
				}
				const blob = new Blob([response.data], {
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				});
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename);

				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				// Clean up the object URL to prevent memory leak
				window.URL.revokeObjectURL(url);

				resolve("Download successful");
			})
			.catch((error) => {
				console.error("Error downloading file:", error);
				reject(error);
			});
	});
};
