import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";

interface TooltipI {
	title: string;
	placement:
		| "top"
		| "bottom"
		| "left"
		| "right"
		| "topLeft"
		| "topRight"
		| "bottomLeft"
		| "bottomRight"
		| "leftTop"
		| "leftBottom"
		| "rightTop"
		| "rightBottom";
}

interface TabButtonProps {
	identifier: string;
	label: string;
	icon: any;
	activePage: string;
	setActivePage: React.Dispatch<React.SetStateAction<string>>;
	tooltip?: TooltipI;
}

function TabButton({
	identifier,
	label,
	icon,
	activePage,
	setActivePage,
	tooltip,
}: TabButtonProps) {
	return (
		<>
			<Tooltip {...tooltip}>
				<div
					className={activePage === identifier ? "project-active-button" : ""}
					style={{
						display: "flex",
						padding: "4px 15px",
						gap: "8px",
						cursor: "pointer",
					}}
					onClick={() => {
						setActivePage(identifier);
					}}
				>
					{icon}
					<span>{label}</span>
				</div>
			</Tooltip>
		</>
	);
}

export default TabButton;
