import { Divider, Flex, Space, Tooltip } from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useTimewriting } from "../contexts/TimewritingContext";
import { GetAntIcon } from "../utils/ant_icons";
import { HomeContext } from "./Home";

function TimesheetSummaryHeader({
	data,
	project_id,
	customWeekLabel,
	customMonthLabel,
}: {
	data: any;
	project_id?: string;
	customWeekLabel?: string;
	customMonthLabel?: string;
}) {
	const { currentWeekStart, currentWeekEnd, allTimeEntries } = useTimewriting();
	const { userInfo }: any = useContext(HomeContext);

	const [weeklyHours, setWeeklyHours] = useState<string>("");
	const [monthlyHours, setMonthlyHours] = useState<string>("");

	const convertToHoursAndMinutesString = (durationMin: number) => {
		return `${Math.floor(durationMin / 60)}h ${durationMin % 60}m`;
	};

	const getMonthlyDurationMin = (data: any[]) => {
		let monthlyDurationMin = 0;
		data.forEach((entry: any) => {
			if (dayjs(entry.date).month() === currentWeekStart.month()) {
				monthlyDurationMin += entry.duration_hours * 60 + entry.duration_mins;
			}
		});
		return monthlyDurationMin;
	};

	const getWeeklyDurationMin = (data: any[]) => {
		let weeklyDurationMin = 0;
		data.forEach((entry: any) => {
			if (
				dayjs(entry.date) >= currentWeekStart &&
				dayjs(entry.date) <= currentWeekEnd
			) {
				weeklyDurationMin += entry.duration_hours * 60 + entry.duration_mins;
			}
		});
		return weeklyDurationMin;
	};

	useEffect(() => {
		let monthlyDurationMin = 0;
		let weeklyDurationMin = 0;

		let timeEntriesAll: any[] = [];
		if (project_id) {
			timeEntriesAll = allTimeEntries.filter(
				(entry: any) =>
					entry.project_id === project_id &&
					["submitted", "resubmitted", "approved", "rejected"].includes(
						entry.status
					)
			);
		} else {
			timeEntriesAll = allTimeEntries.filter(
				(entry: any) => entry.email === userInfo.user.email
			);
		}

		const userTimeEntries = data.flatMap((item: any) => item.time_entries);

		monthlyDurationMin = getMonthlyDurationMin(timeEntriesAll);
		weeklyDurationMin = getWeeklyDurationMin(userTimeEntries);

		const stringMonthlyHours =
			convertToHoursAndMinutesString(monthlyDurationMin);
		const stringWeeklyHours = convertToHoursAndMinutesString(weeklyDurationMin);

		setMonthlyHours(stringMonthlyHours);
		setWeeklyHours(stringWeeklyHours);
	}, [allTimeEntries, currentWeekStart, currentWeekEnd, data, project_id]);

	return (
		<Flex align="center" gap="small" justify="flex-end">
			<Flex gap="small">
				<span>{customWeekLabel ? customWeekLabel : "Weekly hours"}</span>
				<span>=</span>
				<strong>{weeklyHours}</strong>
			</Flex>
			<span className="timesheet-summary-header-divider">|</span>
			<Flex gap="small">
				<Tooltip
					title={
						"Monthly hours is based on month in first day of selected week."
					}
				>
					<span style={{ color: "grey" }}>{GetAntIcon("question3")}</span>
				</Tooltip>
				<span>{customMonthLabel ? customMonthLabel : "Monthly hours"}</span>
				<span>=</span>
				<strong>{monthlyHours}</strong>
			</Flex>
		</Flex>
	);
}

export default TimesheetSummaryHeader;
