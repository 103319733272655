import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
	Button,
	Dropdown,
	Flex,
	Modal,
	Popconfirm,
	Space,
	Tooltip,
} from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { HomeContext } from "../../containers/Home";
import { useTimeEntry } from "../../contexts/TimeEntryContext";
import { useTimewriting } from "../../contexts/TimewritingContext";
import { getTimeEntries } from "../../services/api-server/timewriting";
import { GetAntIcon } from "../../utils/ant_icons";
import CustomForm from "../CustomForm";
import FormButtonSave from "../FormButtonSave";

interface TimeEntryType {
	tenant_key: string;
	project_id: string;
	date: string;
	software_switch: boolean;
	software?: string;
	duration_hours: number;
	duration_mins: number;
	description: string;
}

interface TimeEntryModalRevampProps {
	timeEntry: TimeEntryType | any;
	openModal: boolean;
	showModal: (open: boolean) => void;
}

function DeleteButton({ handleFormDelete }: { handleFormDelete: () => void }) {
	const [openPopover, setOpenPopover] = useState(false);
	return (
		<Popconfirm
			open={openPopover}
			placement="topLeft"
			cancelText="Cancel"
			okText="Delete"
			okType="danger"
			icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
			overlayClassName="popconfirm-danger"
			cancelButtonProps={{ ghost: true }}
			title={
				<div style={{ maxWidth: "300px", padding: "10px" }}>
					Permanently delete this time entry?
					<br />
					This action cannot be undone.
				</div>
			}
			onConfirm={() => {
				setOpenPopover(false);
				handleFormDelete();
			}}
			onCancel={() => setOpenPopover(false)}
		>
			<Button
				className="ant-btn-secondary"
				onClick={() => setOpenPopover(true)}
				style={{ marginRight: "auto" }}
				danger
			>
				Delete
			</Button>
		</Popconfirm>
	);
}

function CancelButton({
	handleFormCancel,
	formReset,
}: {
	handleFormCancel: () => void;
	formReset: boolean;
}) {
	const [openPopover, setOpenPopover] = useState(false);

	const handleCancel = () => {
		setOpenPopover(false);
		handleFormCancel();
	};

	return (
		<Popconfirm
			open={openPopover}
			placement="topRight"
			cancelText="Discard changes"
			okText="Continue editing"
			okType="default"
			icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
			cancelButtonProps={{ ghost: true }}
			title={
				<div style={{ maxWidth: "300px", padding: "10px" }}>
					You have unsaved changes. Discard them or continue editing?
				</div>
			}
			onConfirm={() => setOpenPopover(false)}
			onCancel={() => handleCancel()}
		>
			<Button
				className="ant-btn-secondary"
				onClick={() => {
					if (formReset) {
						handleFormCancel();
					} else {
						setOpenPopover(true);
					}
				}}
			>
				Cancel
			</Button>
		</Popconfirm>
	);
}

function TimeEntryHistoryDropdown({
	activeTimeEntry,
	handleViewHistory,
}: {
	activeTimeEntry: any;
	handleViewHistory: (timeEntry: any) => void;
}) {
	const [timewritingHistory, setTimewritingHistory] = useState<any[]>([]);

	const fetchTimewritingHistory = async (activeTimeEntry: any) => {
		const filter = {
			project_id: activeTimeEntry.project_id,
			time_entry_code: activeTimeEntry.time_entry_code ?? null,
		};
		const timewritingHistory = await getTimeEntries(filter);
		console.log("timewritingHistory", timewritingHistory, filter);
		const filteredTimeEntry = (timewritingHistory as any[])
			.map((i: any) => {
				return {
					...i,
					label: `${dayjs(i.created_on).format("DD MMM YYYY hh:mm:ss A")}`,
					key: i._id,
				};
			})
			.reverse();
		setTimewritingHistory(filteredTimeEntry);
	};

	useEffect(() => {
		if (activeTimeEntry) {
			fetchTimewritingHistory(activeTimeEntry);
		}
	}, [activeTimeEntry]);

	return (
		<Flex justify="space-between" align="center" style={{ width: "100%" }}>
			Time Entry
			{activeTimeEntry && timewritingHistory.length > 1 && (
				<Tooltip title="View revisions of your time entry.">
					<Dropdown
						menu={{
							items: timewritingHistory,
							onClick: async (ev: any) => {
								const _allTimeEntries: any = await getTimeEntries({
									_id: ev.key,
								});
								handleViewHistory(_allTimeEntries[0]);
							},
						}}
						trigger={["click"]}
					>
						<a onClick={(e) => e.preventDefault()}>
							<Space>
								{GetAntIcon("history")}
								{dayjs(activeTimeEntry.created_on).format(
									"DD MMM YYYY hh:mm:ss A"
								)}
								{GetAntIcon("downarrow")}
							</Space>
						</a>
					</Dropdown>
				</Tooltip>
			)}
		</Flex>
	);
}

function TimeEntryModalRevamp({
	timeEntry,
	openModal,
	showModal,
}: TimeEntryModalRevampProps) {
	const [formRef] = useForm();
	const { allTenantSoftware, addTimeEntry, updateTimeEntry, deleteTimeEntry } =
		useTimeEntry();
	const { allTenantProjects } = useTimewriting();
	const { tenantData }: any = useContext(HomeContext);

	const [activeRecord, setActiveRecord] = useState<any>(null);
	const [formReset, setFormReset] = useState<boolean>(false);
	const [filteredDataOptions, setFilteredDataOptions] = useState<any>(null);

	const resetForm = (values: any = null) => {
		console.log("Form reset", values);
		if (formRef) {
			setFormReset(true);
			formRef.resetFields();
			formRef.setFieldsValue(values);
			setActiveRecord(values);
		}
	};

	const handleFormCancel = () => {
		console.log("handleFormCancel");
		showModal(false);
	};

	const handleFormSave = async (values: any) => {
		console.log("activeRecord", activeRecord);
		if (!activeRecord.status || activeRecord.status === "rejected") {
			values.time_entry_code = activeRecord.time_entry_code ?? uuidv4();
			values.status = "updated";
			console.log("Create data!", values);
			addTimeEntry(values);
			showModal(false);
		} else {
			console.log("Updating data!", values);
			updateTimeEntry(activeRecord._id, values, activeRecord);
			showModal(false);
		}
	};

	const handleFormDelete = async () => {
		console.log("Deleting data!");
		deleteTimeEntry(activeRecord?._id);
		showModal(false);
	};

	const handleReadonly = (activeRecord: any) => {
		if (["approved", "resubmitted", "submitted"].includes(activeRecord?.status))
			return true;
		return false;
	};

	// filter softwares based on project software
	const filterSoftware = (projects: any, softwares: any) => {
		const filteredSoftwares = softwares.filter((software: any) => {
			return projects.some((project: any) =>
				project?.software.includes(software._id)
			);
		});
		return filteredSoftwares;
	};

	useEffect(() => {
		if (!openModal) resetForm(); // always reset form when modal is closed
		else {
			if (timeEntry) resetForm(timeEntry);
			setFilteredDataOptions({
				tenants: tenantData,
				projects: allTenantProjects,
				softwares: filterSoftware(allTenantProjects, allTenantSoftware),
			});
		}
	}, [openModal]);

	const handleViewHistory = (timeEntry: any) => {
		setActiveRecord(timeEntry);
		resetForm(timeEntry);
	};

	return (
		<>
			<Modal
				closable={false}
				title={
					<TimeEntryHistoryDropdown
						activeTimeEntry={activeRecord}
						handleViewHistory={handleViewHistory}
					/>
				}
				open={openModal}
				centered
				destroyOnClose
				footer={() => (
					<>
						{timeEntry && !handleReadonly(activeRecord) && (
							<DeleteButton handleFormDelete={handleFormDelete} />
						)}
						<CancelButton
							handleFormCancel={handleFormCancel}
							formReset={formReset}
						/>
						<FormButtonSave
							form={formRef}
							activeKey={activeRecord?._id}
							handleFormSave={handleFormSave}
							formReset={formReset}
							setFormReset={setFormReset}
						/>
					</>
				)}
			>
				<div style={{ height: "50vh" }}>
					<CustomForm
						readOnly={handleReadonly(activeRecord)}
						activeRecord={activeRecord}
						activeRowKey={activeRecord?._id}
						setFormReset={setFormReset}
						formRef={formRef}
						tabKey={"timewriting"}
						dataOptions={{
							projects: allTenantProjects,
							tenants: tenantData,
							softwares: allTenantSoftware,
						}}
						filteredDataOptions={filteredDataOptions}
					/>
				</div>
			</Modal>
		</>
	);
}

export default TimeEntryModalRevamp;
