import React from "react";
import LookupMissingTooltip from "../components/LookupMissingTooltip";
import { Tag } from "antd";
import { countryList } from "./countryCodes";
import { convertToDMS } from "./utils";
import { info } from "console";

const lookupCases = [
	"region",
	"country",
	"rig_type",
	"client",
	"company",
	"business_unit",
	"well_status",
	"well_status_type",
	"well_purpose",
	"well_purpose_type",
	"operation_type",
	"latitude",
	"longitude",
];

const infoKeySpecialCases = [
	"latitude",
	"longitude",
	"roles",
	"team_roles",
	"team_roles_form",
];

export const setLookupData = (
	// data: List of lookup data containing details for fields/properties such as region, country, etc.
	data: any = [],
	// baseData: an object of current data where I want to find the lookup.
	baseData: any,
	// infoKey: The specific field/property name (like region or country) in baseData that needs a lookup.
	infoKey: any,
	// propKey and type: Optional parameters, likely used for cases requiring more specific lookup handling.
	propKey: any = null,
	type: any = null
) => {
	let content: any = (
		<LookupMissingTooltip userRole={data?.userInfo?.userRole} />
	);
	if (
		(!baseData[infoKey] || baseData[infoKey] == "") &&
		!infoKeySpecialCases.includes(infoKey)
	) {
		if (!type) {
			return "-";
		} else {
			return "";
		}
	}
	if (data) {
		let found: any = null;
		switch (infoKey) {
			case "client":
			case "company":
			case "well_operator":
				found = data?.company?.find((element: any) => {
					return element.uuid === baseData[infoKey];
				});
				if (found) {
					content = found.name;
				}
				break;
			case "region":
				found = data?.region?.find((element: any) => {
					return element.uuid === baseData[infoKey];
				});
				if (found) {
					content = found.name;
				}
				break;
			case "country":
				found = countryList?.find((element: any) => {
					return baseData.country === element["alpha-3"];
				});
				if (found) {
					content = found.name;
				} else {
					content = "Aberdeen";
				}
				break;
			case "operating_office":
				found = data?.operating_office?.find((element: any) => {
					return element.uuid === baseData[infoKey];
				});
				if (found) {
					content = found.name;
				}
				break;
			case "project_tags":
				let tags = baseData.project_tags.map((uuid: any) => {
					let found = data?.project_tags?.find((element: any) => {
						return element.uuid == uuid;
					});
					return found;
				});

				return tags.map((tag: any) => {
					if (tag) {
						return (
							<Tag
								color={tag.color}
								key={tag.name}
								style={{
									cursor: "default",
									whiteSpace: "normal",
									wordWrap: "break-word",
								}}
							>
								{tag.name}
							</Tag>
						);
					}
				});
			case "rig_type":
				if (infoKey == "rig_type" && !baseData[infoKey]) content = "Drilling";
				break;
			case "business_unit":
				found = data?.strategic_business_unit?.find((element: any) => {
					return element.uuid == baseData[infoKey];
				});
				if (found) {
					content = found.name;
				}
				break;
			case "latitude":
				console.log(baseData);
				let latitudeValue: any = baseData.latlong[0] || null;
				content = convertToDMS(latitudeValue, "latitude");
				break;
			case "longitude":
				console.log(baseData);
				let longtitudeValue: any = baseData.latlong[1] || null;
				content = convertToDMS(longtitudeValue, "longitude");
				break;
			case "team_roles":
				if ("roles" in data) {
					const tempRoleData = [...data?.["roles"]];
					const findProjectManager = data?.["roles"].find(
						(element: any) => element._id == "project_manager"
					);
					if (!findProjectManager) {
						tempRoleData?.unshift({
							name: "Project manager",
							_id: "project_manager",
						});
					}
					found = tempRoleData?.filter((element: any) => {
						return baseData?.role_id == element._id;
					});
					if (found?.length > 0) {
						content = found[0].name;
					}
				}
				break;

			case "roles":
				if (baseData?.[propKey].length > 0) {
					content = [];
					found = data?.[infoKey]?.filter((element: any) => {
						return baseData?.[propKey]?.includes(element._id);
					});
					if (found?.length > 0) {
						content = found;
					}
					if (baseData?.[propKey].includes("project_manager")) {
						content.unshift({
							name: "Project manager",
							_id: "project_manager",
						});
					}
					break;
				} else {
					content = [];
					break;
				}

			case "project_template":
				found = data?.well_programme_template?.find((element: any) => {
					return element._id === baseData[infoKey];
				});
				if (found) {
					content = found.name;
				}
				break;

			default:
				if (lookupCases.includes(infoKey)) {
					found = data?.[infoKey]?.find((element: any) => {
						let baseID = element.uuid || element._id;
						return baseID === baseData[infoKey];
					});
					if (found) {
						content = found.name;
					}
				} else if (baseData[infoKey]) {
					if (infoKey.includes("date")) {
						var date = new Date(baseData[infoKey]);
						content = date.toLocaleDateString("en-GB", {
							year: "numeric",
							month: "short",
							day: "numeric",
						});
					} else content = baseData[infoKey];
				}

				break;
		}
	}
	return content;
};
