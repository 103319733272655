import { useEffect, useState } from "react";
import { getUser } from "../services/api-server/user";
import { getInitials } from "../utils/utils";
import { Image } from "antd";

function UserImage({ email, size = 64 }: { email: string; size?: number }) {
	const [user, setUser] = useState<any>(null);
	useEffect(() => {
		getUser(email).then((response: any) => {
			console.log("user: ", response);
			setUser(response[0]);
		});
	}, [email]);
	return user?.profile_picture ? (
		<Image
			style={{
				border: "1px solid #d9d9d9",
				borderRadius: "180px",
				minWidth: `${size}px`,
				maxWidth: `${size}px`,
			}}
			width={size}
			height={size}
			preview={false}
			src={user.profile_picture}
		/>
	) : (
		<div
			className="profile-picture-initials"
			style={{
				width: `${size}px`,
				minWidth: `${size}px`,
				maxWidth: `${size}px`,
				height: `${size}px`,
			}}
		>
			<span style={{ fontSize: `${size / 3}px` }}>
				{getInitials(user?.name, 0)}
			</span>
		</div>
	);
}

export default UserImage;
