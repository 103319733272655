import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Popconfirm } from "antd";
import { useForm } from "antd/es/form/Form";
import { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../containers/Home";
import usePermission from "../../hooks/usePermission";
import Emitter from "../../utils/emitter";
import { getCrudaClass } from "../../utils/lookup_list";
import CustomForm from "../CustomForm";
import FormButtonSave from "../FormButtonSave";

function TenantModuleConfigModal(props: any) {
	const context: any = useContext(HomeContext);

	// form related
	const [formRef] = useForm();
	const [formReset, setFormReset] = useState<boolean>(false);
	const [initialForm, setInitialForm] = useState<any>(null);
	const [activeRowKey, setActiveRowKey] = useState<any>(null);
	const [cancelPopover, setCancelPopover] = useState<boolean>(false);
	const [deletePopover, setDeletePopover] = useState<boolean>(false);
	const [currentTenant, setCurrentTenant] = useState<any>(
		context.currentTenantData
	);

	const [tenantModuleInfo, setTenantModuleInfo] = useState<any>(null);

	// console.log("props.extraData", props.extraData);

	useEffect(() => {
		if (props.tenantModuleRecord) {
			resetForm(props.tenantModuleRecord);
			setActiveRowKey(props.tenantModuleRecord._id);
			setTenantModuleInfo(props.tenantModuleRecord);

			console.log("props.tenantModuleRecord", props.tenantModuleRecord);
		}
	}, [props.tenantModuleRecord]);

	const resetForm = (values: any = null, reset: any = true) => {
		// if `formRef` exists and attached to the `CustomForm`
		if (formRef) {
			// `setFormReset` to true
			setFormReset(true);
			// if `reset` is true, reset the form fields in `formRef`
			reset && formRef.resetFields();
			// if `values` is present, populate the form fields with `values`
			formRef.setFieldsValue(values ? { ...values } : null);
			// set initial form values to `values`
			setInitialForm(values);
		}
	};

	const handleFormCancel = () => {
		// Close the modal
		props.setOpen(false);
		// Reset `cancelPopover` state to false
		setCancelPopover(false);
		// Calls `handleFormUpdating` method from context, indicating that the form is no longer updating
		context?.handleFormUpdating(false, props.activeRowKey);
		// if `tenantModuleInfo` is populated
		if (tenantModuleInfo) {
			// Reset the form with `tenantModuleInfo` data
			resetForm(tenantModuleInfo);
			setTenantModuleInfo(tenantModuleInfo);
		} else {
			resetForm();
		}
	};

	const handleFormSave = (values: any) => {
		// Gets an array of all keys in the `values` object
		Object.keys(values)
			// Loops through each key in the object
			.forEach((key) => {
				// Checks if the value of the key is of data type "string"
				if (typeof values[key] == "string") {
					// Remove any leading or trailing whitespace from current value
					values[key] = values[key].trim();
				}
			});
		// Retrieves a CRUD handler for `tenant_module_configuration` file in CRUDA directory
		getCrudaClass("tenant_module_configuration").then((cruda: any) => {
			console.log("activeRowKey", activeRowKey);
			// if `activeRowKey` (selected object) is present
			if (activeRowKey) {
				console.log(
					"Updating tenant module data of `activeRowKey`: ",
					activeRowKey
				);
				// call `getFormList` method from `cruda` object
				const form = cruda.getFormList("tenant_module_configuration");
				// Create an empty array `formList`
				const formList: any = [];
				// Loop through each form fields in the `form` array
				form.forEach((element: any) => {
					// if current `element` has `children` and does not have a `name` property
					if (element.children && !("name" in element)) {
						// Loop through each child element of the current `element`
						element.children.forEach((child_element: any) => {
							// if current `child_element` has a `name`property, push it into `formList`
							if (child_element.name) formList.push(child_element.name);
						});
					} else {
						// if current `element` has a `name` property, push it into `formList`
						if (element.name) formList.push(element.name);
					}
				});

				console.log(
					"props.option",
					props.option,
					"activeRowKey",
					activeRowKey,
					"values: ",
					values,
					"initialForm: ",
					initialForm,
					"formList: ",
					formList
				);

				cruda
					.updateLookupItem(
						"tenant_module_configuration", // lookupKey
						props?.option, // formOption
						activeRowKey,
						context?.currentTenantKey, // current tenant key
						values, // updated values
						initialForm, // initial values
						{},
						props.user,
						formList
					)
					.then((updatedItem: any) => {
						console.log("updatedItem: ", updatedItem);
						Emitter.emit("alert", {
							type: "success",
							message: `Tenant module has been successfully updated.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						// Call `handleFormUpdating` method from context, indicating that the form is no longer updating
						context?.handleFormUpdating(false, activeRowKey);
						// Reset the form with the updated item
						resetForm(values, true);

						const newTenantModulesData =
							// Iterate through the 'modules' array to find the module item with the matching '_id'
							tenantModuleInfo?.modules?.map((item: any) => {
								// Check if the current item has the same '_id' as the updating item
								if (item._id === updatedItem._id) {
									// If they match, return the updated item to replace the old one
									return updatedItem;
								}
								// If they don't match, return the original item (no change)
								return item;
							});

						console.log("newTenantModuleData: ", newTenantModulesData);

						const newRecord = {
							...tenantModuleInfo,
							modules: newTenantModulesData,
						};

						console.log("newRecord: ", newRecord);

						Emitter.emit("resetForm", newRecord);
						Emitter.emit("forceUpdate", {
							data: { modules: newTenantModulesData },
						});
					})
					.catch((error: any) => {
						if (error?.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This Tenant Module already exists, please ensure the Tenant Module ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
			} else {
				console.log("Adding tenant module data", props);

				const updatedModulesData =
					tenantModuleInfo?.modules?.filter(
						// Filter out (removes) current module item that has the same `_id` as `activeRowKey`
						(item: any) => item._id !== activeRowKey
						// sets `updatedModulesData` to an empty array if there are no modules
					) || [];

				console.log("updatedModulesData: ", updatedModulesData);

				console.log("props.option", props.option, "values: ", values);

				cruda
					.addLookupItem(
						"tenant_module_configuration",
						props?.option,
						values,
						context?.currentTenantKey,
						null,
						props.user
					)
					.then((addedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Tenant Module has been successfully saved.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						context?.handleFormUpdating(false, "new");

						resetForm(addedItem, true);

						setActiveRowKey(
							addedItem?.uuid ||
								addedItem?.group_uuid ||
								addedItem?.tag_uuid ||
								addedItem?._id
						);

						const newRecord = { ...tenantModuleInfo };
						console.log("newRecord", newRecord);

						newRecord.modules = [...updatedModulesData, addedItem];

						console.log("updatedModulesData", updatedModulesData);
						props.setTenantModuleRecord((prev: any) => ({
							...prev,
							...addedItem,
							modules: newRecord.modules,
						}));
						Emitter.emit("resetForm", newRecord);
						Emitter.emit("forceUpdate", {
							data: { modules: newRecord.modules },
						});
					})
					.catch((error: any) => {
						if (error.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This Tenant Module already exists, please ensure the Tenant Module ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
			}
		});
	};

	const handleFormDelete = (values: any) => {
		// Signals that the form is performing a delete action on the item identified by `activeRowKey` while setting the updating state to `false`
		context?.handleFormUpdating(false, activeRowKey);
		// Console log the deleting operation item
		console.log("Deleting Operation Item: ", activeRowKey);

		// Retrieves a CRUD handler for operations
		getCrudaClass("tenant_module_configuration")
			// If the handler is available, pass the `cruda` object into the callback function
			.then((cruda: any) => {
				cruda
					// Calls custom method `deleteLookupItem` with details to delete the specific item
					.deleteLookupItem(
						"operation", // The lookup type (type of data to delete)
						props?.option, // Property inside `props`
						activeRowKey, // UID for the row or record to be deleted
						context?.currentTenantKey, // Check for `currentTenantKey`
						{ project_code: context?.currentProject?.project_code },
						props.user // User performing the deletion
					)
					// If the `Promise` (operation) is successful
					.then(() => {
						// Shows a success alert with a message
						Emitter.emit("alert", {
							type: "success", // Type of alert ('success')
							message: `Operation has been successfully deleted.`, // The message to show in the alert
							description: "", // Additional description (empty in this case)
							top: true, // Position of the alert (top of the screen)
							closeable: false, // Prevents the alert from being closed manually
							timeout: 3000, // Alert will disappear after 3 seconds
						});

						// Closes the modal
						props.setOpen(false);

						// Closes the delete confirmation popover
						setDeletePopover(false);

						// Resets the active row key, removing selection
						setActiveRowKey(null);

						const newOperationData =
							// Checks the `operation_data` array, which may or may not contain the data
							tenantModuleInfo?.operation_data
								// `fitler()` creates a new array by filtering out the items that do not match the condition below
								?.filter(
									// Check for any `item._id` that does not match the `activeRowKey` and keep them in the new array
									(item: any) => item._id !== activeRowKey
								);

						// Update the `operationInfo` with the new data after deletion
						const newRecord = {
							...tenantModuleInfo,
							operation_data: newOperationData,
						};

						console.log("Updated Operation Data After Deletion:", newRecord);

						// Emit events to update the form and the data in the UI
						Emitter.emit("resetForm", newRecord);
						Emitter.emit("forceUpdate", {
							data: { operation_data: newOperationData },
						});

						// Reset the form since the operation was deleted
						resetForm(null, true);

						// If a callback function `redirectOnDelete` is provided in the props, it will redirect the user
						if (props.redirectOnDelete) {
							props.redirectOnDelete();
						}
					})
					// If the `Promise` fails (an error occurs)
					.catch(() => {
						// Shows an error alert if something goes wrong
						Emitter.emit("alert", {
							type: "error", // The type of the alert, in this case, 'error'
							message: "Changes are not saved. Please try again.", // The message to show in the alert
							description: "", // Additional description (empty in this case)
							top: true, // Position of the alert (top of the screen)
							closeable: false, // Prevents the alert from being closed manually
							timeout: 3000, // Alert will disappear after 3 seconds
						});
					})
					// Finally block (runs regardless of success or failure)
					.finally(() => {
						// Emit a "loading" event to hide the loading indicator (set it to false)
						// Emitter.emit("loading", false);
					});
			});
	};

	return (
		<>
			{/* Tenant - module configuration - Popup Modal */}
			<Modal
				className="tenant-module-config-modal"
				closable={false}
				title={"Tenant - module configuration"}
				open={props.open}
				centered
				destroyOnClose
				footer={() => {
					return (
						<>
							<Popconfirm
								placement="topLeft"
								title={
									<div style={{ maxWidth: "300px", padding: "10px" }}>
										<>
											Permanently delete this well operation?
											<br />
											This action cannot be undone.
										</>
									</div>
								}
								open={deletePopover}
								onConfirm={handleFormDelete}
								overlayClassName="popconfirm-danger"
								onCancel={() => {
									setDeletePopover(false);
								}}
								okText="Delete"
								okType="danger"
								icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
								cancelText={<span>Cancel</span>}
								cancelButtonProps={{ ghost: true }}
							>
								{/* Delete Button */}
								<Button
									disabled={!activeRowKey}
									className="ant-btn-secondary"
									style={{ marginRight: "auto" }}
									danger
									onClick={() => {
										setDeletePopover(true);
									}}
								>
									Delete
								</Button>
							</Popconfirm>
							{/* Cancel Button - Popconfirm */}
							<Popconfirm
								icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
								title={
									<div style={{ maxWidth: "300px", padding: "10px" }}>
										<>
											You have unsaved changes. Discard them or continue
											editing?
										</>
									</div>
								}
								open={cancelPopover}
								placement="topRight"
								cancelButtonProps={{ ghost: true }}
								cancelText="Discard changes"
								onCancel={() => {
									setCancelPopover(false);
									setTimeout(() => {
										handleFormCancel();
									}, 300);
								}}
								okText="Continue editing"
								okType="default"
								onConfirm={() => {
									setCancelPopover(false);
								}}
							>
								{/* Cancel Button */}
								<Button
									className="ant-btn-secondary"
									onClick={() => {
										if (formReset) {
											handleFormCancel();
										} else {
											setCancelPopover(true);
										}
									}}
								>
									Cancel
								</Button>
							</Popconfirm>
							{/* Save Button */}
							<FormButtonSave
								activeKey={activeRowKey}
								form={formRef}
								handleFormSave={handleFormSave}
								formReset={formReset}
								setFormReset={setFormReset}
							/>
						</>
					);
				}}
			>
				<div style={{ height: "50vh" }}>
					<CustomForm
						tabKey={"tenant_module_configuration"}
						formRef={formRef}
						setFormReset={setFormReset}
						dataOptions={props.extraData}
						activeRowKey={activeRowKey}
						activeRecord={props.tenantModuleRecord}
					/>
				</div>
			</Modal>
		</>
	);
}

export default TenantModuleConfigModal;
