import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import Emitter from "../../utils/emitter";

const GanttChart = (props: any) => {
	const svgRef = useRef<any>();
	const containerRef = useRef<any>();
	const [size, setSize] = useState({ width: 0, height: 0 });
	const data = props.data;
	let zoom: any = null;

	// const generateDateValueArray = (
	// 	count: number,
	// 	startDateRange: any,
	// 	endDateRange: any
	// ) => {
	// 	const randomDate = (start: Date, end: Date) => {
	// 		return new Date(
	// 			start.getTime() + Math.random() * (end.getTime() - start.getTime())
	// 		);
	// 	};

	// 	const randomColor = () => {
	// 		const letters = "0123456789ABCDEF";
	// 		let color = "#";
	// 		for (let i = 0; i < 6; i++) {
	// 			color += letters[Math.floor(Math.random() * 16)];
	// 		}
	// 		return color;
	// 	};

	// 	const randomName = () => {
	// 		const names = [
	// 			"Alpha",
	// 			"Bravo",
	// 			"Charlie",
	// 			"Delta",
	// 			"Echo",
	// 			"Foxtrot",
	// 			"Golf",
	// 			"Hotel",
	// 			"India",
	// 			"Juliet",
	// 		];
	// 		return names[Math.floor(Math.random() * names.length)];
	// 	};

	// 	const array = [];
	// 	// const valueIncrement = 10 / count;

	// 	for (let i = 0; i < count; i++) {
	// 		const startDate = randomDate(startDateRange[0], startDateRange[1]);
	// 		const endDate = randomDate(startDate, endDateRange[1]);
	// 		const value = i;
	// 		const color = randomColor();
	// 		const title = randomName();

	// 		array.push({
	// 			title,
	// 			startDate,
	// 			endDate,
	// 			value,
	// 			color,
	// 		});
	// 	}

	// 	return array;
	// };

	// useEffect(() => {
	// 	const startDateRange = [new Date(2022, 0, 1), new Date(2023, 11, 31)];
	// 	const endDateRange = [new Date(2023, 1, 1), new Date(2024, 11, 31)];
	// 	const count = 10;
	// 	let generatedData = generateDateValueArray(
	// 		count,
	// 		startDateRange,
	// 		endDateRange
	// 	);
	// 	let sortedData = generatedData
	// 		.sort((a: any, b: any) => b.startDate.getTime() - a.startDate.getTime())
	// 		.map((data: any, index: any) => {
	// 			return { ...data, value: index };
	// 		});
	// 	console.log(sortedData);
	// 	setData(sortedData);
	// }, []);

	useEffect(() => {
		// if (data.length > 0) {
		if (!size.width || !size.height) return;

		const margin = { top: 0, right: 1, bottom: 0, left: 0 };
		const width = size.width - margin.left - margin.right;
		const height = size.height - margin.top - margin.bottom;

		// Remove existing SVG content
		d3.select(svgRef.current).selectAll("*").remove();

		const svg: any = d3
			.select(svgRef.current)
			.attr("width", width + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)
			.append("g")
			.attr("transform", `translate(${margin.left},${margin.top})`);

		// Create a zoom overlay
		svg
			.append("rect")
			.attr("width", width)
			.attr("height", height)
			.style("fill", "none")
			.style("pointer-events", "all");

		const maxValue = Math.max(...data.map((d: any) => d.value)) || 0;
		const x = d3.scaleTime().range([0, width]);
		const y = d3
			.scaleLinear()
			.domain([-30, maxValue + 1])
			.range([1000, 0]);
		const xAxis = d3.axisBottom(x);
		const yAxis = d3.axisLeft(y);

		const gX = svg
			.append("g")
			.attr("class", "axis axis--x")
			.attr("transform", `translate(0,0)`)
			.call(xAxis);

		// Change the opacity of only the tick lines
		gX.selectAll(".tick line").style("opacity", "30%");

		const gY = svg
			.append("g")
			.attr("class", "axis axis--y")
			.attr("display", `none`)
			.call(yAxis);

		let currentDate = new Date();
		let flatDateMap =
			props.data.length > 0
				? data?.flatMap((d: any) => [d.startDate, d.endDate])
				: [
						new Date(currentDate.getTime() - 1000 * 60 * 60 * 24 * 365 * 1),
						currentDate,
				  ];
		let a: any = d3.extent(flatDateMap);
		x.domain(a);

		const tooltip = d3.select("#tooltip");
		// Add bars
		svg
			.selectAll(".bar")
			.data(data)
			.enter()
			.append("rect")
			.attr("class", "bar")
			.attr("x", (d: any) => x(d.startDate))
			.attr("y", (d: any) => y(d.value))
			.attr("width", (d: any) => x(d.endDate) - x(d.startDate))
			.attr("height", 20)
			.style("fill", (d: any) => d.color)
			.on("mouseover", (event: any, d: any) => {
				tooltip.transition().duration(200).style("opacity", 0.9);
				tooltip
					.html(d?.tooltip)
					.style("left", event.pageX + 5 + "px")
					.style("top", event.pageY - 28 + "px");
			})
			.on("mousemove", (event: any, d: any) => {
				tooltip
					.style("left", event.pageX + 5 + "px")
					.style("top", event.pageY - 28 + "px");
			})
			.on("mouseout", (event: any, d: any) => {
				tooltip.transition().duration(500).style("opacity", 0);
			});

		// Add text labels to the left of each bar
		svg
			.selectAll(".bar-label")
			.data(data)
			.enter()
			.append("text")
			.attr("class", "bar-label gannt-label")
			.attr("x", (d: any) => x(d.startDate)) // Adjust to position text to the left of the bar
			.attr("y", (d: any) => y(d.value)) // Position vertically centered relative to the bar
			.attr("text-anchor", "start") // Align text to the right
			.text((d: any) => d.title) // Set the text to show the value
			// .style("fill", "white") // Text color
			.style("user-select", "none");

		let dateMin: any = d3.min(data, (d: any) => d.startDate.getTime());
		let dateMax: any = d3.max(data, (d: any) => d.endDate.getTime());
		let zoomMax = data.length > 0 ? (dateMax - dateMin) / (1000 * 60) : 100;

		let zoomMin = 0;

		// Zoom event
		const zoomed = (event: any) => {
			console.log(event);
			if (event.sourceEvent?.type === "wheel" && event.sourceEvent?.shiftKey) {
				console.log("panning");
				// Adjust the transformation for panning
				const newXScale = d3.zoomTransform(svg.node()).rescaleX(x);
				//   const newYScale = d3.zoomTransform(svg.node()).rescaleY(y);

				gX.call(xAxis.scale(newXScale));
				//   gY.call(yAxis.scale(newYScale));

				svg.selectAll(".data-point").attr("cx", (d: any) => newXScale(d.date));
				// .attr("cy", d => newYScale(d.value));
			} else {
				const newTransform = event.transform;
				const newXScale = newTransform.rescaleX(x);
				gX.call(xAxis.scale(newXScale));
				if (data.length > 0) {
					const newYScale = newTransform.rescaleY(y);

					gY.call(yAxis.scale(newYScale));

					svg
						.selectAll(".bar")
						.attr("x", (d: any) => newXScale(d.startDate))
						.attr(
							"width",
							(d: any) => newXScale(d.endDate) - newXScale(d.startDate)
						)
						.attr(
							"transform",
							(d: any) => `translate(0, ${newYScale(d.value)})`
						);
					// gX.call(xAxis.scale(newXScale));
					// .attr("cy", d => newYScale(d.value));

					svg
						.selectAll(".bar-label")
						.attr("x", (d: any) => newXScale(d.startDate))
						.attr(
							"transform",
							(d: any) => `translate(0, ${newYScale(d.value) - 5}) `
						);
				}
			}

			// Update tick lines
			gX.selectAll(".tick line")
				.attr("stroke", "#ddd") // Line color
				.attr("stroke-dasharray", "9,2") // Line style
				.attr("y2", height) // Line length
				.attr("x2", 0) // Line length
				.style("opacity", "30%");
			// Add text labels to the left of each bar

			gX.selectAll(".tick text").style("user-select", "none");
		};

		// Zoom scaling
		zoom = d3
			.zoom()
			.scaleExtent([zoomMin, zoomMax]) // Adjust as needed
			.translateExtent([
				[-Infinity, -Infinity],
				[Infinity, Infinity],
			])
			.extent([
				[0, 0],
				[width, height],
			])
			.duration(250)
			.on("zoom", zoomed);

		// Update the xAxis after domain changes
		gX.call(xAxis);
		gX.selectAll(".tick line")
			.attr("stroke", "#ddd") // Line color
			.attr("stroke-dasharray", "4,2") // Line style
			.attr("y2", height) // Line length
			.attr("x2", 0); // Line length

		svg.select("rect").call(zoom);

		// Initial zoom to initialise zoom identity
		svg
			.select("rect")
			.call(zoom)
			.call(zoom.transform, d3.zoomIdentity.scale(1).translate(0, 0));

		Emitter.on("RESET-GANTT", () => {
			svg
				.select("rect")
				.call(zoom)
				.call(zoom.transform, d3.zoomIdentity.scale(1).translate(0, 0));
		});

		return () => {
			Emitter.remove("RESET-GANTT");
		};

		// }
	}, [svgRef.current, size, props.data]);

	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries: any) => {
			if (entries[0]) {
				const { width, height } = entries[0].contentRect;
				setSize({ width, height });
			}
		});

		if (containerRef.current) {
			resizeObserver.observe(containerRef.current);
		}

		return () => {
			if (containerRef.current) {
				resizeObserver.unobserve(containerRef.current);
			}
		};
	}, []);

	return (
		<div
			ref={containerRef}
			style={{ display: "flex", width: "100%", height: "100%" }}
			id="ganttContainer"
		>
			<svg ref={svgRef}></svg>
			<div
				id="tooltip"
				className="gantt-tooltip"
				style={{
					position: "absolute",
					opacity: 0,
					backgroundColor: "rgba(123,123,123,1)",
					border: "1px solid black",
					padding: "5px",
					pointerEvents: "none",
					color: "white",
					zIndex: "10000",
				}}
			></div>
		</div>
	);
};

export default GanttChart;
