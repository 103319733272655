import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "audit-logs";

export const getAuditLogList = (
	tenantKey: any,
	identifierData: any,
	type: any,
	auditlogFilter: Array<any>
) => {
	return new Promise((resolve, reject) => {
		AuthHeader.headers = { ...AuthHeader.headers, tenantKey }
		axios
			.get(`${url}/${tenantKey}/${apiURL}/${type}`, {
				params: {
					identifierData,
					auditlogFilter
				},
				...AuthHeader
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};
