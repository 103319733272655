import { createContext, useContext, useEffect } from "react";
import { getSoftwareList } from "../services/api-server/software";
import { useQuery } from "@tanstack/react-query";
import { HomeContext } from "../containers/Home";
import { getCrudaClass } from "../utils/lookup_list";
import Emitter from "../utils/emitter";
import { useTimewriting } from "./TimewritingContext";

interface TimeEntryContextType {
	allTenantSoftware: any[] | undefined;
	addTimeEntry: (values: any) => void;
	updateTimeEntry: (id: string, values: any, initialForm: any) => void;
	deleteTimeEntry: (id: string) => void;
}

const TimeEntryContext = createContext<TimeEntryContextType | null>(null);

export const TimeEntryProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { tenantData, userInfo }: any = useContext(HomeContext);
	const { fetchTimewritingUpdate } = useTimewriting();

	const fetchAllTenantSoftware = async () => {
		if (!tenantData) throw new Error("tenantData must be have a value");
		const softwareList = await Promise.all(
			tenantData.map(
				async (tenant: any) => (await getSoftwareList(tenant.role_key)) ?? []
			)
		);
		console.log("softwareList", softwareList.flat());
		return softwareList.flat();
	};

	const { data: allTenantSoftware } = useQuery({
		queryKey: ["allTenantSoftware"],
		queryFn: fetchAllTenantSoftware,
		enabled: !!tenantData, // Ensures the query runs only if tenantData exists
	});

	const addTimeEntry = async (values: any) => {
		try {
			const timewritingCruda = await getCrudaClass("timewriting");
			const response = timewritingCruda.addLookupItem(
				"timewriting",
				[],
				values,
				null,
				null,
				null,
				userInfo?.user
			);
			if (response) {
				Emitter.emit("alert", {
					type: "success",
					message: `Time entry has been successfully saved.`,
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
				fetchTimewritingUpdate();
			}
		} catch (error) {
			console.error("Failed to add time entry:", error);
			Emitter.emit("alert", {
				type: "error",
				message: "Changes are not saved. Please try again.",
				description: "",
				top: true,
				closeable: false,
				timeout: 3000,
			});
		}
	};

	const updateTimeEntry = async (id: string, values: any, initialForm: any) => {
		console.log("updateTimeEntry", id, values, initialForm);
		try {
			const timewritingCruda = await getCrudaClass("timewriting");
			const response = timewritingCruda.updateLookupItem(
				null,
				null,
				id,
				null,
				values,
				initialForm,
				null,
				userInfo?.user,
				null
			);
			if (response) {
				Emitter.emit("alert", {
					type: "success",
					message: `Time entry has been successfully updated.`,
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
				fetchTimewritingUpdate();
			}
		} catch (error) {
			console.error("Failed to update time entry:", error);
			Emitter.emit("alert", {
				type: "error",
				message: "Changes are not saved. Please try again.",
				description: "",
				top: true,
				closeable: false,
				timeout: 3000,
			});
		}
	};

	const deleteTimeEntry = async (id: string) => {
		try {
			const timewritingCruda = await getCrudaClass("timewriting");
			const response = timewritingCruda.deleteLookupItem(null, null, id);
			if (response) {
				Emitter.emit("alert", {
					type: "success",
					message: `Time entry has been successfully deleted.`,
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
				fetchTimewritingUpdate();
			}
		} catch {
			Emitter.emit("alert", {
				type: "error",
				message: "Changes are not saved. Please try again.",
				description: "",
				top: true,
				closeable: false,
				timeout: 3000,
			});
		}
	};

	return (
		<TimeEntryContext.Provider
			value={{
				allTenantSoftware,
				addTimeEntry,
				updateTimeEntry,
				deleteTimeEntry,
			}}
		>
			{children}
		</TimeEntryContext.Provider>
	);
};

export const useTimeEntry = () => {
	const context = useContext(TimeEntryContext);
	if (!context) {
		throw new Error("useTimeEntry must be used within a TimeEntryProvider");
	}
	return context;
};
