import { Button, Space } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTimewriting } from "../contexts/TimewritingContext";
import { GetAntIcon } from "../utils/ant_icons";

function WeekNavigator({}: {}) {
	const {
		currentWeekStart: timewritingCurrentWeekStart,
		setCurrentWeekStart: timewritingSetCurrentWeekStart,
		currentWeekEnd: timewritingCurrentWeekEnd,
		setCurrentWeekEnd: timewritingSetCurrentWeekEnd,
		currentDateOffset,
		setCurrentDateOffset,
	} = useTimewriting();

	const [currentWeekStart, setCurrentWeekStart] = useState<string>("");
	const [currentWeekEnd, setCurrentWeekEnd] = useState<string>("");
	const [offset, setOffset] = useState<number>(currentDateOffset);

	const handleNavigateWeek = (offset: number) => {
		const startOfWeek = dayjs().add(offset, "week").startOf("week");
		const endOfWeek = dayjs().add(offset, "week").endOf("week");
		setCurrentDateOffset(offset);
		timewritingSetCurrentWeekStart(startOfWeek);
		timewritingSetCurrentWeekEnd(endOfWeek);
		const startOfWeekString = startOfWeek.format("DD MMM YYYY");
		const endOfWeekString = endOfWeek.format("DD MMM YYYY");
		setCurrentWeekStart(startOfWeekString);
		setCurrentWeekEnd(endOfWeekString);
	};

	useEffect(() => {
		handleNavigateWeek(offset);
	}, [offset]);

	return (
		<>
			<Space direction="horizontal" size={10}>
				<Button shape="circle" onClick={() => setOffset((prev) => prev - 1)}>
					{GetAntIcon("leftarrow")}
				</Button>
				<span>
					{currentWeekStart} - {currentWeekEnd}
				</span>
				<Button
					shape="circle"
					onClick={() => setOffset((prev) => prev + 1)}
					disabled={dayjs(currentWeekEnd).isAfter(dayjs().add(10, "days"))}
				>
					{GetAntIcon("rightarrow")}
				</Button>
			</Space>
		</>
	);
}

export default WeekNavigator;
