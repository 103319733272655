import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { createContext, useContext, useEffect, useState } from "react";
import { HomeContext } from "../containers/Home";
import { getAllProjects } from "../services/api-server/projects";
import {
	addSubmittedReview,
	getSubmmitedReviewList,
} from "../services/api-server/submitted_review";
import { getTimeEntries } from "../services/api-server/timewriting";
import Emitter from "../utils/emitter";
import {
	checkSuperAdminPriviledge,
	getProjectMembers,
	includesIgnoreCase,
} from "../utils/utils";

dayjs.extend(isBetween);
interface Day {
	day: string;
	name: string;
	dayNumber: string;
}

interface TimewritingContextType {
	daysOfWeek: Day[];
	currentWeekStart: any;
	currentWeekEnd: any;
	allTenantProjects: any[];
	userTimesheet: any[];
	timesheetManagementData: any[];
	userWeekSubmittedReview: any[];
	allUserWeekSubmittedReview: any[];
	weeklyProjectSummary: any[];
	monthlyProjectSummary: any[];
	fetchTimewritingUpdate: () => void;
	setCurrentWeekStart: (weekStart: any) => void;
	setCurrentWeekEnd: (weekEnd: any) => void;
	handleSubmitForReview: () => void;
}

const TimewritingContext = createContext<TimewritingContextType | any>(null);

export const TimewritingProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { currentTenantKey, tenantData, userInfo, userRole }: any =
		useContext(HomeContext);

	const [currentDateOffset, setCurrentDateOffset] = useState<number>(0);
	const [currentWeekStart, setCurrentWeekStart] = useState<any>(
		dayjs().startOf("week")
	);
	const [currentWeekEnd, setCurrentWeekEnd] = useState<any>(
		dayjs().endOf("week")
	);
	const [daysOfWeek, setDaysOfWeek] = useState<Day[]>([]);
	const [allTenantProjects, setAllTenantProjects] = useState<any>([]);
	const [userTimesheet, setUserTimesheet] = useState<any[]>([]);
	const [allTimeEntries, setAllTimeEntries] = useState<any>([]);
	const [userTimeEntries, setUserTimeEntries] = useState<any[]>([]);
	const [userWeekSubmittedReview, setUserWeekSubmittedReview] = useState<any[]>(
		[]
	);
	const [timesheetManagementData, setTimesheetManagementData] = useState<any[]>(
		[]
	);
	const [allUserWeekSubmittedReview, setAllUserWeekSubmittedReview] = useState<
		any[]
	>([]);
	const [weeklyProjectSummary, setWeeklyProjectSummary] = useState<any[]>([]);

	// Function to only take latest time entry that has the same time_entry_code
	const groupTimeEntryCode = (data: any[]) => {
		return Object.values(
			data.reduce((acc: any, entry: any) => {
				if (
					!acc[entry.time_entry_code] ||
					dayjs(entry.date).isAfter(dayjs(acc[entry.time_entry_code].date))
				) {
					acc[entry.time_entry_code] = {
						...entry,
						date: dayjs(entry.date).utc().local().format("YYYY-MM-DD"),
					};
				}
				return acc;
			}, {})
		);
	};

	// Assign time entry to projects
	const assignTimeEntryToProjects = (projects: any[], timeEntries: any[]) => {
		const timesheetData = projects.map((project: any) => ({
			...project,
			members: getProjectMembers(project),
			time_entries: timeEntries.filter(
				(entry: any) => entry.project_id === project._id
			),
		}));
		return timesheetData;
	};

	// Filter projects based on user role
	const filterProjectsBasedOnUserRole = (projects: any[]) => {
		return projects.filter((project: any) => {
			if (checkSuperAdminPriviledge(userInfo.userRole)) {
				return true;
			} else if (userRole.role === "tenant_admin") {
				return includesIgnoreCase(project.tenant_admin, userInfo.user.email);
			} else {
				const approvers = [
					...project.main_approver,
					...project.secondary_approver,
				];
				return includesIgnoreCase(approvers, userInfo.user.email);
			}
		});
	};

	// Filter projects based on date
	const filterProjectsBasedOnDate = (timeEntries: any[]) => {
		return timeEntries.filter((entry) =>
			dayjs(entry.date).isBetween(currentWeekStart, currentWeekEnd, "day", "[]")
		);
	};

	// Initialize days of week for time writing
	const initializeDaysOfWeek = () => {
		const startOfWeek = dayjs(currentWeekStart);
		const daysOfWeek = [];
		for (let i = 0; i < 7; i++) {
			const day = startOfWeek.add(i, "day");
			daysOfWeek.push({
				day: day.format("YYYY-MM-DD"),
				name: day.format("dddd") as string,
				dayNumber: day.format("DD"),
			});
		}
		setDaysOfWeek(daysOfWeek);
	};

	// Fetch all projects from all tenants
	const fetchAllProjects = async () => {
		try {
			const projects = await getAllProjects(currentTenantKey, tenantData);
			setAllTenantProjects(projects);
		} catch (error) {
			console.error("Failed to fetch all projects:", error);
		}
	};

	const fetchAllTimeEntries = async () => {
		try {
			const allTimeEntries = await getTimeEntries();
			const validEntries = groupTimeEntryCode(allTimeEntries as any);
			setAllTimeEntries(validEntries);
		} catch (error) {
			console.error("Failed to fetch all time entries:", error);
		}
	};
	// Handle submit for review in weekly summary page
	const handleSubmitForReview = async () => {
		const email = userInfo?.user.email;
		const project_ids = userTimesheet.map((project: any) => project._id);
		const relevantTimeEntries = filterProjectsBasedOnDate(
			userTimeEntries
		).filter((entry) => project_ids.includes(entry.project_id));

		try {
			await addSubmittedReview(
				email,
				currentWeekStart.toISOString(),
				currentWeekEnd.toISOString(),
				project_ids,
				relevantTimeEntries
			);
			Emitter.emit("alert", {
				type: "success",
				message: `Timesheet has been successfully submitted.`,
				description: "",
				top: true,
				closeable: false,
				timeout: 3000,
			});
			fetchTimewritingUpdate();
		} catch (error: any) {
			Emitter.emit("alert", {
				type: "alert",
				message: `Failed to submit timesheet. Please try again.`,
				description: error.message || "",
				top: true,
				closeable: false,
				timeout: 3000,
			});
		}
	};

	// Fetch user week submitted review in weekly summary page
	const fetchUserWeekSubmittedReview = async () => {
		const email = userInfo?.user.email;
		const adjustedStart = new Date(currentWeekStart);
		adjustedStart.setUTCDate(adjustedStart.getUTCDate() - 1); // Shift start back 1 day

		const adjustedEnd = new Date(currentWeekEnd);
		adjustedEnd.setUTCDate(adjustedEnd.getUTCDate() + 1); // Shift end forward 1 day
		const startDate = adjustedStart.toISOString();
		const endDate = adjustedEnd.toISOString();
		try {
			const filter = {
				user: email,
				start_date: { $gte: startDate },
				end_date: { $lte: endDate },
			};
			const data: any = await getSubmmitedReviewList(filter);
			setUserWeekSubmittedReview(data[0]);
		} catch (error) {
			console.error("Failed to fetch week submitted review:", error);
		}
	};

	// Fetch all user weekly submitted review in timesheet management page
	const fetchAllUserWeeklySubmittedReview = async () => {
		const adjustedStart = new Date(currentWeekStart);
		adjustedStart.setUTCDate(adjustedStart.getUTCDate() - 1); // Shift start back 1 day

		const adjustedEnd = new Date(currentWeekEnd);
		adjustedEnd.setUTCDate(adjustedEnd.getUTCDate() + 1); // Shift end forward 1 day
		const startDate = adjustedStart.toISOString();
		const endDate = adjustedEnd.toISOString();
		try {
			const filter = {
				start_date: { $gte: startDate },
				end_date: { $lte: endDate },
			};
			const data: any = await getSubmmitedReviewList(filter);
			setAllUserWeekSubmittedReview(data);
		} catch (error) {
			console.error("Failed to fetch all submitted reviews:", error);
		}
	};

	// User time sheet in weekly summary page
	const fetchUserTimeSheet = async () => {
		try {
			const userEmail: string = userInfo.user.email;
			const startDate = currentWeekStart.toISOString();
			const endDate = currentWeekEnd.toISOString();

			const filter = {
				email: userEmail,
				date: { $gte: startDate, $lte: endDate },
			};

			const response: any = await getTimeEntries(filter);

			const validEntries = groupTimeEntryCode(response);
			setUserTimeEntries(validEntries);

			const filteredTimeEntries = filterProjectsBasedOnDate(validEntries);
			console.log("filteredTimeEntries", filteredTimeEntries);

			// Filter user time sheet based on user email to get user
			const userTimesheet = assignTimeEntryToProjects(
				allTenantProjects,
				filteredTimeEntries
			).filter((project: any) =>
				includesIgnoreCase(project.members, userEmail)
			);
			setUserTimesheet(userTimesheet);
		} catch (error) {
			console.error("Failed to fetch user time sheet:", error);
		}
	};

	const fetchWeeklySubmittedTimeEntries = async () => {
		try {
			const startDate = currentWeekStart.toISOString();
			const endDate = currentWeekEnd.toISOString();
			const filter = {
				date: { $gte: startDate, $lte: endDate },
				status: { $in: ["submitted", "resubmitted", "approved", "rejected"] }, // status: { $ne: "updated" }
			};
			const response: any = await getTimeEntries(filter);
			const validEntries = groupTimeEntryCode(response);

			const groupedByUser = validEntries.reduce((acc: any, entry: any) => {
				acc[entry.email] = acc[entry.email] || [];
				acc[entry.email].push(entry);
				return acc;
			}, {});

			const groupedByEmailList = Object.entries(groupedByUser as any).map(
				([user, timeEntries]) => ({
					user,
					time_entries: timeEntries,
				})
			);
			setTimesheetManagementData(groupedByEmailList);
		} catch (error) {
			console.error("Failed to fetch submitted time entries", error);
		}
	};

	const fetchProjectSummaryData = async () => {
		try {
			const setData = (data: any) => {
				const validEntries = groupTimeEntryCode(data);
				const filteredProjects =
					filterProjectsBasedOnUserRole(allTenantProjects);
				const projectSummaryData = assignTimeEntryToProjects(
					filteredProjects,
					validEntries
				);
				return projectSummaryData;
			};

			// Fetch Project Summary Data for Weekly
			const startDate = currentWeekStart.toISOString();
			const endDate = currentWeekEnd.toISOString();
			const filter = {
				date: { $gte: startDate, $lte: endDate },
			};
			const response: any = await getTimeEntries(filter);
			const weeklyProjectSummaryData = setData(response);
			setWeeklyProjectSummary(weeklyProjectSummaryData);
		} catch (error) {
			console.error("Failed to fetch project weekly summary data", error);
		}
	};

	// Refetch related data when user time writing data is updated
	const fetchTimewritingUpdate = async () => {
		fetchUserTimeSheet();
		fetchUserWeekSubmittedReview();
		fetchAllUserWeeklySubmittedReview();
		fetchWeeklySubmittedTimeEntries();
		fetchProjectSummaryData();
		fetchAllTimeEntries();
	};

	useEffect(() => {
		if (currentTenantKey && tenantData && userInfo?.user.email) {
			fetchAllProjects();
			fetchAllTimeEntries();
		}
	}, [currentTenantKey, tenantData, userInfo?.user.email]);

	// Update state for week related data
	useEffect(() => {
		if (currentWeekStart && currentWeekEnd) {
			initializeDaysOfWeek();
			fetchUserWeekSubmittedReview();
			fetchAllUserWeeklySubmittedReview();
			fetchUserTimeSheet();
			fetchWeeklySubmittedTimeEntries();
			fetchProjectSummaryData();
		}
	}, [currentWeekStart, currentWeekEnd]);

	useEffect(() => {
		fetchUserTimeSheet();
	}, [allTenantProjects]);

	return (
		<TimewritingContext.Provider
			value={{
				daysOfWeek,
				currentDateOffset,
				currentWeekStart,
				currentWeekEnd,
				allTenantProjects,
				allTimeEntries,
				userTimesheet,
				timesheetManagementData,
				userWeekSubmittedReview,
				allUserWeekSubmittedReview,
				weeklyProjectSummary,
				fetchTimewritingUpdate,
				setCurrentWeekStart,
				setCurrentWeekEnd,
				setCurrentDateOffset,
				handleSubmitForReview,
			}}
		>
			{children}
		</TimewritingContext.Provider>
	);
};

export const useTimewriting = () => {
	const context = useContext(TimewritingContext);
	if (!context) {
		throw new Error("useTimewriting must be used within a TimewritingProvider");
	}
	return context;
};
