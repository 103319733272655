import { Divider, Popover, Skeleton } from "antd";
import dayjs from "dayjs";
import { useTimewriting } from "../../contexts/TimewritingContext";
import { useEffect, useState } from "react";
import { getOrdinalSuffix } from "../../utils/ordinalSuffix";

type SubmittedReviewHistoryType = {
	submitted_date: string;
	reviewed_date?: string;
	comment?: string;
	reviewed_by?: string;
};

type PopoverProps = {
	children: React.ReactNode;
	project_id: string;
	placement?:
		| "top"
		| "bottom"
		| "left"
		| "right"
		| "topLeft"
		| "topRight"
		| "bottomLeft"
		| "bottomRight"
		| "leftTop"
		| "leftBottom"
		| "rightTop"
		| "rightBottom";
};

const TimesheetReviewHistoryPopover: React.FC<PopoverProps> = ({
	children,
	project_id,
	placement = "right",
}) => {
	const { userWeekSubmittedReview } = useTimewriting();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [timewritingHistory, setTimewritingHistory] = useState<
		SubmittedReviewHistoryType[]
	>([]);

	useEffect(() => {
		console.log("userWeekSubmittedReview", userWeekSubmittedReview);
		if (userWeekSubmittedReview) {
			const submission = userWeekSubmittedReview.submissions.find(
				(submission: any) => submission.project_id === project_id
			);
			console.log("submission", submission);
			return setTimewritingHistory(submission?.history);
		}
	}, [userWeekSubmittedReview]);

	const popoverContent = () => {
		return (
			<Skeleton loading={isLoading} active>
				<div
					style={{
						maxHeight: "400px",
						overflowY: "auto",
						paddingRight: "8px",
					}}
				>
					{timewritingHistory?.map((item: any, index: number) => (
						<div key={item._id || index}>
							<div className="review-history-popover-content">
								<div className="review-history-popover-content-item">
									<span>{`${index + 1}${getOrdinalSuffix(
										index + 1
									)} submission`}</span>
									<span>
										{dayjs(item.submitted_date).format(
											"DD MMM YYYY hh:mm:ss A"
										)}
									</span>
								</div>
								{item.reviewed_date && (
									<>
										<div className="review-history-popover-content-item">
											<span>Reviewed on</span>
											<span>
												{item.reviewed_date &&
													dayjs(item.reviewed_date).format(
														"DD MMM YYYY hh:mm:ss A"
													)}
											</span>
										</div>
										<div className="review-history-popover-content-item">
											<span>Comment</span>
											<span>{item.comment || "-"}</span>
										</div>
										<div className="review-history-popover-content-item">
											<span>Reviewed by</span>
											<span>{item.reviewed_by}</span>
										</div>
									</>
								)}
							</div>
							{index < timewritingHistory.length - 1 && (
								<Divider style={{ margin: "12px 0" }} />
							)}
						</div>
					))}
				</div>
			</Skeleton>
		);
	};

	return (
		<Popover
			rootClassName="review-history-popover"
			trigger={["hover"]}
			placement={placement}
			title={"Timesheet review history"}
			content={popoverContent}
		>
			{children}
		</Popover>
	);
};

export default TimesheetReviewHistoryPopover;
