import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { getFilePreview } from "../services/api-server/welldocs";
import { HomeContext } from "./Home";
import { getWells } from "../services/api-server/wells";
import Emitter from "../utils/emitter";
import NoAccess from "./NoAccess";

const SharepointPreview = (props: any) => {
	const location = useLocation();
	const context: any = useContext(HomeContext);
	const searchParams = new URLSearchParams(location.search);
	const file_id = searchParams.get("record");
	const container_id = searchParams.get("container");

	const [previewUrl, setPreviewUrl] = useState<any>(null);
	const [restricted, setRestricted] = useState<boolean>(false);

	useEffect(() => {
		Emitter.emit("loading", true);
		if (context.currentTenantKey) {
			let well_id: any = location?.pathname?.split("/")[2];
			getWells(context.currentTenantKey, { _id: well_id }).then(
				(wells: any) => {
					if (wells && wells.length > 0) {
						context.setCurrentWell(wells[0]);
						getFilePreview(
							context.currentTenantKey,
							file_id,
							container_id
						).then((response: any) => {
							setPreviewUrl(response?.getUrl);
							Emitter.emit("loading", false);
						});
					} else {
						setRestricted(true);
					}
				}
			);
		}
	}, [context.currentTenantKey]);

	return (
		<>
			{restricted ? (
				<>
					<NoAccess
						break={true}
						text={
							"Oops, looks like you don't have the authorisation to view this page."
						}
					/>
				</>
			) : (
				previewUrl && (
					<iframe
						src={previewUrl}
						width="100%"
						height="100%"
						style={{ border: "none" }}
						title="SharePoint Document Preview"
					/>
				)
			)}
		</>
	);
};

export default SharepointPreview;
