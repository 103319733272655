import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Tag, Tooltip } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import CheckboxDropdown from "../components/CheckboxDropdown";
import WellConfigurationModal from "../components/Modal/WellConfigurationModal";
import useHandleContextMenu from "../hooks/useHandleContextMenu";
import usePermission from "../hooks/usePermission";
import { getOperations } from "../services/api-server/operations";
import { getWells } from "../services/api-server/wells";
import { GetAntIcon } from "../utils/ant_icons";
import Emitter from "../utils/emitter";
import { setLookupData } from "../utils/lookupFinder";
import { HomeContext } from "./Home";
import NoAccess from "./NoAccess";
import SharepointComponent from "../components/SharepointComponent";

const WellDetails = (props: any) => {
	const context: any = useContext(HomeContext);
	const location = useLocation();
	const navigate = useNavigate();

	const { handleContextMenu } = useHandleContextMenu();
	const hasViewWellPermission = usePermission("View.Well");
	const hasEditWellPermission = usePermission("Edit.Well");

	const [wellDetailsExpand, setWellDetailsExpand] = useState<boolean>(false);
	const [wellInfo, setWellInfo] = useState<any>(null);
	const [wellOperationList, setWellOperationList] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [activePage, setActivePage] = useState<string>("operations");
	const [wellId, setCurrentWellId] = useState<any>(null);

	//Filtering states
	const [filteredWellList, setFilteredWellList] = useState<any>(null);
	const [filterMode, setFilterMode] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>("");
	const [descMode, setDescMode] = useState<boolean>(false);
	const [selectedFilters, setSelectedFilters] = useState<any>({
		operation_type: [],
	});

	const [sharepointDisabled, setSharepointDisabled] = useState<boolean>(true);

	const [openWellModal, setOpenWellModal] = useState<boolean>(false);

	useEffect(() => {
		Emitter.emit("loading", true);

		if (context.currentTenantKey && context.allData) {
			let well_id: any =
				location?.state?.well_id || location?.pathname?.split("/")[2];

			getWells(context.currentTenantKey, { _id: well_id })
				.then((wells: any) => {
					if (wells && wells.length > 0) {
						setWellInfo(wells[0]);
						setCurrentWellId(well_id);
						context.setCurrentWell(wells[0]);

						getOperations(context.currentTenantKey, {
							well_id: wells[0]._id,
						}).then((operations: any) => {
							setWellOperationList(operations);
						});
					}
				})
				.finally(() => {
					Emitter.emit("loading", false);
					setIsLoading(false);
				});
		}
	}, [context.currentTenantKey, context.allData]);

	const hasSelectedFilters = () => {
		// Check if any of the filter categories have selected values
		return Object.values(selectedFilters).some(
			(values: any) => values.length > 0
		);
	};

	// useMemo: Ensures the filterRender component is only re-created when filterMode or selectedFilters changes, improving performance by avoiding unnecessary re-renders.
	const filterRender = useMemo(() => {
		// This function is called when a filter's selection changes.
		const handleMenuClick =
			// field: Represents the filter category (e.g., "location").
			// selectedValues: The selected values for the filter category.
			(field: any, selectedValues: any[]) => {
				// console.log(`Selected: ${field} - ${selectedValues}`);

				// Updates the selectedFilters state with the new values for the specified field.
				setSelectedFilters({ ...selectedFilters, [field]: selectedValues });

				// Stores the updated filters in localStorage so the selections persist across page reloads.
				localStorage.setItem(
					"selectedFilters",
					JSON.stringify({ ...selectedFilters, [field]: selectedValues })
				);
			};

		// Resets all filters to their initial (empty) state.
		const handleClearAllFilters = () => {
			// Clears all selected filters by setting them to empty arrays.
			setSelectedFilters({
				operation_type: [],
			});
			// Reset search value
			setSearchValue("");

			// Turns off the filter mode (if it was active).
			setFilterMode(false);

			setDescMode(false); // Reset to the default sort (ascending or set it to your desired default)

			// Clears the saved filters from localStorage.
			localStorage.removeItem("selectedFilters");

			// Object.keys(selectedFilters).forEach((field) => {
			// 	const storageKey = generateStorageKey(field);
			// 	localStorage.removeItem(storageKey);
			// });

			// Briefly toggles filterMode off and back on to trigger any dependent UI updates.
			setTimeout(() => {
				setFilterMode(true);
			}, 5);
		};

		return (
			<>
				{/* Operation Type */}
				<CheckboxDropdown
					options={context?.allData?.operation_type?.map((ws: any) => ({
						label: ws.name,
						value: ws.uuid,
					}))}
					onChange={(selectedValues) =>
						handleMenuClick("operation_type", selectedValues)
					}
					onClear={""}
					defaultValue={selectedFilters["operation_type"]}
					Key="operation_type"
					label="Operation Type"
				/>
				{/* Reset All */}
				<div className="test">
					<Button type="link" onClick={handleClearAllFilters}>
						Reset All
					</Button>
				</div>
			</>
		);
	}, [filterMode, selectedFilters]);

	const listOperationWells = () => {
		const filterWellOperationList = (
			// wellOperationList is an array of wells. Each well is an object containing fields like operation_name, operation_type, etc.
			wellOperationList: any[],
			// filters is a dictionary where each key represents a filter field (e.g., operation_name, operation_type) and maps to an array of selected values for that field.
			filters: any
		) => {
			// Loops through each item (well) in the wellOperationList and returns only the well operations that match all the filters.
			return wellOperationList.filter((wellOperation) => {
				// Converts the filters object into an array of key-value pairs.
				return (
					Object.entries(filters)
						// Checks if all filter conditions are met for the current well.
						.every(([field, selectedValues]) => {
							// Array.isArray(selectedValues): Ensures selectedValues is a valid array.
							if (
								!Array.isArray(selectedValues) ||
								// If the array is empty (no values are selected), the filter is skipped for this field. The well passes this filter automatically.
								selectedValues.length === 0
							)
								return true;
							// Gets the value of the current field from the well object.
							// For field = "location" and well = { location: "Onshore", status: "Active" }, `wellValue` = "Onshore".
							const wellOperationValue = wellOperation[field];
							// `selectedValues.includes(wellValue)`: Checks if the `wellValue` matches any value in the `selectedValues` array.
							return selectedValues.includes(wellOperationValue);
						})
				);
			});
		};

		const searchFilterList = (wellOperationList: any[], searchTerm: string) => {
			if (!searchTerm) return wellOperationList;
			return wellOperationList.filter((wellOperation) =>
				wellOperation.operation_name
					.toLowerCase()
					.includes(searchTerm.toLowerCase())
			);
		};

		const filteredWellOperationList =
			// The current state of selected filters.
			selectedFilters &&
			// Checks if there are any active filters.
			Object.keys(selectedFilters).length > 0
				? filterWellOperationList(wellOperationList, selectedFilters)
				: // Otherwise, the original wellList is used (no filtering).
				  wellOperationList;

		const searchedWellOperationList = searchFilterList(
			filteredWellOperationList,
			searchValue
		);

		const sortWellList = (wellOperationList: any[], descMode: boolean) => {
			return [...wellOperationList].sort((a, b) => {
				return descMode
					? b.operation_name.localeCompare(a.operation_name) // Descending
					: a.operation_name.localeCompare(b.operation_name); // Ascending
			});
		};

		let sortedWellOperationList = sortWellList(
			searchedWellOperationList,
			descMode
		);

		let currentWellOperationList =
			sortedWellOperationList || searchedWellOperationList || wellOperationList;

		if (currentWellOperationList.length > 0) {
			return currentWellOperationList?.map((operation: any) => {
				return (
					<div
						className="project-card"
						style={{
							maxWidth: "275.1px",
							minHeight: "fit-content",
							padding: "15px 30px",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "12px",
							}}
						>
							<div className="panel-detail-label">Operation name</div>
							<div
								className="panel-item-content project-title-clickable"
								onClick={() => {
									navigate(
										`/projects/${operation?.project_code}/${operation.operation_code}`
									);
								}}
								onContextMenu={(e: any) => {
									const link = `${window.location.origin}/projects/${operation?.project_code}/${operation.operation_code}`;
									handleContextMenu(e, { link: link });
								}}
								style={{
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
									cursor: "pointer",
								}}
								title={operation.operation_name}
							>
								{operation.operation_name}
							</div>
							<Tag
								style={{
									wordWrap: "break-word",
									whiteSpace: "normal",
									width: "fit-content",
									maxWidth: "213.5px",
								}}
								className="operations-tag"
							>
								{setWellContent(operation, "operation_type")}
							</Tag>
						</div>
					</div>
				);
			});
		}
	};

	const setWellContent = (currentData: any, infoKey: any) => {
		let content = "-";
		if (
			currentData[infoKey] ||
			infoKey == "latitude" ||
			infoKey == "longitude"
		) {
			content = setLookupData(context.allData, currentData, infoKey);
			if (infoKey === "location") {
				content =
					currentData[infoKey].charAt(0).toUpperCase() +
						currentData[infoKey].slice(1) || "-";
			}
		}
		return content;
	};

	return (
		<>
			{!isLoading && (
				<>
					{hasViewWellPermission ? (
						<>
							{wellInfo ? (
								<div
									className="generic-container"
									style={{ gap: "20px", overflow: "auto" }}
								>
									{/* Top Panel */}
									<div
										className="well-details-top-content main-container"
										id="main-container"
									>
										{/* Top Panel Header */}
										<div
											className="container-header"
											style={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											<div
												className="panel-info"
												onClick={() => {
													setWellDetailsExpand(!wellDetailsExpand);
												}}
											>
												<span>Well details</span>
												{wellDetailsExpand
													? GetAntIcon("up")
													: GetAntIcon("downarrow")}
											</div>
											{/* Panel Buttons Container */}
											{hasEditWellPermission && (
												<div className="panel-main-buttons-container">
													<a
														style={{ marginTop: "auto" }}
														onClick={() => {
															setOpenWellModal(true);
														}}
													>
														{GetAntIcon("setting")} Well settings
													</a>
												</div>
											)}
										</div>
										{/* Expanded Task Details Container */}
										{wellDetailsExpand ? (
											<div className="project-details-container">
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														maxWidth: "30%",
														height: "100%",
														position: "relative",
														gap: "20px",
													}}
												>
													{/* Panel info */}
													<div
														className="meta-data-container"
														style={{
															gridTemplateColumns: "repeat(6, auto)",
														}}
													>
														<div className="meta-data-item">
															<span className="panel-detail-label">
																Well name
															</span>
															<span className="panel-item-content">
																{wellInfo?.well_name}
															</span>
														</div>
														<div className="meta-data-item">
															<span className="panel-detail-label">
																Well operator
															</span>
															<span className="panel-item-content">
																{setWellContent(wellInfo, "well_operator") ||
																	"-"}
															</span>
														</div>
														<div className="meta-data-item">
															<span className="panel-detail-label">
																Location
															</span>
															<span className="panel-item-content">
																{setWellContent(wellInfo, "location") || "-"}
															</span>
														</div>
														<div className="meta-data-item">
															<span className="panel-detail-label">
																Water depth
															</span>
															<span className="panel-item-content">
																{wellInfo?.water_depth || "-"}
															</span>
														</div>
														<div className="meta-data-item">
															<span className="panel-detail-label">
																Purpose
															</span>
															<span className="panel-item-content">
																{setWellContent(wellInfo, "well_purpose") ||
																	"-"}
															</span>
														</div>
														<div className="meta-data-item">
															<span className="panel-detail-label">Status</span>
															<span className="panel-item-content">
																{setWellContent(wellInfo, "well_status") || "-"}
															</span>
														</div>
														<div className="meta-data-item">
															<span className="panel-detail-label">
																License
															</span>
															<span className="panel-item-content">
																{wellInfo?.license || "-"}
															</span>
														</div>
														<div className="meta-data-item">
															<span className="panel-detail-label">
																Latitude
															</span>
															<span className="panel-item-content">
																{setWellContent(wellInfo, "latitude") || "-"}
															</span>
														</div>
														<div className="meta-data-item">
															<span className="panel-detail-label">
																Longitude
															</span>
															<span className="panel-item-content">
																{setWellContent(wellInfo, "longitude") || "-"}
															</span>
														</div>
														<div className="meta-data-item">
															<span className="panel-detail-label">Block</span>
															<span className="panel-item-content">
																{wellInfo?.block || "-"}
															</span>
														</div>
														<div className="meta-data-item">
															<span className="panel-detail-label">Field</span>
															<span className="panel-item-content">
																{wellInfo?.field || "-"}
															</span>
														</div>
													</div>
												</div>
											</div>
										) : (
											<></>
										)}
									</div>
									{/* Bottom Panel */}
									<div
										className="well-details-bottom-content main-container"
										style={{
											minHeight: "85%",
											flex: 1,
											width: "100%",
											overflow: "hidden",
											display: "flex",
											flexDirection: "column",
										}}
									>
										{/* Bottom Panel Header */}
										<div className="project-header">
											{/* Bottom Container Header Left Side */}
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													gap: "10px",
												}}
											>
												<div
													className={
														activePage === "operations"
															? "project-active-button"
															: ""
													}
													style={{
														display: "flex",
														padding: "4px 15px",
														gap: "8px",
														cursor: "pointer",
													}}
													onClick={() => {
														setActivePage("operations");
													}}
												>
													{GetAntIcon("product")}
													<span>Operations</span>
												</div>
												<Tooltip
													title={
														sharepointDisabled ? "Documents are disabled" : ""
													}
												>
													<div
														className={
															activePage === "documents"
																? "project-active-button"
																: ""
														}
														style={{
															display: "flex",
															padding: "4px 15px",
															gap: "8px",
															cursor: sharepointDisabled
																? "not-allowed"
																: "pointer",
															opacity: sharepointDisabled ? 0.5 : 1,
														}}
														onClick={() => {
															if (!sharepointDisabled) {
																setActivePage("documents");
															}
														}}
													>
														{GetAntIcon("file2")}
														<span>Documents</span>
													</div>
												</Tooltip>
											</div>
											{/* Bottom Container Header Right Side */}
											<div
												style={{
													display: "flex",
													flex: "1",
													justifyContent: "flex-end",
													alignItems: "center",
													gap: "15px",
												}}
											>
												{/* Search Input */}
												<Input
													placeholder="Search by operation name"
													className="input-clearable-panel"
													style={{
														maxWidth: "265px",
														display: activePage === "documents" ? "none" : "",
													}}
													onPressEnter={(
														e: React.KeyboardEvent<HTMLInputElement>
													) => {
														setSearchValue(e.currentTarget.value);
														e.currentTarget.blur();
													}}
													onChange={(e) => {
														// if (e.target.value === "") {
														// 	setSearchValue("");
														// }
														const value = e.target.value;
														setSearchValue(value);

														setFilteredWellList(
															wellOperationList.filter((operation: any) =>
																operation.operation_name
																	.toLowerCase()
																	.includes(value.toLowerCase())
															)
														);
													}}
													allowClear
													prefix={<SearchOutlined />}
												/>
												<div style={{ display: "flex", flexDirection: "row" }}>
													{/* Filter Button */}
													<div
														className={
															filterMode || hasSelectedFilters()
																? "project-active-button"
																: ""
														}
														style={{
															padding: "4px 10px",
															cursor: "pointer",
															display: activePage === "documents" ? "none" : "",
														}}
														onClick={() => {
															setFilterMode(!filterMode);
														}}
													>
														{GetAntIcon("filter")}
													</div>
													{/* Sort Button */}
													<div
														style={{
															padding: "4px 10px",
															cursor: "pointer",
															display: activePage === "documents" ? "none" : "",
														}}
														onClick={() => {
															setDescMode(!descMode);
														}}
													>
														{descMode
															? GetAntIcon("descending")
															: GetAntIcon("ascending")}
													</div>
												</div>
											</div>
										</div>
										<div
											style={{
												flex: 1,
												display: "flex",
												flexWrap: "inherit",
												flexDirection: "column",
												alignItems: "flex-start",
												padding: 0,
												gap: 0,
												overflowX: "auto",
												maxHeight: "100%",
											}}
										>
											{activePage === "operations" && (
												<div
													className={`filter-area ${
														filterMode ? "show-filter" : "hide-filter"
													}`}
												>
													{filterMode && filterRender}
												</div>
											)}
											<div
												className="generic-content project-overview-container"
												style={{
													flex: 1,
													display: "flex",
													flexWrap: "inherit",
													flexDirection: "column",
													alignItems: "flex-start",
													padding: 0,
													gap: 0,
													overflowX: "auto",
													maxHeight: "100%",
												}}
											>
												{activePage === "operations" && (
													<div
														style={{
															width: "100%",
															padding: "40px 30px",
														}}
													>
														<div
															style={{
																display: "flex",
																gap: "40px",
																flexWrap: "wrap",
															}}
														>
															{wellOperationList?.length > 0 ? (
																<>{listOperationWells()}</>
															) : (
																<div
																	className="project-card"
																	style={{
																		maxWidth: "275.1px",
																		minHeight: "fit-content",
																		padding: "15px 30px",
																	}}
																>
																	<div
																		style={{
																			display: "flex",
																			flexDirection: "column",
																			gap: "12px",
																		}}
																	>
																		<div>There are no wells available.</div>
																	</div>
																</div>
															)}
														</div>
													</div>
												)}

												<SharepointComponent
													setDisabled={setSharepointDisabled}
													well_id={wellId}
													visible={activePage === "documents"}
												/>
											</div>
										</div>
									</div>

									{/* Well Configuration Modal */}
									{wellInfo && (
										<WellConfigurationModal
											open={openWellModal}
											setOpen={setOpenWellModal}
											activeRowKey={wellId}
											wellRecord={wellInfo}
											setWellRecord={setWellInfo}
											extraData={context.allData}
											redirectOnDelete={() => navigate("/wells")}
										/>
									)}
								</div>
							) : (
								<NoAccess
									text={
										"Oops, looks like you don't have the authorisation to view this well."
									}
								/>
							)}
						</>
					) : (
						<NoAccess
							text={
								"Oops, looks like you don't have the authorisation to view this well."
							}
						/>
					)}
				</>
			)}
		</>
	);
};

export default WellDetails;
