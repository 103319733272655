import { io } from "socket.io-client";

export const socketServer: string = window.location.hostname === "localhost"
	? `http://localhost:${process.env.REACT_APP_PORT_SERVER}`
	: `${window.location.origin}`;
const socketIDToken: any = localStorage.getItem(`idToken`);
let sessionDate: any = new Date().getTime();
sessionDate = (sessionDate - (sessionDate % 1000)) / 1000;

const path =
	process.env.NODE_ENV === "development" ? "/socket.io" : `/socket.io`;

export const socket = io(socketServer, {
	path: path,
	query: { token: socketIDToken, sessiondt: sessionDate },
	withCredentials: true,
});
