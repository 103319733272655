import { QuestionCircleOutlined } from "@ant-design/icons";
import {
	Badge,
	Button,
	Divider,
	Flex,
	Input,
	Popconfirm,
	Space,
	Spin,
} from "antd";
import dayjs from "dayjs";
import React, { Suspense, useContext, useEffect, useState } from "react";
import CalendarCell from "../components/CalendarCell";
import UserImage from "../components/UserImage";
import WeekNavigator from "../components/WeekNavigator";
import { TimeEntryProvider } from "../contexts/TimeEntryContext";
import { useTimewriting } from "../contexts/TimewritingContext";
import { GetAntIcon } from "../utils/ant_icons";
import { HomeContext } from "./Home";
import { updateUserWeeklyApproval } from "../services/api-server/timewriting";
import { updateSubmittedReview } from "../services/api-server/submitted_review";
import Emitter from "../utils/emitter";
import TimesheetReviewHistoryPopover from "../components/Popover/TimesheetReviewHistoryPopover";
import SearchButton from "../components/SearchButton";

interface TimewritingCalendarProps {
	project?: any;
	data: any[];
	headerComponent?: React.ReactNode;
	type: "project" | "user";
	cellClassName?: (cell: any) => string;
}

function Label({
	type,
	item,
	project,
	handleResubmit,
}: {
	type: "project" | "user";
	item: any;
	handleResubmit: () => void;
	project?: any;
}) {
	const { userWeekSubmittedReview, allUserWeekSubmittedReview } =
		useTimewriting();

	const getBadgeStatus = () => {
		const status = userWeekSubmittedReview?.submissions?.find(
			(submission: any) => submission.project_id == item._id
		)?.status;
		if (status === "approved")
			return (
				<Flex gap={12} align="center">
					<TimesheetReviewHistoryPopover project_id={item._id}>
						<Badge
							status="success"
							text="Approved"
							style={{
								display: "flex",
								alignItems: "center",
								pointerEvents: "auto",
							}}
						/>
					</TimesheetReviewHistoryPopover>
				</Flex>
			);

		if (status === "rejected")
			return (
				<Flex gap={12} align="center">
					<TimesheetReviewHistoryPopover project_id={item._id}>
						<Badge
							status="error"
							text="Rejected"
							style={{
								display: "flex",
								alignItems: "center",
								pointerEvents: "auto",
							}}
						/>
					</TimesheetReviewHistoryPopover>
					<Button onClick={() => handleResubmit()}>Resubmit</Button>
				</Flex>
			);

		if (status === "resubmitted")
			return (
				<Flex gap={12} align="center">
					<TimesheetReviewHistoryPopover project_id={item._id}>
						<Badge
							color="cyan"
							text="Resubmitted for review"
							style={{
								display: "flex",
								alignItems: "center",
								pointerEvents: "auto",
							}}
						/>
					</TimesheetReviewHistoryPopover>
				</Flex>
			);
	};

	if (type === "project") {
		return (
			<Flex gap={"8px"} style={{ width: "100%" }} vertical>
				<span>{item.project_name}</span>
				<Space style={{ fontSize: "12px", color: "grey" }}>
					<span>
						{GetAntIcon("apartment")} {item.tenant_name}
					</span>
					<span>
						{GetAntIcon("profile")} {item.client_name}
					</span>
				</Space>
				{userWeekSubmittedReview && getBadgeStatus()}
			</Flex>
		);
	} else {
		const userSubmittedReview = allUserWeekSubmittedReview.find(
			(submitted_review: any) => submitted_review.user === item.user
		);
		const projectSubmission = userSubmittedReview?.submissions.find(
			(submission: any) => submission.project_id === project._id
		);
		const submissionDate =
			projectSubmission?.history[projectSubmission?.history.length - 1]
				?.submitted_date;
		return (
			<Flex gap={"12px"} align="center">
				<UserImage email={item.user} size={32} />
				<Space direction="vertical">
					<span>{item.user}</span>
					<span style={{ whiteSpace: "nowrap" }}>
						{dayjs(submissionDate).format("DD MMM YYYY hh:mm:ss A")}
					</span>
				</Space>
			</Flex>
		);
	}
}

function ApproveButton({ handleApprove }: { handleApprove: () => void }) {
	return (
		<Popconfirm
			icon={<QuestionCircleOutlined style={{ color: "grey" }} />}
			title={
				<div style={{ maxWidth: "330px" }}>
					Approve this timesheet? Once approved it will be finalised and cannot
					be modified.
				</div>
			}
			rootClassName="review-popconfirm"
			placement="bottomLeft"
			cancelButtonProps={{ ghost: true }}
			okText="Confirm"
			onConfirm={() => handleApprove()}
		>
			<Button className="approve-btn" type="link">
				Approve
			</Button>
		</Popconfirm>
	);
}

function RejectButton({
	handleReject,
}: {
	handleReject: (comment: string) => void;
}) {
	const [comment, setComment] = useState<string>("");
	return (
		<Popconfirm
			icon={<QuestionCircleOutlined style={{ color: "grey" }} />}
			title={
				<div style={{ maxWidth: "330px" }}>
					<span>Reject this timesheet? Please provide a comment.</span>
					<br />
					<br />
					<b>Note</b>: This timesheet can be resubmitted for review.
					<Input.TextArea
						value={comment}
						onChange={(e) => setComment(e.target.value)}
						autoSize={{ minRows: 2, maxRows: 6 }}
					/>
				</div>
			}
			rootClassName="review-popconfirm"
			placement="bottomLeft"
			cancelButtonProps={{ ghost: true }}
			okText="Confirm"
			okButtonProps={{ ghost: true, disabled: comment?.length === 0 }}
			onOpenChange={(visible) => {
				if (!visible) setComment("");
			}}
			onConfirm={() => {
				handleReject(comment);
				setComment("");
			}}
		>
			<Button className="reject-btn" type="link">
				Reject
			</Button>
		</Popconfirm>
	);
}

function ReviewSection({
	handleApproval,
	item,
	project,
}: {
	handleApproval: (item: any, status: string, comment?: string) => void;
	item: any;
	project: any;
}) {
	const { allUserWeekSubmittedReview } = useTimewriting();
	const [showStatus, setShowStatus] = useState<boolean>(false);
	const [status, setStatus] = useState<string>("");

	useEffect(() => {
		const userSubmittedReview = allUserWeekSubmittedReview.find(
			(submitted_review: any) => submitted_review.user === item.user
		);
		const projectSubmission = userSubmittedReview?.submissions.find(
			(submission: any) => submission.project_id === project._id
		);
		const status = projectSubmission?.status;
		setStatus(status);
		if (status === "approved" || status === "rejected") setShowStatus(true);
		else setShowStatus(false);
	}, [allUserWeekSubmittedReview, item, project]);

	const getStatusBadge = () => {
		if (status === "approved")
			return (
				<Badge
					status="success"
					text="Approved"
					style={{
						display: "flex",
						alignItems: "center",
						pointerEvents: "auto",
					}}
				/>
			);
		if (status === "rejected")
			return (
				<Badge
					status="error"
					text="Rejected"
					style={{
						display: "flex",
						alignItems: "center",
						pointerEvents: "auto",
					}}
				/>
			);
	};

	return (
		<Flex className="timewriting-grid-cell" justify="center" align="center">
			{showStatus ? (
				<TimesheetReviewHistoryPopover project_id={project._id}>
					{getStatusBadge()}
				</TimesheetReviewHistoryPopover>
			) : (
				<>
					<ApproveButton
						handleApprove={() => handleApproval(item, "approved")}
					/>
					<Divider type="vertical" />
					<RejectButton
						handleReject={(comment: string) =>
							handleApproval(item, "rejected", comment)
						}
					/>
				</>
			)}
		</Flex>
	);
}

const TimewritingCalendar = ({
	project,
	data,
	headerComponent,
	type,
	cellClassName,
}: TimewritingCalendarProps) => {
	const { userInfo }: any = useContext(HomeContext);
	const {
		currentWeekStart,
		currentWeekEnd,
		daysOfWeek,
		fetchTimewritingUpdate,
	} = useTimewriting();

	const [filteredData, setFilteredData] = useState<any[] | null>(null);

	const convertToHoursAndMinutesString = (durationMin: number) => {
		return `${Math.floor(durationMin / 60)}h ${durationMin % 60}m`;
	};

	const calculateDurationInDay = (day: string) => {
		const timeEntries = data.flatMap((item: any) => item.time_entries);
		const timeEntriesInDay = timeEntries.filter((item: any) =>
			dayjs(item.date).isSame(dayjs(day), "day")
		);
		const totalDurationMin = timeEntriesInDay.reduce(
			(acc: number, item: any) =>
				acc + item.duration_hours * 60 + item.duration_mins,
			0
		);
		return totalDurationMin;
	};

	const getNoDataMessage = (type: "project" | "user") => {
		if (type === "project") return "You are not assigned to any projects";
		return "No members available for this project.";
	};

	const handleApproval = async (
		item: any,
		statusChanged: string,
		comment?: string
	) => {
		const projectId = project ? project._id : item._id;
		const email = item.user || userInfo?.user.email;
		try {
			await updateUserWeeklyApproval(
				projectId,
				email,
				{
					firstDayOfWeek: currentWeekStart,
					lastDayOfWeek: currentWeekEnd,
					reviewer:
						statusChanged !== "resubmitted" ? userInfo?.user.email : null,
				},
				statusChanged,
				comment
			);

			await updateSubmittedReview(
				email,
				currentWeekStart,
				currentWeekEnd,
				[projectId],
				statusChanged,
				comment,
				userInfo?.user
			);

			Emitter.emit("alert", {
				type: "success",
				message: `Timesheet has been successfully ${statusChanged}.`,
				description: "",
				top: true,
				closeable: false,
				timeout: 3000,
			});

			fetchTimewritingUpdate();
		} catch (error) {
			Emitter.emit("alert", {
				type: "alert",
				message: `Fail to review timesheet.`,
				description: "",
				top: true,
				closeable: false,
				timeout: 3000,
			});
		}
	};

	const handleSearch = (e: any) => {
		const filteredData = data.filter((item: any) =>
			item[e.columnKey]?.toLowerCase().includes(e.value.toLowerCase())
		);
		if (filteredData.length > 0) setFilteredData(filteredData);
		else setFilteredData(null);
	};

	return (
		<>
			<div className="week-navigator">
				{headerComponent ? (
					React.cloneElement(headerComponent as React.ReactElement, {
						children: <WeekNavigator />,
					})
				) : (
					<WeekNavigator />
				)}
			</div>
			<div style={{ overflow: "auto" }}>
				<div
					className="timewriting-grid-table"
					style={{
						gridTemplateColumns:
							type == "project"
								? "auto repeat(7, 160px)"
								: "auto repeat(7, 160px) auto",
					}}
				>
					<div className="timewriting-grid-header timewriting-grid-cell timewriting-row-header">
						{type == "project" && (
							<SearchButton
								handleSearch={handleSearch}
								headerName="List of projects"
								columnKey="project_name"
							/>
						)}
						{type == "user" && (
							<SearchButton
								handleSearch={handleSearch}
								headerName="List of team members"
								columnKey="user"
							/>
						)}
					</div>
					{Array.from({ length: 7 }, (_, i) => (
						<div
							key={i}
							className="timewriting-grid-header timewriting-grid-cell timewriting-row-header"
						>
							<Flex
								key={i}
								align="center"
								gap={24}
								justify="space-between"
								style={{ width: "100%" }}
							>
								<Space direction="vertical">
									<span style={{ fontSize: "20px" }}>
										{dayjs(currentWeekStart).add(i, "day").format("DD")}
									</span>
									<span>
										{dayjs(currentWeekStart).add(i, "day").format("dddd")}
									</span>
								</Space>
								{/* Total hours and minutes for the day */}
								<div className="color-default-btn">
									{convertToHoursAndMinutesString(
										calculateDurationInDay(daysOfWeek[i]?.day)
									)}
								</div>
							</Flex>
						</div>
					))}
					{type == "user" && (
						<div className="timewriting-grid-header timewriting-grid-cell timewriting-row-header">
							Review
						</div>
					)}

					{(filteredData ?? data).length > 0 ? (
						(filteredData ?? data).map((item: any) => {
							return (
								<>
									<div className="timewriting-grid-header timewriting-grid-cell">
										<Label
											type={type}
											item={item}
											project={project}
											handleResubmit={() => handleApproval(item, "resubmitted")}
										/>
									</div>{" "}
									{Array.from({ length: 7 }, (_, i) => {
										const timeEntries = item.time_entries.filter(
											(timeEntry: any) => timeEntry.date === daysOfWeek[i]?.day
										);
										return (
											<TimeEntryProvider>
												<CalendarCell
													key={i}
													day={daysOfWeek[i]?.day}
													timeEntries={timeEntries}
													item={item}
													className={`timewriting-grid-cell ${
														cellClassName && cellClassName(item)
													}`}
													closeFutureDate={type === "project"}
													cellClickable={type === "project"}
												/>
											</TimeEntryProvider>
										);
									})}
									{type == "user" && (
										<ReviewSection
											handleApproval={handleApproval}
											item={item}
											project={project}
										/>
									)}
								</>
							);
						})
					) : (
						<div
							className="timewriting-grid-cell"
							style={{
								gridColumn: "1 / -1",
								display: "flex",
								justifyContent: "center",
								height: "150px",
							}}
						>
							{getNoDataMessage(type)}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default TimewritingCalendar;
