import axios from "axios";
import { AuthHeader, url } from "../../utils/_exports";

const api = axios.create({
	baseURL: `${url}/submitted_review`,
	...AuthHeader,
});

export const getSubmmitedReviewList = (filter: {
	user?: string;
	start_date: string | {};
	end_date: string | {};
}) => {
	return new Promise((resolve, reject) => {
		api
			.get("/", {
				params: { filter: JSON.stringify(filter) },
			})
			.then((response: any) => {
				if (response && response.data) {
					resolve(response.data);
				} else {
					resolve(null);
				}
			})
			.catch((error: any) => {
				console.error("Error fetching time entries: ", error);
				reject(error);
			});
	});
};

/**
 * TODO: This function should hit a custom endpoint where the search of time entries and update of submitted review are done in the backend.
 */
export const addSubmittedReview = (
	email: string,
	start_date: string,
	end_date: string,
	projectIds: string[],
	timeEntries: any[]
) => {
	return new Promise((resolve, reject) => {
		const submittedReviewData = {
			user: email,
			start_date,
			end_date,
			submissions: projectIds.map((projectId) => ({
				project_id: projectId,
				status: "submitted",
				history: [
					{
						submitted_date: new Date().toISOString(),
					},
				],
				time_entry_code: timeEntries
					.filter((timeEntry: any) => timeEntry.project_id === projectId)
					.map((timeEntry: any) => timeEntry.time_entry_code),
			})),
			created_on: new Date(),
		};

		const timeEntryData = {
			filter: {
				email: email,
				project_id: { $in: projectIds },
				date: { $gte: start_date, $lte: end_date },
			},
			data: {
				status: "submitted",
				submitted_date: new Date().toISOString(),
			},
		};

		let submittedReviewId: string;

		// Use Promise.all to ensure both operations succeed or both fail
		api
			.post("/", submittedReviewData)
			.then((submittedReviewResponse: any) => {
				submittedReviewId = submittedReviewResponse.data._id;
				return Promise.all([
					Promise.resolve(submittedReviewResponse),
					axios.put(`${url}/timewriting`, timeEntryData, AuthHeader),
				]);
			})
			.then(([submittedReviewResponse, timewritingResponse]) => {
				resolve(submittedReviewResponse.data);
			})
			.catch(async (error) => {
				// If timewriting update failed and we have a submitted review ID, try to rollback
				if (submittedReviewId) {
					try {
						await api.delete(`/${submittedReviewId}`);
					} catch (rollbackError) {
						console.error("Rollback failed:", rollbackError);
						reject(
							new Error(
								"Critical: Timewriting update failed and rollback failed. Data may be inconsistent."
							)
						);
						return;
					}
				}
				reject(error);
			});
	});
};

export const updateSubmittedReview = async (
	email: string,
	start_date: string,
	end_date: string,
	projectIds: string[],
	status: string,
	comment: string | null = null,
	user: any = null
) => {
	const adjustedStart = new Date(start_date);
	adjustedStart.setUTCDate(adjustedStart.getUTCDate() - 1); // Shift start back 1 day

	const adjustedEnd = new Date(end_date);
	adjustedEnd.setUTCDate(adjustedEnd.getUTCDate() + 1); // Shift end forward 1 day

	const adjustedStartString = adjustedStart.toISOString();
	const adjustedEndString = adjustedEnd.toISOString();

	return new Promise((resolve, reject) => {
		const filter = {
			user: email,
			// old
			// start_date: { $gte: start_date },
			// end_date: { $lte: end_date },

			// new
			// start_date: { $lte: end_date },
			// end_date: { $gte: start_date },

			//v2
			start_date: { $gte: adjustedStartString },
			end_date: { $lte: adjustedEndString },
		};
		let data: any = {};
		let arrayFilters: any = [{ "x.project_id": { $in: projectIds } }];
		const options = { upsert: false, arrayFilters };

		if (status === "rejected" || status === "approved") {
			arrayFilters.push({ "y.reviewed_by": { $exists: false } });
			data = {
				"submissions.$[x].status": status,
				"submissions.$[x].history.$[y].reviewed_date": new Date().toISOString(),
				"submissions.$[x].history.$[y].comment": comment,
				"submissions.$[x].history.$[y].reviewed_by": user.email,
			};
		} else if (status === "resubmitted") {
			data = {
				$set: { "submissions.$[x].status": status },
				$push: {
					"submissions.$[x].history": {
						submitted_date: new Date().toISOString(),
					},
				},
			};
		}

		api
			.put("/", { filter, data, options })
			.then((response: any) => {
				if (response && response.data) {
					resolve(response.data);
				} else {
					resolve(null);
				}
			})
			.catch((error: any) => {
				console.error("Error updating submission data: ", error);
				reject(error);
			});
	});
};
