import React, { useContext, useState } from "react";
import CustomForm from "../components/CustomForm";
import { Button, Modal, Popconfirm } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FormButtonSave from "../components/FormButtonSave";
import { useForm } from "antd/es/form/Form";
import { HomeContext } from "./Home";
import { getCrudaClass } from "../utils/lookup_list";
import Emitter from "../utils/emitter";
import { getProject } from "../services/api-server/projects";
import { getOperations } from "../services/api-server/operations";
import { getPhaseItem } from "../services/api-server/phases";
import { getTask } from "../services/api-server/tasks";
import { getDeliverables } from "../services/api-server/deliverables";
import { getAllUsers } from "../services/api-server/user";

type TaskReopenModalProps = {
	modalOpen: boolean;
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	handleReopenTask: (reason: string) => void;
	taskInfo: any;
	// loadData: (
	// 	project_code: string,
	// 	operation_code: string,
	// 	phase_id: string,
	// 	task_id: string,
	// 	deliverable_id: string,
	// 	extraData: any
	// ) => void ;
};

const TaskReopenModal = ({
	modalOpen,
	setModalOpen,
	handleReopenTask,
	taskInfo = null,
}: // loadData,
TaskReopenModalProps) => {
	const [formReset, setFormReset] = useState<boolean>(true);
	const [cancelPopover, setCancelPopover] = useState<any>(false);
	const [reopenForm] = useForm();

	const context: any = useContext(HomeContext);

	const handleFormCancel = () => {
		setModalOpen(false);
		setCancelPopover(false);
		resetForm();
	};

	const handleFormSave = async (values: any) => {
		handleReopenTask(values?.reopen_reason);
		setModalOpen(false);
		resetForm();
		context.handleFormUpdating(false);
	};

	const resetForm = (values: any = null, reset: any = true) => {
		if (reopenForm) {
			setFormReset(true);
			reset && reopenForm.resetFields();
			reopenForm.setFieldsValue(values ? { ...values } : null);
		}
	};

	// const loadData = (
	// 	project_code: any,
	// 	operation_code: any,
	// 	phase_id: any,
	// 	task_id: any,
	// 	deliverable_id: any,
	// 	extraData: any
	// ) => {
	// 	Emitter.emit("loading", true);
	// 	Promise.all([
	// 		getProject(context.currentTenantKey, project_code),
	// 		getOperations(context.currentTenantKey, {
	// 			project_code,
	// 			operation_code,
	// 		}),
	// 		getPhaseItem(context.currentTenantKey, phase_id),
	// 		getTask(context.currentTenantKey, task_id),
	// 		getDeliverables(context.currentTenantKey, task_id),
	// 		getAllUsers(),
	// 	])
	// 		.then((values: any) => {
	// 			setProjectTitle(values[0]?.project_name);
	// 			context.setCurrentProject(values[0]);
	// 			setWellTitle(values[1][0]?.well_project_name);
	// 			context.setCurrentOperation(values[1][0]);
	// 			setPhaseTitle(values[2][0]?.phase_name);
	// 			context.setCurrentPhase(values[2][0]);
	// 			setTaskInfo(values[3]);
	// 			context.setCurrentTask(values[3]);

	// 			// from values[4] which is the deliverable data, create a new field which is responsible_roles_names, accountable_roles_names, consulted_names, informed_parties_names based on the id of the respective roles field already exists
	// 			values[4]
	// 				//TO BE REMOVED
	// 				?.sort((a: any, b: any) => a.created_on.localeCompare(b.created_on))
	// 				.map((deliverable: any) => {
	// 					deliverable.assigned_to_names = mapRolesData(
	// 						deliverable.assigned_to,
	// 						extraData
	// 					);
	// 					deliverable.reviewed_by_names = mapRolesData(
	// 						deliverable.reviewed_by,
	// 						extraData
	// 					);
	// 				});

	// 			setAllUsers(values[5]);
	// 			console.log(values[5]);

	// 			setDeliverableList(values[4]);
	// 			Emitter.emit("loading", false);

	// 			if (sideContainerData?._id) {
	// 				const newSideContainerData = values[4].find((item: any) => {
	// 					return item._id === sideContainerData._id;
	// 				});
	// 				//this thing will re-render the useEffect
	// 				setSideContainerData(newSideContainerData);
	// 				setSideContainerOpen(true);
	// 			} else if (deliverable_id) {
	// 				const newSideContainerData = values[4].find((item: any) => {
	// 					return item._id === deliverable_id;
	// 				});
	// 				//this thing will re-render the useEffect
	// 				setSideContainerData(newSideContainerData);
	// 				setSideContainerOpen(true);
	// 			}
	// 		})
	// 		.catch((e: any) => {
	// 			console.log(e);
	// 			// setIsLoading(false);
	// 			Emitter.emit("loading", false);
	// 		})
	// 		.finally(() => {
	// 			// calculateTableHeight();
	// 			Emitter.emit("loading", false);
	// 		});
	// };

	// const handleReopenTask = (task: any) => {
	// 	getCrudaClass("task").then((cruda: any) => {
	// 		const initialData = { ...task };
	// 		task.closed = false;
	// 		cruda
	// 			.updateLookupItem(
	// 				"tasks",
	// 				null,
	// 				task._id,
	// 				context.currentTenantKey,
	// 				task,
	// 				initialData,
	// 				null,
	// 				context.userInfo.user,
	// 				[],
	// 				{
	// 					level: "task",
	// 					level_id: task._id,
	// 					event_type: "update",
	// 					event: `Reopened the task`,
	// 					details: [
	// 						{
	// 							new_data: "Comment: Update on well plans",
	// 						},
	// 					],
	// 					ref_tags: {
	// 						operation_code: task.operation_code,
	// 						phase_id: task.phase_id,
	// 					},
	// 					created_on: new Date(),
	// 					created_by: context.userInfo.user.email,
	// 				}
	// 			)
	// 			.then(() => {
	// 				// loadData(
	// 				// 	project_code,
	// 				// 	operationCode,
	// 				// 	phase_id,
	// 				// 	task_id,
	// 				// 	deliverableKey,
	// 				// 	context.allData
	// 				// );
	// 				Emitter.emit("alert", {
	// 					type: "success",
	// 					message: `Task is successfully reopened.`,
	// 					description: "",
	// 					top: true,
	// 					closeable: false,
	// 					timeout: 3000,
	// 				});
	// 			})
	// 			.catch(() => {
	// 				Emitter.emit("alert", {
	// 					type: "error",
	// 					message: `Fail to open task.`,
	// 					description: "",
	// 					top: true,
	// 					closeable: false,
	// 					timeout: 3000,
	// 				});
	// 			});
	// 		setModalOpen(false);
	// 	});
	// };

	return (
		<Modal
			closable={false}
			width={"40%"}
			title="Reopen task?"
			open={modalOpen}
			centered
			destroyOnClose
			footer={() => {
				return (
					<div
						className="generic-footer"
						style={{ height: "auto", padding: "0", width: "100%" }}
					>
						<Popconfirm
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										You have unsaved changes. Discard them or continue editing?
									</>
								</div>
							}
							open={cancelPopover}
							placement="topRight"
							cancelButtonProps={{ ghost: true }}
							cancelText="Discard changes"
							onCancel={() => {
								setCancelPopover(false);
								setTimeout(() => {
									handleFormCancel();
								}, 300);
							}}
							okText="Continue editing"
							okType="default"
							onConfirm={() => {
								setCancelPopover(false);
							}}
						>
							<Button
								className="ant-btn-secondary"
								onClick={() => {
									if (formReset) {
										handleFormCancel();
									} else {
										setCancelPopover(true);
									}
								}}
							>
								Cancel
							</Button>
						</Popconfirm>
						<Popconfirm
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										You have unsaved changes. Discard them or continue editing?
									</>
								</div>
							}
							placement="topRight"
						>
							<FormButtonSave
								form={reopenForm}
								formReset={formReset}
								setFormReset={setFormReset}
								handleFormSave={handleFormSave}
							></FormButtonSave>
						</Popconfirm>
					</div>
				);
			}}
		>
			<CustomForm
				setFormReset={setFormReset}
				formRef={reopenForm}
				tabKey={"task-reopen"}
			/>
		</Modal>
	);
};

export default TaskReopenModal;
