import { QuestionCircleOutlined } from "@ant-design/icons";
import { Badge, Button, Popconfirm, Popover, Space } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import ProjectSummaryTable from "../components/ProjectSummaryTable";
import TabButton from "../components/TabButton";
import { useTimewriting } from "../contexts/TimewritingContext";
import { GetAntIcon } from "../utils/ant_icons";
import { checkPrivilege, includesIgnoreCase } from "../utils/utils";
import { HomeContext } from "./Home";
import TimesheetSummaryHeader from "./TimesheetSummaryHeader";
import TimewritingCalendar from "./TimewritingCalendar";

function SubmitForReviewButton() {
	const { handleSubmitForReview } = useTimewriting();
	return (
		<Popconfirm
			icon={<QuestionCircleOutlined style={{ color: "grey" }} />}
			title={
				<div style={{ maxWidth: "300px", padding: "12px" }}>
					Submit for review? Once submitted, this timesheet will be locked and
					cannot be edited.
				</div>
			}
			placement="bottomLeft"
			cancelButtonProps={{ ghost: true }}
			okText="Confirm"
			onConfirm={() => handleSubmitForReview()}
		>
			<Button className="ant-btn-secondary">Submit for review</Button>
		</Popconfirm>
	);
}

function SubmittedBadge() {
	const { userWeekSubmittedReview } = useTimewriting();
	return (
		<Popover
			rootClassName="review-history-popover"
			trigger={["hover"]}
			placement="bottomLeft"
			title={"Timesheet submit history"}
			content={
				<div className="review-history-popover-content">
					<div className="review-history-popover-content-item">
						<span>Submitted on</span>
						<span>
							{userWeekSubmittedReview.created_on
								? dayjs(userWeekSubmittedReview.created_on).format(
										"DD MMM YYYY hh:mm:ss A"
								  )
								: "-"}
						</span>
					</div>
				</div>
			}
		>
			<Badge color="#00bebe" text="Submitted for review" />
		</Popover>
	);
}

function WeeklySummaryHeader({
	data,
	children,
}: {
	data: any[];
	children?: React.ReactNode;
}) {
	const { userWeekSubmittedReview } = useTimewriting();

	return (
		<>
			{children}
			<Space size="middle">
				<TimesheetSummaryHeader data={data} />
				{data.length > 0 ? (
					userWeekSubmittedReview ? (
						<SubmittedBadge />
					) : (
						<SubmitForReviewButton />
					)
				) : null}
			</Space>
		</>
	);
}

const TimewritingRevamp = ({ params }: any) => {
	const { userInfo }: any = useContext(HomeContext);
	const { allTenantProjects, userTimesheet, userWeekSubmittedReview } =
		useTimewriting();

	const [activePage, setActivePage] = useState<string>("weekly-summary");
	const [isApprover, setIsApprover] = useState<boolean>(false);

	useEffect(() => {
		const email = userInfo.user.email;
		if (allTenantProjects && allTenantProjects.length > 0) {
			const hasApprover = allTenantProjects.some(
				(project: any) =>
					includesIgnoreCase(project.main_approver, email) ||
					includesIgnoreCase(project.secondary_approver, email)
			);
			if (hasApprover) {
				setIsApprover(true);
			} else {
				setIsApprover(false);
			}
		}
	}, [allTenantProjects]);

	const getSubmittedClassName = (item: any) => {
		if (!userWeekSubmittedReview) return "";
		const projectSubmissionStatus = userWeekSubmittedReview.submissions?.find(
			(submission: any) => submission.project_id == item._id
		)?.status;
		if (projectSubmissionStatus === "rejected") return "";
		return "cell-submitted";
	};

	return (
		<div className="generic-container" style={{ gap: "20px" }}>
			<div
				className="main-container"
				style={{
					flex: 1,
					width: "100%",
					maxHeight: "100%",
					overflow: "hidden",
					display: "flex",
					flexDirection: "column",
					borderRadius: "5px",
				}}
			>
				<div className="project-header">
					<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<TabButton
							identifier="weekly-summary"
							label="Weekly summary"
							icon={GetAntIcon("project")}
							activePage={activePage}
							setActivePage={setActivePage}
							tooltip={{
								title:
									"This section is for you to log time against a project for each day of the week.",
								placement: "top",
							}}
						/>
						{/* <TabButton
							identifier="time-block"
							label="Time block"
							icon={GetAntIcon("timewriting")}
							activePage={activePage}
							setActivePage={setActivePage}
                            tooltip={{
								title:
									"This is a space for you to log time against a project for each day of the week.",
								placement: "top",
							}}
						/> */}
						{(checkPrivilege(userInfo.userRole, ["tenant-admin"]) ||
							isApprover) && (
							<TabButton
								identifier="timesheet-management"
								label="Timesheet management"
								icon={GetAntIcon("clock-filled")}
								activePage={activePage}
								setActivePage={setActivePage}
								tooltip={{
									title:
										"This section is for reviewing submitted timesheets for the project for the week.",
									placement: "top",
								}}
							/>
						)}
					</div>
				</div>

				<div
					className="generic-content project-overview-container"
					style={{
						overflowY: "hidden",
						flex: 1,
						display: "flex",
						flexWrap: "inherit",
						flexDirection: "column",
						alignItems: "flex-start",
						padding: 0,
						gap: 0,
						overflowX: "auto",
						maxHeight: "100%",
					}}
				>
					<div
						className="timewriting-table"
						style={{
							display: "flex",
							height: "100%",
							width: "100%",
							flexDirection: "column",
						}}
					>
						{activePage === "weekly-summary" && (
							<>
								<TimewritingCalendar
									data={userTimesheet}
									headerComponent={<WeeklySummaryHeader data={userTimesheet} />}
									type="project"
									cellClassName={(cell) => getSubmittedClassName(cell)}
								/>
							</>
						)}
						{activePage === "timesheet-management" && <ProjectSummaryTable />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TimewritingRevamp;
