import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Popconfirm } from "antd";
import { useForm } from "antd/es/form/Form";
import { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../containers/Home";
import usePermission from "../../hooks/usePermission";
import Emitter from "../../utils/emitter";
import { getCrudaClass } from "../../utils/lookup_list";
import CustomForm from "../CustomForm";
import FormButtonSave from "../FormButtonSave";
import { filterInitialForm } from "../../utils/utils";

function OperationConfigModal(props: any) {
	const context: any = useContext(HomeContext);

	// form related
	const [formRef] = useForm();
	const [formReset, setFormReset] = useState<boolean>(false);
	const [initialForm, setInitialForm] = useState<any>(null);
	const [activeRowKey, setActiveRowKey] = useState<any>(null);
	const [cancelPopover, setCancelPopover] = useState<boolean>(false);
	const [deletePopover, setDeletePopover] = useState<boolean>(false);
	const [currentProject, setCurrentProject] = useState<any>(null);

	const [operationInfo, setOperationInfo] = useState<any>(null);

	const hasDeleteOperationPermission = usePermission(
		"Delete.Operation",
		currentProject?.project_code
	);

	// console.log("hasDeleteOperationPermission", hasDeleteOperationPermission);

	const hasEditOperationPermission = usePermission(
		"Edit.Operation",
		currentProject?.project_code
	);

	useEffect(() => {
		if (props.operationRecord) {
			resetForm(props.operationRecord);
			setActiveRowKey(props.operationRecord._id);
			setOperationInfo(props.operationRecord);
			setCurrentProject(props.currentProjectData);
			// console.log(props.operationRecord);
		}
	}, [props.operationRecord, props.currentProjectData]);

	const resetForm = (values: any = null, reset: any = true) => {
		// Ensure formRef exists
		if (formRef) {
			setFormReset(true); // `setFormReset` to `true`
			reset && formRef.resetFields(); // If 'reset' is true, reset the
			formRef.setFieldsValue(values ? { ...values } : null); // Set new values or clear fields
			setInitialForm(values); // Update initial form values
		}
	};

	const handleFormCancel = () => {
		props.setOpen(false);
		setCancelPopover(false);
		context?.handleFormUpdating(false, props.activeRowKey);
		if (operationInfo) {
			resetForm(operationInfo);
			setOperationInfo(operationInfo);
		} else {
			resetForm();
		}
	};

	const handleFormSave = (values: any) => {
		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				values[key] = values[key].trim();
			}
		});
		getCrudaClass("operation").then((cruda: any) => {
			console.log("activeRowKey", activeRowKey);
			// Update Operation Item
			if (activeRowKey) {
				console.log(
					"Updating tenant module data of `activeRowKey`: ",
					activeRowKey
				);
				const form = cruda.getFormList("operation");
				const formList: any = [];
				form.forEach((element: any) => {
					if (element.children && !("name" in element)) {
						element.children.forEach((child_element: any) => {
							if (child_element.name) formList.push(child_element.name);
						});
					} else {
						if (element.name) formList.push(element.name);
					}
				});

				// console.log(
				// 	"props.option",
				// 	props.option,
				// 	"activeRowKey",
				// 	activeRowKey,
				// 	"values: ",
				// 	values,
				// 	"initialForm: ",
				// 	initialForm,
				// 	"formList: ",
				// 	formList
				// );

				cruda
					.updateLookupItem(
						"operation",
						props?.option,
						activeRowKey,
						context?.currentTenantKey,
						{ ...values },
						filterInitialForm(values, initialForm),
						{ project_code: currentProject.project_code },
						props.user,
						formList
					)
					.then((updatedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Operation has been successfully updated.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						context?.handleFormUpdating(false, activeRowKey);
						resetForm(updatedItem, true);
						context.setCurrentWell(updatedItem);
						// Emitter.emit("forceUpdate", null);

						// console.log(operationInfo);
						const newOperationData =
							// Iterate through the 'operation_data' array to find the operation item with the matching '_id'
							operationInfo?.operation_data?.map((item: any) => {
								// Check if the current item has the same '_id' as the updating item
								if (item._id === updatedItem._id) {
									// If they match, return the updated item to replace the old one
									return updatedItem;
								}
								// If they don't match, return the original item (no change)
								return item;
							});

						console.log("newOperationData: ", newOperationData);

						const newRecord = {
							...operationInfo,
							operation_data: newOperationData,
						};

						console.log("newRecord: ", newRecord);

						Emitter.emit("resetForm", newRecord);
						Emitter.emit("forceUpdate", {
							data: { operation_data: newOperationData },
						});
						// console.log(newRecord, newOperationData);
					})
					.catch((error: any) => {
						console.log(error);
						if (error?.status === 409) {
							Emitter.emit("alert", {
								type: "error",
								message: `This operation already exists, please ensure the operation name is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
			} else {
				// Add Operation Item
				console.log("adding data", props);

				const updatedOperationData =
					operationInfo?.operation_data?.filter(
						// Add all items into the `updatedOperationData` array whose `._id` does not match `activeRowKey`
						(item: any) => item._id !== activeRowKey
					) || [];

				cruda
					.addLookupItem(
						"operation",
						props?.option,
						values,
						{
							project_code: context?.currentProject?.project_code,
							well_id: operationInfo?.well_id,
						},
						context?.currentTenantKey,
						null,
						props.user
					)
					.then((addedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Operation has been successfully saved.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						context?.handleFormUpdating(false, "new");

						resetForm(addedItem, true);
						context.setCurrentWell(addedItem);

						setActiveRowKey(
							addedItem?.uuid ||
								addedItem?.group_uuid ||
								addedItem?.tag_uuid ||
								addedItem?._id
						);

						const newRecord = { ...operationInfo };

						newRecord.operation_data = [...updatedOperationData, addedItem];

						console.log("New Record", updatedOperationData);
						props.setOperationRecord((prev: any) => ({
							...prev,
							...addedItem,
							operation_data: newRecord.operation_data,
						}));
						Emitter.emit("resetForm", newRecord);
						Emitter.emit("forceUpdate", {
							data: { operation_data: newRecord.operation_data },
						});
					})
					.catch((error: any) => {
						if (error.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This Operation already exists, please ensure the Operation ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
			}
		});
	};

	const handleFormDelete = (values: any) => {
		context?.handleFormUpdating(false, activeRowKey);
		getCrudaClass("operation").then((cruda: any) => {
			const data = { is_removed: true };
			cruda
				.updateLookupItem(
					"operation",
					props?.option,
					activeRowKey,
					context?.currentTenantKey,
					data,
					null, //filterInitialForm(data, initialForm),
					{},
					props.user
				)
				.then((updatedItem: any) => {
					Emitter.emit("alert", {
						type: "success",
						message: `Operation has been successfully deleted.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});

					const newOperationData = operationInfo?.operation_data?.filter(
						(item: any) => item._id !== activeRowKey
					);
					const newRecord = {
						...operationInfo,
						operation_data: newOperationData,
					};

					console.log("Updated Operation Data After Deletion:", newRecord);

					Emitter.emit("resetForm", newRecord);
					Emitter.emit("forceUpdate", {
						data: { operation_data: newOperationData },
					});

					resetForm(null, true);

					if (props.redirectOnDelete) {
						props.redirectOnDelete();
					}
				})
				.catch(() => {
					Emitter.emit("alert", {
						type: "error",
						message: "Changes are not saved. Please try again.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				})
				.finally(() => {
					props.setOpen(false);
					setDeletePopover(false);
					setActiveRowKey(null);
				});
		});
	};

	return (
		<>
			{/* Well - operation configuration */}
			<Modal
				closable={false}
				title={"Well - operation configuration"}
				open={props.open}
				centered
				destroyOnClose
				footer={() => {
					return (
						<>
							{hasDeleteOperationPermission && (
								// Delete - Popconfirm
								<Popconfirm
									placement="topLeft"
									title={
										<div style={{ maxWidth: "300px", padding: "10px" }}>
											<>
												Permanently delete this well operation?
												<br />
												This action cannot be undone.
											</>
										</div>
									}
									open={deletePopover}
									onConfirm={handleFormDelete}
									overlayClassName="popconfirm-danger"
									onCancel={() => {
										setDeletePopover(false);
									}}
									okText="Delete"
									okType="danger"
									icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
									cancelText={<span>Cancel</span>}
									cancelButtonProps={{ ghost: true }}
								>
									{/* Delete Button */}
									<Button
										disabled={!activeRowKey}
										className="ant-btn-secondary"
										style={{ marginRight: "auto" }}
										danger
										onClick={() => {
											setDeletePopover(true);
										}}
									>
										Delete
									</Button>
								</Popconfirm>
							)}
							{/* Cancel Button - Popconfirm */}
							<Popconfirm
								icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
								title={
									<div style={{ maxWidth: "300px", padding: "10px" }}>
										<>
											You have unsaved changes. Discard them or continue
											editing?
										</>
									</div>
								}
								open={cancelPopover}
								placement="topRight"
								cancelButtonProps={{ ghost: true }}
								cancelText="Discard changes"
								onCancel={() => {
									setCancelPopover(false);
									setTimeout(() => {
										handleFormCancel();
									}, 300);
								}}
								okText="Continue editing"
								okType="default"
								onConfirm={() => {
									setCancelPopover(false);
								}}
							>
								{/* Cancel Button */}
								<Button
									className="ant-btn-secondary"
									onClick={() => {
										if (formReset) {
											handleFormCancel();
										} else {
											setCancelPopover(true);
										}
									}}
								>
									Cancel
								</Button>
							</Popconfirm>
							{/* Save Button */}
							{hasEditOperationPermission && (
								<FormButtonSave
									activeKey={activeRowKey}
									form={formRef}
									handleFormSave={handleFormSave}
									formReset={formReset}
									setFormReset={setFormReset}
								/>
							)}
						</>
					);
				}}
			>
				<div style={{ height: "50vh" }}>
					<CustomForm
						tabKey={"operation"}
						formRef={formRef}
						setFormReset={setFormReset}
						dataOptions={props.extraData}
						activeRowKey={activeRowKey}
						activeRecord={props.operationRecord}
						readOnly={!hasEditOperationPermission}
					/>
				</div>
			</Modal>
		</>
	);
}

export default OperationConfigModal;
