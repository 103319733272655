import { Button, Flex, Space, Table } from "antd";
import { ColumnType } from "antd/es/table";
import React, { useEffect, useMemo, useState } from "react";
import TimesheetSummaryHeader from "../containers/TimesheetSummaryHeader";
import TimewritingCalendar from "../containers/TimewritingCalendar";
import { useTimewriting } from "../contexts/TimewritingContext";
import { GetAntIcon } from "../utils/ant_icons";
import ExportExcelButton from "./ExportExcelButton";
import WeekNavigator from "./WeekNavigator";
import SearchButton from "./SearchButton";

interface ProjectSummaryTableData {
	project_name: string;
	client_name: string;
	logged_hours: number;
	submitted_timesheet: number;
	pending_review: number;
	approved: number;
	rejected: number;
}

function TimesheetWeekNavigationHeader({
	project,
	data,
	openCalendar,
	setOpenCalendar,
	children,
}: {
	project: any;
	data: any[];
	openCalendar: boolean;
	setOpenCalendar: (openCalendar: boolean) => void;
	children?: React.ReactNode;
}) {
	return (
		<>
			<div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
				{openCalendar && (
					<Button type="link" onClick={() => setOpenCalendar(false)}>
						{GetAntIcon("leftarrow")}Back
					</Button>
				)}
				{children}
			</div>
			<Flex align="center" gap={12} justify="flex-end">
				<strong>{project.project_name}</strong>
				<span className="timesheet-summary-header-divider">|</span>
				<TimesheetSummaryHeader
					data={data}
					project_id={project._id}
					customWeekLabel={"Total submitted hours (Week)"}
					customMonthLabel={"Total submitted hours (Month)"}
				/>
				<ExportExcelButton
					title={"Export Excel"}
					project_id={project._id}
					tenant_key={project.tenant_key}
				/>
			</Flex>
		</>
	);
}

function ProjectSummaryTable() {
	const {
		weeklyProjectSummary,
		allUserWeekSubmittedReview,
		timesheetManagementData,
	} = useTimewriting();

	const [openCalendar, setOpenCalendar] = useState<boolean>(false);
	const [userTimeEntries, setUserTimeEntries] = useState<any[]>([]);
	const [activeProject, setActiveProject] = useState<any>(null);
	// const [projectSummaryData, setProjectSummaryData] = useState<any[]>([]);
	const [filteredProjectSummaryData, setFilteredProjectSummaryData] = useState<
		any[] | null
	>(null);

	const handleSearch = (e: any) => {
		const filteredData = weeklyProjectSummary.filter((item: any) =>
			item[e.columnKey]?.toLowerCase().includes(e.value.toLowerCase())
		);
		console.log("debug filteredData", filteredData);
		if (filteredData.length > 0) setFilteredProjectSummaryData(filteredData);
		else setFilteredProjectSummaryData(null);
	};

	const columns: ColumnType<ProjectSummaryTableData>[] = [
		{
			title: (
				<SearchButton
					handleSearch={handleSearch}
					headerName="Project name"
					columnKey="project_name"
				/>
			),
			dataIndex: "project_name",
			key: "project_name",
			width: "15%",
			render: (text: string, record: any) => {
				return (
					<div>
						<span>{text}</span>
						<Space style={{ fontSize: "12px", color: "grey" }}>
							<span>
								{GetAntIcon("apartment")} {record.tenant_name}
							</span>
							<span>
								{GetAntIcon("profile")} {record.client_name}
							</span>
						</Space>
					</div>
				);
			},
		},
		{
			title: (
				<SearchButton
					handleSearch={handleSearch}
					headerName="Client name"
					columnKey="client_name"
				/>
			),
			dataIndex: "client_name",
			key: "client_name",
			width: "15%",
		},
		{
			title: "No. of members",
			key: "total_members",
			width: 200,
			render: (_: any, record: any) => {
				const members = [
					...(record.general_members ?? []),
					...(record.project_manager ?? []),
					...(record.role_assignments.flatMap((role: any) => role.appointee) ??
						[]),
				];
				return Array.from(new Set([...members])).length;
			},
			sorter: (a: any, b: any) => {
				const countMembers = (item: any) => {
					const members = [
						...(item.general_members ?? []),
						...(item.project_manager ?? []),
						...(item.role_assignments.flatMap((role: any) => role.appointee) ??
							[]),
					];
					return Array.from(new Set([...members])).length;
				};
				return countMembers(a) - countMembers(b);
			},
		},
		{
			title: "Total logged hours",
			dataIndex: "logged_hours",
			key: "logged_hours",
			width: 200,
			render: (_: any, record: any) => {
				const loggedHours = record.time_entries.reduce(
					(acc: any, entry: any) => {
						return acc + (entry.duration_hours * 60 + entry.duration_mins);
					},
					0
				);
				const hours = Math.floor(loggedHours / 60);
				const minutes = loggedHours % 60;
				if (isNaN(hours) && isNaN(minutes)) {
					return "-";
				}
				return `${isNaN(hours) ? 0 : hours}h ${isNaN(minutes) ? 0 : minutes}m`;
			},
			sorter: (a: any, b: any) => {
				const hoursA = a.logged_hours || 0;
				const hoursB = b.logged_hours || 0;
				return hoursA - hoursB;
			},
		},
		{
			title: "Submitted timesheet",
			dataIndex: "submitted_timesheet",
			key: "submitted_timesheet",
			width: 200,
			render: (_: any, record: any) => {
				return (
					record.time_entries.filter((entry: any) =>
						["submitted", "resubmitted", "approved", "rejected"].includes(
							entry.status
						)
					).length || "-"
				);
			},
			sorter: (a: any, b: any) => {
				const totalSubmittedA =
					!a.submitted_timesheet || a.submitted_timesheet == "-"
						? 0
						: a.submitted_timesheet;
				const totalSubmittedB =
					!b.submitted_timesheet || b.submitted_timesheet == "-"
						? 0
						: b.submitted_timesheet;
				return totalSubmittedA - totalSubmittedB;
			},
		},
		{
			title: "Pending review",
			dataIndex: "pending_review",
			key: "pending_review",
			width: 200,
			render: (_: any, record: any) => {
				return (
					record.time_entries.filter(
						(entry: any) =>
							entry.status === "submitted" || entry.status === "resubmitted"
					).length || "-"
				);
			},
			sorter: (a: any, b: any) => {
				const totalPendingA =
					!a.pending_review || a.pending_review == "-" ? 0 : a.pending_review;
				const totalPendingB =
					!b.pending_review || b.pending_review == "-" ? 0 : b.pending_review;
				return totalPendingA - totalPendingB;
			},
		},
		{
			title: "Approved",
			dataIndex: "approved",
			key: "approved",
			width: 200,
			render: (_: any, record: any) => {
				return (
					record.time_entries.filter(
						(entry: any) => entry.status === "approved"
					).length || "-"
				);
			},
			sorter: (a: any, b: any) => {
				const totalApprovedA =
					!a.approved || a.approved == "-" ? 0 : a.approved;
				const totalApprovedB =
					!b.approved || b.approved == "-" ? 0 : b.approved;
				return totalApprovedA - totalApprovedB;
			},
		},
		{
			title: "Rejected",
			dataIndex: "rejected",
			key: "rejected",
			width: 200,
			render: (_: any, record: any) => {
				return (
					record.time_entries.filter(
						(entry: any) => entry.status === "rejected"
					).length || "-"
				);
			},
			sorter: (a: any, b: any) => {
				const totalRejectedA =
					!a.rejected || a.rejected == "-" ? 0 : a.rejected;
				const totalRejectedB =
					!b.rejected || b.rejected == "-" ? 0 : b.rejected;
				return totalRejectedA - totalRejectedB;
			},
		},
	];

	const getUserCalendarClassName = (cell: any) => {
		const userSubmittedReview = allUserWeekSubmittedReview.find(
			(submitted_review: any) => submitted_review.user === cell.user
		);
		const projectSubmission = userSubmittedReview?.submissions.find(
			(submission: any) => submission.project_id === activeProject._id
		);
		const status = projectSubmission?.status;
		console.log("debug status", status);
		if (status === "rejected") return "cell-rejected cell-submitted";
		if (status === "approved") return "cell-approved cell-submitted";
		return "";
	};

	const getUserTimeEntryFromProject = (project: any) => {
		const projectTimeEntries = project.time_entries.filter(
			(entry: any) => entry.project_id === project._id
		);
		const groupedByEmail = projectTimeEntries.reduce((acc: any, item: any) => {
			const email = item.email;
			if (item.status) {
				if (!acc[email]) {
					acc[email] = [];
				}
				acc[email].push(item);
			}
			return acc;
		}, {});
		const groupedByEmailList = Object.entries(groupedByEmail)
			.map(([user, timeEntries]) => ({
				user,
				...project,
				time_entries: timeEntries,
			}))
			.sort((a: any, b: any) => {
				const userA = a.user;
				const userB = b.user;
				if (userA === userB) return 0;
				return userA < userB ? -1 : 1;
			});
		setUserTimeEntries(groupedByEmailList);
	};

	useEffect(() => {
		// filter out unrelated time entries not within the active project from time_entries
		if (activeProject) {
			const filteredTimeEntries = timesheetManagementData
				.map((item: any) => {
					const time_entries = item.time_entries.filter(
						(entry: any) => entry.project_id === activeProject._id
					);
					if (time_entries.length > 0) {
						return {
							...item,
							time_entries,
						};
					}
					return null;
				})
				.filter((item: any) => item !== null);
			setUserTimeEntries(filteredTimeEntries);
		}
	}, [timesheetManagementData, activeProject]);

	const handleRowClick = async (record: any) => {
		setOpenCalendar(true);
		setActiveProject(record);
		getUserTimeEntryFromProject(record);
	};

	return (
		<>
			{openCalendar ? (
				<TimewritingCalendar
					project={activeProject}
					data={userTimeEntries}
					headerComponent={
						<TimesheetWeekNavigationHeader
							project={activeProject}
							data={userTimeEntries}
							openCalendar={openCalendar}
							setOpenCalendar={setOpenCalendar}
						/>
					}
					type="user"
					cellClassName={(cell) => getUserCalendarClassName(cell)}
				/>
			) : (
				<>
					<div className="week-navigator">
						<WeekNavigator />
					</div>
					<Table
						style={{ overflow: "auto" }}
						onRow={(record) => {
							return {
								onClick: () => handleRowClick(record),
							};
						}}
						rootClassName="timesheet-management-table"
						columns={columns}
						dataSource={filteredProjectSummaryData ?? weeklyProjectSummary}
						pagination={false}
						bordered
					/>
				</>
			)}
		</>
	);
}

export default ProjectSummaryTable;
