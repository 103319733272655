import {
	Avatar,
	Button,
	Drawer,
	Empty,
	Flex,
	Modal,
	Popconfirm,
	Space,
} from "antd";
import {
	MutableRefObject,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { GetAntIcon } from "../../utils/ant_icons";
import { Link } from "react-router-dom";
import TextEditor from "../TextEditor";
import { HomeContext } from "../../containers/Home";
import {
	getTaskComments,
	getDeliverableComments,
	addTaskComment,
	addDeliverableComment,
	deleteComment,
	editComment,
} from "../../services/api-server/discussion";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Emitter from "../../utils/emitter";
import elementalSvg from "../../assets/image/elemental 'e'.svg";

interface TextEditorHandle {
	clearEditor: () => void; // Method to clear the editor
	getEditor: () => {
		root: {
			innerHTML: string;
		};
	};
}

function DiscussionButton({
	setDiscussionOpen,
}: {
	setDiscussionOpen: (open: boolean) => void;
}) {
	return (
		<div onClick={() => setDiscussionOpen(true)}>
			{GetAntIcon("comment")} Discussion
		</div>
	);
}

function LeaveConfirmationModal({
	open,
	setOpen,
	discussionOpen,
	setDiscussionOpen,
}: {
	open: boolean;
	setOpen: (open: boolean) => void;
	discussionOpen: boolean;
	setDiscussionOpen: (open: boolean) => void;
}) {
	return (
		<Modal
			open={open}
			closable={false}
			maskClosable={false}
			cancelButtonProps={{ ghost: true }}
			cancelText="Leave"
			okText="Continue editing"
			okType="default"
			rootClassName="leave-changes-modal"
			onCancel={() => {
				setOpen(false);
				setDiscussionOpen(false);
			}}
			onOk={() => {
				setOpen(false);
			}}
		>
			<Space>
				<Flex align="center" gap={20}>
					<ExclamationCircleOutlined style={{ color: "grey" }} />
					Leave without saving? Changes will not be saved.
				</Flex>
			</Space>
		</Modal>
	);
}

function DeliverablesDiscussionModal(props: any) {
	const { userInfo, currentTenantKey }: any = useContext(HomeContext);

	const [discussionOpen, setDiscussionOpen] = useState<boolean>(false);
	const [newComment, setNewComment] = useState<string>("");
	const [newCommentTrimmed, setNewCommentTrimmed] = useState<string>("");
	const [comments, setComments] = useState<any[]>([]);
	const [hideButtons, setHideButtons] = useState<any>(true);

	// Add comment handling
	const [addButtonDisabled, setAddButtonDisabled] = useState<any>(true);
	// Edit comment handling
	const [editButtonDisabled, setEditButtonDisabled] = useState<any>(true);
	const [editCommentClick, setEditCommentClick] = useState<any>(false);
	const [editCommentID, setEditCommentID] = useState<any>(null);
	const [editCommentContent, setEditCommentContent] = useState<string>("");
	const [commentCompare, setCommentCompare] = useState<string>("");
	// Delete comment handling
	const [deleteCommentClick, setDeleteCommentClick] = useState<any>(false);
	const [deleteCommentID, setDeleteCommentID] = useState<any>(null);
	// Drawer container reference
	//   const drawerRef = useRef<HTMLDivElement>(null);
	// Add comment container reference
	const containerRef = useRef<HTMLDivElement>(null);
	// Text editor reference
	const editorRef = useRef<TextEditorHandle | null>(null);

	useEffect(() => {
		const editorContent = editorRef.current?.getEditor()?.root.innerHTML || ""; // Get HTML content
		const textContent = editorContent.replace(/<[^>]+>/g, ""); // Remove all HTML tags
		const trimmedContent = textContent.trim(); // Trim whitespace
		setNewCommentTrimmed(trimmedContent);

		const isCommentValid = trimmedContent.length > 0; // Check if there is any text
		setAddButtonDisabled(!isCommentValid); // Enable or disable button based on text presence

		if (isCommentValid) {
			setHideButtons(false);
		}

		const clickOutsideAddComment = (event: MouseEvent) => {
			// if the click happens outside the `containerRef`, `hideButtons` is set to true
			if (
				containerRef.current &&
				!containerRef.current.contains(event.target as Node) &&
				!isCommentValid
			) {
				setHideButtons(true);
			}
		};

		document.addEventListener("mousedown", clickOutsideAddComment);
		return () => {
			document.removeEventListener("mousedown", clickOutsideAddComment);
		};
	}, [newComment, containerRef]); // Run this effect when newComment changes

	const loadComments = (tenantKey: any, taskId: any, deliverableId: any) => {
		// Call only the necessary API based on props.sideContainerData
		const commentApi = props.sideContainerData?._id
			? getDeliverableComments(tenantKey, taskId, deliverableId)
			: getTaskComments(tenantKey, taskId);

		commentApi
			.then((data: any) => {
				if (Array.isArray(data)) {
					setComments(data);
					console.log("Loaded comments:", data);
				} else {
					setComments([]);
				}
			})
			.catch((e) => {
				console.error("Error loading comments:", e);
			});
	};

	useEffect(() => {
		if (currentTenantKey && props.task_id) {
			loadComments(
				currentTenantKey,
				props.task_id,
				props.sideContainerData?._id
			);
		}
	}, [
		(currentTenantKey && props.task_id) ||
			(currentTenantKey && props.sideContainerData),
		props.sideContainerData?._id,
	]);

	// Add comment handling
	const handleAddComment = async () => {
		// Insert `newComment` from `TextEditor` into `originalComment`
		const originalComment = newComment;

		// Check for empty comment (only whitespace)
		if (originalComment.trim() === "") return;

		try {
			const addCommentApi = await (props.sideContainerData
				? addDeliverableComment(
						currentTenantKey,
						props.task_id,
						props.sideContainerData?._id,
						userInfo.user.email,
						originalComment
				  )
				: addTaskComment(
						currentTenantKey,
						props.task_id,
						userInfo.user.email,
						originalComment
				  ));

			// Update comments state
			setComments((prev) => [...prev, addCommentApi]); // Add the new comment to the comments array
			setNewComment(""); // Clear the input field
			editorRef.current?.clearEditor(); // Clear the TextEditor content

			console.log("Comment added successfully");

			Emitter.emit("alert", {
				type: "success",
				message: "Comment has been successfully saved.",
				description: "",
				top: true,
				closeable: false,
				timeout: 3000,
			});
		} catch (error) {
			console.error("Error adding comment:", error);
		}
	};

	const handleEditInit = (editCommentID: any, editCommentContent: any) => {
		// Handle the Clicking of Edit Button
		setEditCommentClick(!editCommentClick);
		// Handle the storing of Selected Comment ID
		setEditCommentID(editCommentID);

		// Handle the storing of Selected Comment
		setEditCommentContent(editCommentContent);
		// Handle the storing of New Comment
		setCommentCompare(editCommentContent);
	};

	const handleEditComment = async (commentId: any, editCommentContent: any) => {
		// Insert `updatedComment` from `TextEditor` into `originalComment`
		const updatedComment = editCommentContent;

		// Check for empty comment (only whitespace)
		if (updatedComment.trim() === "") {
			return;
		}

		try {
			// Call the `editComment` API with the updated comment data
			const data = await editComment(
				currentTenantKey,
				commentId,
				updatedComment
			);

			// Update the comments state by replacing the edited comment
			setComments((prev) =>
				prev.map((comment) => {
					return comment._id === commentId ? data : comment;
				})
			);

			setNewComment("");
			/* HandleEditInit */
			setEditCommentClick(false);
			setEditCommentID(null);
			setCommentCompare("");

			editorRef.current?.clearEditor(); // Clear the TextEditor content
		} catch (error) {
			console.error("Error editing comment:", error);
		}
	};

	const handleDeleteInit = (deleteCommentID: any) => {
		// Handle the Clicking of Delete Button
		setDeleteCommentClick(!deleteCommentClick);

		// Handle the storing of Selected Comment ID
		setDeleteCommentID(deleteCommentID);
	};

	const handleDeleteComment = async (commentId: any) => {
		try {
			// Call the deleteComment API function with the correct commentId
			const data = await deleteComment(currentTenantKey, commentId);

			// Filter out the deleted comment from the state
			const updatedComments = comments.filter(
				(comment) => comment._id !== commentId
			);
			setComments(updatedComments);
		} catch (error) {
			console.error("Error deleting comment: ", error);
		}
	};

	const handleCancelComment = () => {
		setNewComment(""); // Clear the input field
		editorRef.current?.clearEditor(); // Clear the TextEditor content

		setHideButtons(true);
	};

	const [open, setOpen] = useState(false);

	const onClose = () => {
		if (newComment && newCommentTrimmed.length > 0) {
			setOpen(true);
		} else {
			setOpen(false);
			setDiscussionOpen(false);
		}

		if (editCommentContent) {
			const cleanText = (text: string) =>
				text
					.replace(/<[^>]*>/g, "")
					.replace(/\s+/g, " ")
					.trim();

			if (cleanText(editCommentContent) !== cleanText(commentCompare)) {
				setOpen(true);
			} else {
				setOpen(false);
				setDiscussionOpen(false);
			}
		}
	};

	useEffect(() => {
		if (!discussionOpen) {
			if (newComment && newCommentTrimmed.length > 0) {
				setNewComment("");
				editorRef.current?.clearEditor();
				console.log("run1");
			}

			if (editCommentClick) {
				/* HandleEditInit */
				setEditCommentClick(false);
				setEditCommentID(null);
				setCommentCompare("");

				editorRef.current?.clearEditor(); // Clear the TextEditor content
				console.log("run2");
			}
		}
	}, [discussionOpen]);

	useEffect(() => {
		console.log("Open: ", open, "DiscussionOpen: ", discussionOpen);
	}, [open, discussionOpen]);

	return (
		<>
			{/* Discussion button */}
			<DiscussionButton setDiscussionOpen={setDiscussionOpen} />
			{/* Discussion drawer */}
			<Drawer
				title={`${
					props.sideContainerData
						? `${props.sideContainerData?.deliverable_name}`
						: `${props.taskInfo?.task_name}`
				}`}
				open={discussionOpen}
				onClose={onClose}
				closeIcon={GetAntIcon("rightarrow")}
				width={"50vw"}
				zIndex={9999}
				id="deliverable-discussion-modal"
			>
				{/* Add comment container */}
				{props.taskInfo?.closed !== true && (
					<div
						ref={containerRef}
						style={{
							width: "100%",
							height: "auto",
							borderRadius: "5px",
							backgroundColor: "rgba(255, 255, 255, 0.1)",
							boxShadow: "7px 7px 11px 0px #00000040",
						}}
					>
						{/* Add comment container contents */}
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "15px",
								fontSize: "14px",
								padding: "20px 30px 25px 30px",
							}}
						>
							{/* User details */}
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "12px",
								}}
							>
								{/* Avatar */}
								<Avatar
									size={20}
									icon={GetAntIcon("user")}
									src={userInfo?.user?.profile_picture}
								/>
								{/* Name */}
								<Link
									to={`/profile`} // Navigate to the user's profile page based on their ID
								>
									<span>{userInfo?.user?.name}</span>
								</Link>
							</div>
							{/* Text Editor */}
							<TextEditor
								ref={editorRef}
								onChange={setNewComment}
								onClick={() => setHideButtons(false)}
								value={newComment}
								disabled={editCommentClick}
							/>
							{/* Buttons Container */}
							{!hideButtons && (
								<div
									style={{
										display: "flex",
										justifyContent: "end",
										gap: "8px",
									}}
								>
									<Button
										className="ant-btn-secondary"
										onClick={handleCancelComment}
									>
										Cancel
									</Button>
									<Button
										className="ant-btn-primary"
										onClick={handleAddComment}
										disabled={addButtonDisabled}
									>
										Save
									</Button>
								</div>
							)}
						</div>
					</div>
				)}
				{/* Comments Container */}
				<div style={{ width: "100%", height: "100%" }}>
					{comments && comments.length > 0
						? comments
								.slice()
								.reverse()
								.map((comment: any) => {
									return (
										/* Comment container */
										<div
											key={comment._id}
											style={{
												height: "auto",
												borderRadius: "5px",
												padding: "20px 30px 25px 30px",
												boxShadow: "7px 7px 11px 0px #00000040",
												marginBottom: "15px",
												position: "relative",
											}}
											className="comment-container"
										>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													gap: "15px",
													fontSize: "14px",
												}}
											>
												<div
													style={{
														display: "flex",
														justifyContent: "space-between",
													}}
												>
													{/* User details */}
													<div
														style={{
															display: "flex",
															gap: "12px",
														}}
													>
														{/* User Avatar */}
														<Avatar
															size={20}
															icon={GetAntIcon("user")}
															src={
																props?.commentingUser(comment.user)
																	?.profile_picture
															}
														/>
														{/* Username */}
														{userInfo?.user?.email === comment.user ? (
															<Link to={`/profile`}>
																<span>{comment.user}</span>
															</Link>
														) : (
															<span>{comment.user}</span>
														)}
														{/* Comment DateTime */}
														<span className="comment-date-time">
															{new Date(comment.created_on)
																.toLocaleString("en-GB", {
																	day: "numeric",
																	month: "short",
																	year: "numeric",
																	hour: "2-digit",
																	minute: "2-digit",
																	second: "2-digit",
																	hour12: true,
																})
																.replace(",", "")
																.replace(" am", " AM")
																.replace(" pm", " PM")}
														</span>
													</div>
													{/* Edit and delete buttons container */}
													{props.taskInfo?.closed !== true &&
														userInfo?.user?.email === comment.user && (
															<div>
																<div
																	style={{
																		display: "flex",
																		gap: "11px",
																	}}
																>
																	{/* Edit Button */}
																	<Button
																		size="small"
																		shape="circle"
																		icon={GetAntIcon("edit")}
																		style={{ border: "none" }}
																		onClick={() =>
																			handleEditInit(
																				comment._id,
																				comment.comment
																			)
																		}
																		disabled={newCommentTrimmed.length > 0}
																	/>
																	{/* Delete Button */}
																	<Button
																		size="small"
																		shape="circle"
																		icon={GetAntIcon("delete")}
																		onClick={() =>
																			handleDeleteInit(comment._id)
																		}
																	/>
																	{/* Delete confirmation process */}{" "}
																	{deleteCommentClick &&
																		comment._id === deleteCommentID && (
																			<div
																				style={{
																					position: "absolute",
																					right: "40px",
																				}}
																			>
																				<Popconfirm
																					zIndex={9999}
																					getPopupContainer={(trigger: any) =>
																						trigger.parentNode
																					}
																					title={
																						<div
																							style={{
																								maxWidth: "300px",
																								padding: "10px",
																							}}
																						>
																							<>
																								Permanently delete this comment?
																								<br />
																								This action cannot be undone.
																							</>
																						</div>
																					}
																					open={deleteCommentClick}
																					onConfirm={() =>
																						handleDeleteComment(comment._id)
																					}
																					overlayClassName="popconfirm-danger"
																					onCancel={() => {
																						setDeleteCommentClick(false);
																					}}
																					okText="Delete"
																					okType="danger"
																					icon={
																						<ExclamationCircleOutlined
																							style={{
																								color: "grey",
																							}}
																						/>
																					}
																					cancelText={<span>Cancel</span>}
																					cancelButtonProps={{
																						ghost: true,
																					}}
																				/>
																			</div>
																		)}
																</div>
															</div>
														)}
												</div>
												{/* Edit Comment Process */}
												{editCommentClick && editCommentID === comment._id ? (
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															gap: "15px",
														}}
													>
														<TextEditor
															ref={editorRef}
															value={editCommentContent}
															onChange={setEditCommentContent}
														/>
														<div
															style={{
																display: "flex",
																justifyContent: "end",
																gap: "8px",
															}}
														>
															<Button
																className="ant-btn-secondary"
																onClick={() => {
																	handleEditInit(comment._id, comment.comment);
																}}
															>
																Cancel
															</Button>
															<Button
																className="ant-btn-primary"
																disabled={!editButtonDisabled} // Disable button if content hasn't changed
																onClick={() =>
																	handleEditComment(
																		editCommentID,
																		editCommentContent
																	)
																}
															>
																Save
															</Button>
														</div>
													</div>
												) : (
													/* Comment Container */
													<div className="comment-box">
														{/* Splitting Lines: The split(/(<p[^>]*>.*?<\/p>)/g) regex separates each paragraph (<p> tag) */}
														{comment.comment
															.split(/(<p[^>]*>.*?<\/p>)/g)
															// `.map` is used to iterate over any array
															.map(
																// line :any - To hold the element being processed (text or HTML elements)
																// index :any - To represent the current index of the line within the array being processed
																(line: any, index: any) => {
																	// Each line is checked for an indent level, which could either be from 0 to 8
																	const indentMatch =
																		line.match(/ql-indent-(\d+)/);
																	// If there is a classname `ql-indent-{}` appended to the line
																	const paddingLeft = indentMatch
																		? // If the string has ql-indent-2, indentMatch will capture 2
																		  `${
																				parseInt(indentMatch[1]) *
																				// Multiplies it by 39 pixels
																				39
																		  }px`
																		: // Defaults to 0 if there is no `ql-indent-{}` class
																		  "0";

																	return (
																		<div
																			key={index}
																			style={{ paddingLeft }}
																			dangerouslySetInnerHTML={{
																				__html: line,
																			}}
																		/>
																	);
																}
															)}
													</div>
												)}
											</div>
										</div>
									);
								})
						: props.taskInfo?.closed === true && (
								<div
									style={{
										height: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center ",
									}}
								>
									<Empty
										className="no-data-empty"
										style={{ margin: "auto", fontSize: "20px" }}
										image={elementalSvg}
										description="No comments available."
									/>
								</div>
						  )}
				</div>
				{/* Leave with changes modal */}
				<LeaveConfirmationModal
					open={open}
					setOpen={setOpen}
					discussionOpen={discussionOpen}
					setDiscussionOpen={setDiscussionOpen}
				/>
			</Drawer>
		</>
	);
}

export default DeliverablesDiscussionModal;
