import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Popconfirm, Popover, Select, Space, Tabs } from "antd";
import { useForm } from "antd/es/form/Form";
import _ from "lodash";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import "../assets/scss/projects.scss";
import CRUDTabTableModal from "../components/CRUDTabTableModal";
import CustomForm from "../components/CustomForm";
import FormButtonCancel from "../components/FormButtonCancel";
import FormButtonSave from "../components/FormButtonSave";
import DeliverableConfigurationModal from "../components/Modal/DeliverableConfigurationModal";
import TaskConfigurationModal from "../components/Modal/TaskConfigurationModal";
import TemplateDisabledModal from "../components/Modal/TemplateDisabledModal";
import usePermission from "../hooks/usePermission";
import { updateDeliverableDisabled } from "../services/api-server/deliverables";
import {
	deleteOperationItem,
	getOperations,
	updateOperationItem,
} from "../services/api-server/operations";
import {
	getPhasesAndTasks,
	updatePhaseDisabled,
} from "../services/api-server/phases";
import { getProject } from "../services/api-server/projects";
import { updateTaskDisabled } from "../services/api-server/tasks";
import { getWells } from "../services/api-server/wells";
import { GetAntIcon } from "../utils/ant_icons";
import Emitter from "../utils/emitter";
import { getCrudaClass } from "../utils/lookup_list";
import { checkPrivilege } from "../utils/utils";
import { HomeContext } from "./Home";
import NoAccess from "./NoAccess";

// import ProjectDetails from "../projectTabs/ProjectDetails";

const { Option } = Select;

/*
	- currentWellProject is the project code of the current project
*/
function OperationSettings(props: any) {
	const context: any = useContext(HomeContext);
	const location = useLocation();
	const navigate = useNavigate();
	const [allData, setAllData] = useState<any>({});

	const [projectInfo, setProjectInfo] = useState<any>(null);
	const [wellInfo, setWellInfo] = useState<any>(null);
	const [isLoading, setIsLoading] = useState<any>(true);

	const [phaseTaskRecord, setPhaseTaskRecord] = useState<any>(null);
	const [deliverableRecord, setDeliverableRecord] = useState<any>(null);
	const [taskModalOpen, setTaskModalOpen] = useState<any>(false);
	const [deliverableModalOpen, setDeliverableModalOpen] = useState<any>(false);

	const [allWellProjects, setAllWellProjects] = useState<any>([]);
	const [currentWellProject, setCurrentWellProject] = useState<any>(null);
	const [formRef]: any = useForm();
	const [formReset, setFormReset] = useState<boolean>(true);
	const [emptyModalOpen, setEmptyModalOpen] = useState<any>(null);

	const [initialForm, setInitialForm] = useState<any>(null);
	const [popOverVisibility, setPopOverVisibility] = useState<boolean>(false);
	const [dropdownOpen, setDropdownOpen] = useState<any>(false);

	const [otherData, setOtherData] = useState<any>(null);
	const [deliverableExtraData, setDeliverableExtraData] = useState<any>(null);
	const [allCampaignTasks, setAllCampaignTasks] = useState<any>(null);
	const [filteredDataOptions, setFilteredDataOptions] = useState<any>(null);

	const [templateModalOpen, setTemplateModalOpen] = useState<any>(false);
	const [templateObject, setTemplateObject] = useState<any>(null);

	const hasEditOperationPermission = usePermission(
		"Edit.Operation",
		projectInfo?.project_code
	);

	const tabList: any = [
		{ label: "Operation details", key: "well-project-details" },
		{
			label: "Well programme",
			key: "well-programme",
		},
	];
	const [tabKey, setTabKey] = useState<any>(() => {
		if (sessionStorage.getItem("main-tabkey")) {
			let sessionTabKey: any = sessionStorage.getItem("main-tabkey");
			let found = tabList.find((tab: any) => {
				return tab.key === sessionTabKey;
			});
			if (found) {
				return sessionTabKey;
			} else {
				sessionStorage.setItem("main-tabkey", "well-project-details");
				return "well-project-details";
			}
		} else {
			sessionStorage.setItem("main-tabkey", "well-project-details");
			return "well-project-details";
		}
	});

	// const [history, setHistory] = useState<any>([]);
	// const [sideContainerOpen, setSideContainerOpen] = useState<any>(false);

	// Load all the data needed for the form
	useEffect(() => {
		if (context.currentTenantKey && context.allData) {
			if (context.currentTenantKey && context.allData) {
				let project_code: any =
					location?.state?.project_code || location?.pathname?.split("/")[2];
				let operation_code: any =
					location?.state?.operation_code || location?.pathname?.split("/")[3];

				getProject(context.currentTenantKey, project_code)
					.then((project: any) => {
						let allData: any = project;
						setProjectInfo(project);
						context.setCurrentProject(project);
						getOperations(context.currentTenantKey, { project_code })
							.then((data: any) => {
								setAllWellProjects(data);
								let foundOperation = data.find(
									(operation: any) =>
										operation?.operation_code === operation_code
								);

								if (foundOperation) {
									context.setCurrentOperation(foundOperation);
									getWells(context.currentTenantKey, {
										_id: foundOperation?.well_id,
									})
										.then((wellData: any) => {
											const wellOperationData: any = {
												...wellData[0],
												...foundOperation,
											};
											setWellInfo(wellOperationData);
											resetForm(wellOperationData);
											setCurrentWellProject(operation_code);
										})
										.catch((error: any) => {});
									setOtherData({
										operation_code: foundOperation.operation_code,
									});

									// Filter allData.role_assignments to remove data that does not exist in context.allData.roles if roles in context.allData exist and not empty
									if (allData.role_assignments) {
										if (
											context.allData.roles &&
											context.allData.roles.length > 0
										) {
											allData.role_assignments =
												allData.role_assignments.filter((role: any) => {
													return context.allData.roles.find(
														(item: any) => item._id == role.role_id
													);
												});
										}
									}

									// Then push default project manager role id project manager exists
									if (allData.project_manager) {
										if (allData.role_assignments) {
											allData.role_assignments.push({
												role: "Project manager",
												role_id: "project_manager",
												appointee: [...allData.project_manager],
											});
										} else {
											allData.role_assignments = [
												{
													role: "Project manager",
													role_id: "project_manager",
													appointee: [...allData.project_manager],
												},
											];
										}
									}
									setDeliverableExtraData({
										...allData,
									});
									setAllData({ ...context.allData, ...allData });
								} else {
									setWellInfo(null);
								}

								const promises = data.map(async (item: any) => {
									return getPhasesAndTasks(context.currentTenantKey, {
										operation_code: item.operation_code,
									})
										.then((data: any) => {
											const _taskData: any = Object.values(
												data.task_data
											).reduce((acc: any, val: any) => acc.concat(val), []);
											return _taskData;
										})
										.catch((error: any) => {
											console.log(error);
										});
								});

								Promise.all(promises).then((data: any) => {
									const taskData = _.flatten(data);
									// sorting the task data ascending
									if (taskData?.length > 0) {
										taskData.sort((a: any, b: any) => {
											return (
												parseFloat(a.task_number) - parseFloat(b.task_number)
											);
										});
									}
									setAllCampaignTasks(taskData);
								});
							})
							.catch((error: any) => {
								setWellInfo(null);
							})
							.finally(() => {
								setIsLoading(false);
							});
					})
					.catch(() => {
						setProjectInfo(null);
						setIsLoading(false);
					});
			}
		}
	}, [context.currentTenantKey, context.allData, location.pathname]);

	// Emitter addition
	useEffect(() => {
		Emitter.on("taskModalOpen", async (payload: any) => {
			try {
				setPhaseTaskRecord(payload);
				setTaskModalOpen(true);
			} catch (error) {}
		});

		Emitter.on("templateDisableModalOpen", (payload: any) => {
			setTemplateObject({
				title: payload.title,
				message: payload.message,
				id: payload.id,
				level: payload.level,
			});
			setTemplateModalOpen(true);
		});

		//Help me
		Emitter.on("templateForceSave", (payload: any) => {
			handleTemplateModalSave(payload.id, payload.level, {
				operation_code: currentWellProject,
				disabled: false,
				disabled_reason: "",
			});
		});

		return () => {
			Emitter.remove("taskModalOpen");
			Emitter.remove("templateDisableModalOpen");
			Emitter.remove("templateForceSave");
		};
	}, [
		context.currentTenantKey,
		phaseTaskRecord,
		deliverableRecord,
		currentWellProject,
	]);

	// Handling Task Modal close
	const handleTaskModalClose = () => {
		setPhaseTaskRecord(null);
	};

	// Force table re-render
	// Have to figure out a way to update just one specific task to the table renderer
	const handleTaskFormUpdate = (payload: any) => {
		setOtherData({ operation_code: currentWellProject });
		if (payload) {
			setPhaseTaskRecord({
				...phaseTaskRecord,
				task: payload,
			});
		}
	};

	const handleDeliverableModalClose = () => {
		context?.handleFormUpdating(
			false,
			deliverableRecord?._id || "deliverable_new"
		);
		setDeliverableModalOpen(false);
		setDeliverableRecord(null);
	};

	const handleDeliverableFormUpdate = (payload: any = null) => {
		setOtherData({ operation_code: currentWellProject });
		//Force re-render to download deliverable data
		setPhaseTaskRecord({
			...phaseTaskRecord,
			task: { ...phaseTaskRecord.task },
		});
		setDeliverableRecord(payload);
	};

	const handleTemplateModalSave = (id: any, level: any, payload: any) => {
		Emitter.emit("loading", true);
		payload.operation_code = currentWellProject;
		if (level === "phase") {
			updatePhaseDisabled(
				context?.currentTenantKey,
				id,
				payload,
				props.params?.user
			)
				.then((result: any) => {
					Emitter.emit("forceUpdate", { data: payload, level: level });
					setTemplateModalOpen(false);
				})
				.catch((error: any) => {
					console.log(error);
				})
				.finally(() => {
					Emitter.emit("loading", false);
				});
		} else if (level === "task") {
			updateTaskDisabled(
				context?.currentTenantKey,
				id,
				payload,
				props.params?.user
			)
				.then((result: any) => {
					setPhaseTaskRecord({
						...phaseTaskRecord,
						task: { ...phaseTaskRecord?.task, ...payload },
					});
					Emitter.emit("forceUpdateTask", null);

					setTemplateModalOpen(false);
				})
				.catch((error: any) => {
					console.log(error);
				})
				.finally(() => {
					Emitter.emit("loading", false);
				});
		} else if (level === "deliverable") {
			updateDeliverableDisabled(
				context?.currentTenantKey,
				id,
				payload,
				props.params?.user
			)
				.then((result: any) => {
					setDeliverableRecord({ ...deliverableRecord, ...payload });
					Emitter.emit("forceUpdateDeliverable", {
						...deliverableRecord,
						...payload,
					});
					setTemplateModalOpen(false);
				})
				.catch((error: any) => {
					console.log(error);
				})
				.finally(() => {
					Emitter.emit("loading", false);
				});
		}
	};

	// reset form
	const resetForm = (values: any = null, data: any = null) => {
		if (formRef) {
			setFormReset(true);
			formRef.resetFields();
			formRef.setFieldsValue(values);

			let filteredExtraData: any = {};
			if (formRef.getFieldValue("well_purpose")) {
				let lookup = context.allData;
				let foundData = lookup?.well_purpose?.find(
					(element: any) =>
						element.uuid === formRef.getFieldValue("well_purpose")
				);
				if (foundData) {
					filteredExtraData.well_purpose_type = lookup?.well_purpose_type
						?.map((element: any) => {
							if (foundData.types.includes(element.uuid)) return element;
						})
						.filter(Boolean);
				}
			}
			if (formRef.getFieldValue("well_status")) {
				let lookup = context.allData;
				let foundData = lookup?.well_status?.find(
					(element: any) =>
						element.uuid === formRef.getFieldValue("well_status")
				);
				if (foundData) {
					filteredExtraData.well_status_type = lookup?.well_status_type
						?.map((element: any) => {
							if (foundData.types.includes(element.uuid)) return element;
						})
						.filter(Boolean);
				}
			}

			if (Object.keys(filteredExtraData).length > 0) {
				setFilteredDataOptions({ ...context.allData, ...filteredExtraData });
			}
		}
	};

	// Function to sort array of objects alphabetically based on property's name
	const sortData = (data: any) => {
		data.sort((a: any, b: any) => {
			if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			if (a.label.toLowerCase() > b.label.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		return data;
	};

	// Handle when project has change / adding a new project
	const handleProjectChange = async (
		key: any = null,
		data: any = null,
		manual: boolean = false
	) => {
		try {
			let projects = data?.projects || allWellProjects;
			await context.handlePageChange();
			Emitter.emit("loading", true);
			setCurrentWellProject(key);
			navigate(`/projects/${projectInfo?.project_code}/${key}/well-settings`);

			//Always set tabKey to project-details when project has changed
			if (manual) {
				setTabKey("well-project-details");
				sessionStorage.removeItem("tabKey");
			}

			if (key == "Add new project" || key == null) {
				Emitter.emit("projectName", "Add new project");
				setTabKey("well-project-details");
				sessionStorage.removeItem("tabKey");
				resetForm(null);
			} else {
				const project = projects.find((project: any) => {
					// Emit the project name to set the breadcrumb
					Emitter.emit("projectName", project.project_name);
					return project.project_code == key;
				});
				resetForm(project, data);
			}
			Emitter.emit("loading", false);
		} catch {}
	};

	const handleTabChange = async (key: any) => {
		try {
			await context.handlePageChange();
			setTabKey(key);
			setFormReset(true);
			sessionStorage.setItem("main-tabkey", key);
			const operation = allWellProjects.find((operation: any) => {
				return operation.operation_code == currentWellProject;
			});
			getWells(context.currentTenantKey, {
				_id: operation?.well_id,
			})
				.then((wellData: any) => {
					const wellOperationData: any = {
						...wellData[0],
						...operation,
					};
					resetForm(wellOperationData);
				})
				.catch((error: any) => {
					console.log(error);
				});
		} catch {}
	};

	// Handle form save
	const handleFormSave = (formValues: any | null = null, type: any = null) => {
		let values = formValues || formRef.getFieldsValue();

		// For each values in the form, trim the leading and trailing spaces
		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				values[key] = values[key].trim();
			}
		});

		Emitter.emit("loading", true);

		values.operation_code = currentWellProject;
		getCrudaClass(tabKey).then((crudaClassInstance: any) => {
			if (currentWellProject && wellInfo) {
				const form = crudaClassInstance.getFormList(tabKey);
				const formList: any = [];
				form.forEach((element: any) => {
					if (element.children && !("name" in element)) {
						element.children.forEach((child_element: any) => {
							if (child_element.name) formList.push(child_element.name);
						});
					} else {
						if (element.name) formList.push(element.name);
					}
				});

				values.modified_on = Date.now();
				values.project_code = projectInfo.project_code;
				console.log(values, initialForm, formList);
				updateOperationItem(
					context.currentTenantKey,
					wellInfo._id,
					values,
					initialForm,
					props.params.user,
					formList
				)
					.then((updatedData: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: "Operation details has been successfully updated.",
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						getWells(context.currentTenantKey, {
							_id: updatedData?.well_id,
						})
							.then((wellData: any) => {
								const wellOperationData: any = {
									...wellData[0],
									...updatedData,
								};
								resetForm(wellOperationData);
							})
							.catch((error: any) => {
								console.log(error);
							});
						context.setCurrentOperation(updatedData);

						// I dont know why its here so i just gonna leave it
						getOperations(context.currentTenantKey, {})
							.then((_data: any) => {
								setAllWellProjects(_data);
							})
							.catch((error: any) => {
								console.log(error);
							});
					})
					.catch((error: any) => {
						Emitter.emit("alert", {
							type: "error",
							message: "Failed to update operation. Please try again.",
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						resetForm();
					})
					.finally(() => {
						Emitter.emit("loading", false);
					});
			}
		});
	};

	const handleFormDelete = (project_code: any) => {
		Emitter.emit("loading", true);
		context?.handleFormUpdating(false);
		updateOperationItem(
			context.currentTenantKey,
			wellInfo._id,
			{ is_removed: true },
			null,
			props.params.user,
			null
		)
			.then(() => {
				Emitter.emit("alert", {
					type: "success",
					message: "Operation has been successfully deleted.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
				resetForm();
			})
			.catch((error: any) => {
				console.log("error", error);
				Emitter.emit("alert", {
					type: "error",
					message: "Failed to delete operation. Please try again.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.finally(() => {
				Emitter.emit("loading", false);
				navigate(`/projects/${projectInfo?.project_code}`, { replace: true });
			});
	};

	// Handle form Cancel
	const handleFormCancel = () => {
		context?.handleFormUpdating(false);
		if (currentWellProject != "Add new project") {
			const project = allWellProjects.find((project: any) => {
				return project.operation_code == currentWellProject;
			});
			resetForm(project);
		} else {
			resetForm();
		}
	};

	const tabContent = () => {
		switch (tabKey) {
			// Operation details tab contents
			case "well-project-details":
				return (
					<>
						{context?.allData && (
							<div
								style={{
									flex: "1",
									display: "flex",
									flexDirection: "row",
									overflow: "auto",
								}}
							>
								<div
									className="main-container"
									style={{
										width: "70%",
										display: "flex",
										flexDirection: "column",
										borderRadius: "3px",
									}}
								>
									<div className="generic-header" style={{ height: "50px" }}>
										Settings
									</div>
									<div className="generic-content" style={{ flex: "1" }}>
										<div style={{ flex: "1", position: "relative" }}>
											{/* {currentWellProject !== "Add new project" &&
										currentWellProject && (
											<span
												style={{
													position: "absolute",
													right: 30,
													color: "rgba(255, 255, 255, 0.5)",
												}}
											>
												# {currentWellProject}
											</span>
										)} */}
											{/* Add Form here */}
											<CustomForm
												setFormReset={setFormReset}
												handleFormSave={handleFormSave}
												formRef={formRef}
												tabKey={tabKey}
												filteredDataOptions={filteredDataOptions}
												dataOptions={context.allData}
												activeRowKey={wellInfo._id}
												activeRecord={wellInfo}
											/>
										</div>
									</div>
									<div className="generic-footer" style={{ height: "50px" }}>
										{(checkPrivilege(props?.params?.userRole, [
											"tenant-admin",
										]) ||
											projectInfo?.project_manager.some(
												(pm: any) =>
													pm.toLowerCase() ===
													props?.params?.user?.email.toLowerCase()
											)) &&
											currentWellProject && (
												<Popconfirm
													overlayInnerStyle={{
														maxWidth: "300px",
														padding: "16px",
													}}
													placement="topLeft"
													okText="Delete"
													okType="danger"
													title=""
													icon={
														<ExclamationCircleOutlined
															style={{ color: "grey" }}
														/>
													}
													cancelText={"Cancel"}
													cancelButtonProps={{ ghost: true }}
													overlayClassName="popconfirm-danger"
													description="Permanently delete this operation? This action cannot be undone."
													onOpenChange={(open: any) => {
														if (!open) {
															setEmptyModalOpen(false);
														}
													}}
													onConfirm={() => {
														setEmptyModalOpen(false);
														handleFormDelete(currentWellProject);
													}}
													onCancel={() => {
														setEmptyModalOpen(false);
													}}
												>
													<Button
														className="ant-btn-secondary"
														style={{ marginRight: "auto" }}
														danger
													>
														Delete
													</Button>
												</Popconfirm>
											)}
										<FormButtonCancel
											form={formRef}
											handleFormCancel={handleFormCancel}
											formReset={formReset}
										></FormButtonCancel>
										<Popover
											open={popOverVisibility}
											onOpenChange={(boolean: any) => {
												// if (checkCode()) {
												// 	setPopOverVisibility(boolean);
												// } else {
												// 	setPopOverVisibility(false);
												// }
											}}
											overlayStyle={{
												maxWidth: "420px",
												background: "rgba(57,57,57,1)",
												borderRadius: "8px",
												// padding: "16px",
											}}
											style={{ background: "transparent" }}
											trigger={"click"}
											content={
												<div
													style={{
														display: "flex",
														flexDirection: "row",
														gap: "8px",
													}}
												>
													<Button
														className="ant-button-default"
														style={{
															marginRight: "auto",
															border: "1px solid rgba(255, 255, 255, 0.1)",
														}}
														ghost
														onClick={() => {
															setPopOverVisibility(false);
															handleFormSave(null, "New");
														}}
													>
														Create new
													</Button>
													<Button
														className="ant-button-default"
														style={{
															border: "1px solid rgba(255, 255, 255, 0.1)",
														}}
														ghost
														onClick={() => {
															setPopOverVisibility(false);
														}}
													>
														Cancel
													</Button>
													<Button
														className="ant-button-default"
														onClick={() => {
															setPopOverVisibility(false);
															handleFormSave(null, "code");
														}}
													>
														Proceed
													</Button>
												</div>
											}
											title={
												<Space>
													<div style={{ color: "grey" }}>
														{GetAntIcon("exclamation")}
													</div>
													<span style={{ fontWeight: "400" }}>
														Changing the business unit or client info affects
														the project code. Do you wish to proceed?
													</span>
												</Space>
											}
										>
											<div>
												<FormButtonSave
													form={formRef}
													handleFormSave={handleFormSave}
													setInitialForm={setInitialForm}
													formReset={formReset}
													setFormReset={setFormReset}
												></FormButtonSave>
											</div>
										</Popover>
									</div>
								</div>
							</div>
						)}
					</>
				);

			// Well programme tab contents
			case "well-programme":
				return (
					<CRUDTabTableModal
						items={[
							{
								label: "Phases and tasks",
								key: "phase",
								directoryName: "Phases and tasks directory",
								configurationName: "Phase configuration",
								sortKey: "sequence_id",
							},
						]}
						tabKey={"phase"}
						otherData={otherData}
						user={props.params?.user}
						userPermissions={["update", "add", "add-phase", "delete"]}
						reorderModal
					/>
				);
			default:
				return <></>;
		}
	};

	if (typeof hasEditOperationPermission !== "boolean") {
		return <></>;
	}

	if (
		!hasEditOperationPermission &&
		typeof hasEditOperationPermission === "boolean"
	) {
		return (
			<NoAccess text="Oops, looks like you don't have the authorisation to view this page." />
		);
	}

	return (
		<>
			{projectInfo && wellInfo && !isLoading && (
				<div className="generic-container well-settings">
					<Space direction="vertical">
						<Select
							getPopupContainer={(trigger: any) => trigger.parentNode}
							className="title-selector"
							style={{
								fontSize: "20px",
								fontWeight: "600",
								width: "auto",
							}}
							options={allWellProjects}
							value={currentWellProject}
							open={dropdownOpen}
							onClick={() => {
								setDropdownOpen(!dropdownOpen);
							}}
							onChange={(key: any) => {
								handleProjectChange(key, null, true);
								setDropdownOpen(false);
							}}
							onBlur={(event: any) => {
								event.preventDefault();
								console.log(event.relatedTarget);
								setDropdownOpen(false);
							}}
							fieldNames={{
								label: "operation_name",
								value: "operation_code",
							}}
							dropdownStyle={{ minWidth: "100%", width: "auto" }}
							dropdownRender={(menu) => {
								return <>{menu}</>;
							}}
						></Select>

						<Tabs
							activeKey={tabKey}
							onChange={handleTabChange}
							items={tabList}
						></Tabs>
					</Space>
					<div
						style={{
							flex: "1",
							display: "flex",
							flexDirection: "row",
							gap: "20px",
							maxHeight: "100%",
							overflow: "auto",
						}}
					>
						{currentWellProject && tabContent()}
					</div>

					<Modal
						className="empty-modal"
						open={emptyModalOpen}
						closable={false}
						maskClosable={true}
						onCancel={() => {
							setEmptyModalOpen(false);
						}}
						zIndex={1050}
						footer={<></>}
						style={{ background: "transparent" }}
					>
						<></>
					</Modal>
					{phaseTaskRecord?._id && allCampaignTasks && (
						<TaskConfigurationModal
							open={taskModalOpen}
							setOpen={setTaskModalOpen}
							handleFormUpdate={handleTaskFormUpdate}
							handleModalClose={handleTaskModalClose}
							setDeliverableModalOpen={setDeliverableModalOpen}
							setDeliverableRecord={setDeliverableRecord}
							currentProject={projectInfo}
							// wellProjects={allWellProjects}
							record={phaseTaskRecord}
							allCampaignTasks={allCampaignTasks}
							phaseID={phaseTaskRecord?._id}
							taskRecord={phaseTaskRecord?.task}
							activeRowKey={phaseTaskRecord?.task?._id}
							extraData={allData}
							user={props.params?.user}
							userRole={props.params?.userRole}
						/>
					)}
					<DeliverableConfigurationModal
						open={deliverableModalOpen}
						setOpen={setDeliverableModalOpen}
						handleFormUpdate={handleDeliverableFormUpdate}
						handleModalClose={handleDeliverableModalClose}
						taskID={phaseTaskRecord?.task?._id}
						deliverableRecord={deliverableRecord}
						activeRowKey={deliverableRecord?._id}
						extraData={deliverableExtraData}
						currentProject={projectInfo}
						user={props.params?.user}
						userRole={props.params?.userRole}
						taskRecord={phaseTaskRecord?.task}
					/>
					<TemplateDisabledModal
						open={templateModalOpen}
						setOpen={setTemplateModalOpen}
						handleTemplateModalSave={handleTemplateModalSave}
						title={templateObject?.title}
						message={templateObject?.message}
						id={templateObject?.id}
						level={templateObject?.level}
					/>
				</div>
			)}
			{!projectInfo && !isLoading && (
				<NoAccess text={"This project does not exist."} />
			)}
			{!wellInfo && projectInfo && !isLoading && (
				<NoAccess text={"This well does not exist."} />
			)}
		</>
	);
}

OperationSettings.propTypes = {
	params: PropTypes.any,
};

export default OperationSettings;
