import { Button, Flex, Form, Input, Space } from "antd";
import React, { useState } from "react";
import { GetAntIcon } from "../utils/ant_icons";

/**
 * Input Multiple for form fields
 * @param {Object} props
 * @param {string} props.name - The name of the form field
 * @param {string} props.label - The label of the form field
 * @param {string} props.placeholder - The placeholder of the form field
 * @param {string} props.value - The value of the form field
 * @param {string} props.onChange - The onChange event of the form field
 * @param {string} props.onPressEnter - The onPressEnter event of the form field
 */

interface InputMultipleProps {
	name: string;
	label: string;
	description: string;
	placeholder: string;
	required: boolean;
	setFormReset: (value: boolean) => void;
	// value: string;
	// onChange: (value: string) => void;
	// onPressEnter: (value: string) => void;
}

function RemoveItemButton({ handleRemove }: { handleRemove: () => void }) {
	return (
		<span
			className="clickable-text"
			style={{ cursor: "pointer", margin: "auto", padding: "2px" }}
			onClick={() => handleRemove()}
		>
			{GetAntIcon("close")}
		</span>
	);
}

function InputMultiple({
	name,
	label,
	description,
	placeholder,
	required,
	setFormReset,
}: InputMultipleProps) {
	const [inputValue, setInputValue] = useState<string>("");

	const handleAdd = (value: string, add: (item: any) => void) => {
		if (value) {
			add({ name: value, checked: false });
			setInputValue("");
			setFormReset(false);
		}
	};

	return (
		<>
			<div>{description}</div>
			<Form.Item label={label} required={required}>
				<Form.List name={name}>
					{(fields, { add, remove }) => (
						<>
							<Input
								autoComplete="off"
								value={inputValue}
								placeholder={placeholder}
								onChange={(e) => {
									setInputValue(e.target.value);
								}}
								onPressEnter={(e) =>
									handleAdd(e.currentTarget.value.trim(), add)
								}
								onBlur={(e) => handleAdd(e.currentTarget.value.trim(), add)}
								style={{ marginBottom: "10px" }}
							/>
							{fields.map((field: any) => (
								<Flex
									key={field.key}
									justify="space-between"
									align="center"
									gap="10px"
								>
									<Form.Item
										{...field}
										name={[field.name, "name"]}
										required={required}
										style={{ flex: "1" }}
									>
										<Input
											autoComplete="off"
											onChange={() => setFormReset(false)}
										/>
									</Form.Item>
									<Form.Item>
										<RemoveItemButton
											handleRemove={() => {
												remove(field.name);
												setFormReset(false);
											}}
										/>
									</Form.Item>
								</Flex>
							))}
						</>
					)}
				</Form.List>
			</Form.Item>
		</>
	);
}

export default InputMultiple;
