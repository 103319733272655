import { useContext, useEffect, useMemo, useState } from "react";
import { HomeContext } from "../containers/Home";

function usePermission(permission: string, currentProjectCode: string | null = null): any {

    const context: any = useContext(HomeContext);


    const hasPermission = useMemo(() => {
        if (context.userInfo && context.userRole && context.rolePermissionsData) {
            if (context.userInfo.userRole.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)) {
                return true;
            }
            let role: any = null;
            if (!Array.isArray(context.userRole)) {
                role = context.userRole.role;
            } else {
                if (currentProjectCode) {
                    const _role = context.userRole.find((role: any) => role.project_code === currentProjectCode);
                    if (_role) {
                        role = _role.role;
                    }
                } else {
                    if (context.userRole.some((role: any) => role.role === "project_manager")) role = "project_manager";
                    else if (context.userRole.some((role: any) => role.role === "team_members")) role = "team_members";
                    else if (context.userRole.some((role: any) => role.role === "general_members")) role = "general_members";
                }
            }
            console.log('User role', role)

            if (role === "guest") {
                return false;
            } else {
                const _role = context.rolePermissionsData.find((roleItem: any) => roleItem.key === role);
                if (_role) {
                    if (_role?.permissions?.includes(permission)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        }
        return null;
    }, [context.userInfo, context.userRole, context.rolePermissionsData])

    return hasPermission;
}

export default usePermission;
