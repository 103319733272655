import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, Space, Tabs } from "antd";
import { HomeContext } from "../Home";
import CRUDTabTableModal from "../../components/CRUDTabTableModal";
import TenantSettings from "../TenantSettings";
import { GetAntIcon } from "../../utils/ant_icons";
import { checkSuperAdminPriviledge } from "../../utils/utils";
import PermissionSettings from "../PermissionSettings";
import TenantModuleConfiguration from "../../utils/CRUDA/tenant_module_configuration";
import TenantModuleConfigModal from "../../components/Modal/TenantModuleConfigModal";
import Emitter from "../../utils/emitter";
import { set } from "lodash";
import { getAllModules } from "../../services/api-server/module";

function AdminPage(props: any) {
	const context: any = useContext(HomeContext);
	const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
	const [isTenantAdmin, setIsTenantAdmin] = useState<boolean>(false);
	const [tabItems, setTabItems] = useState<any>([]);
	const [tabkey, setTabKey] = useState(() => {
		return sessionStorage.getItem("admin-tabkey") || undefined;
	});
	const [secondaryTabKey, setSecondaryTabkey] = useState(() => {
		return sessionStorage.getItem("secondary-tabkey") || undefined;
	});

	const [tenantModuleConfigModalOpen, setTenantModuleConfigModalOpen] =
		useState<boolean>(false);

	const [tenantModuleRecord, setTenantModuleRecord] = useState<any>(null);
	const [allModules, setAllModules] = useState<any>(null);

	useEffect(() => {
		const fetchModules = async () => {
			try {
				const values = await getAllModules();
				setAllModules(values);
				// console.log("values: ", values);
			} catch (error) {
				console.error("Failed to fetch modules:", error);
			}
		};

		fetchModules();

		const handleTenantModuleConfigModal = (data: any) => {
			setTenantModuleConfigModalOpen(true);
			setTenantModuleRecord(data);
			console.log("tenantModuleRecord", data);
		};
		Emitter.on("tenantModuleConfigModalOpen", handleTenantModuleConfigModal);
		return () => {
			Emitter.off("tenantModuleConfigModalOpen", handleTenantModuleConfigModal);
		};
	}, []);

	useEffect(() => {
		if (context.allData && context.currentTenantData) {
			let email = context.userInfo.user.email;
			let isSuperAdmin = checkSuperAdminPriviledge(props.params.userRole);
			let isTenantAdmin = context?.currentTenantData?.tenant_admin
				?.map((adminEmail: string) => adminEmail.toLowerCase())
				.includes(email.toLowerCase());
			let tabItems: any = [
				isSuperAdmin && { label: "Management", key: "tenant-manager" },
				{ label: "Tenant settings", key: "tenant-settings" },
				(isSuperAdmin || isTenantAdmin) && {
					label: "Permission settings",
					key: "permission-settings",
				},
				{ label: "Role", key: "roles-manager" },
				{
					label: (
						<Dropdown
							menu={{
								onClick: (info: any) => {
									handleTabDropdown("dropdown-lookup-manager", info.key);
								},
								items: [
									{ label: "Global", key: "0" },
									{ label: "Tenant specific", key: "1" },
								],
							}}
							trigger={["click"]}
						>
							<span>Lookup manager {GetAntIcon("downarrow")}</span>
						</Dropdown>
					),
					key: "dropdown-lookup-manager",
				},
				{ label: "Template", key: "template" },
			].filter(Boolean);

			if (tabkey === undefined) {
				setTabKey(tabItems[0].key);
			}
			setTabItems(tabItems);
			setIsTenantAdmin(isTenantAdmin);
			setIsSuperAdmin(isSuperAdmin);
		}
	}, [context]);

	const pageContent = () => {
		switch (tabkey) {
			// If `tabKey` is `tenant-manager`, return the `CRUDTabTableModal`
			case "tenant-manager":
				return (
					<CRUDTabTableModal
						items={[
							{ label: "Tenant management", key: "tenant" },
							// { label: "Module management", key: "module" },
						]}
						tabkey={tabkey}
						user={props.params?.user}
					/>
				);
			case "tenant-settings":
				return <TenantSettings params={props.params} />;
			case "permission-settings":
				return <PermissionSettings params={props.params} />;
			case "dropdown-lookup-manager":
				if (secondaryTabKey == "0") {
					return (
						<CRUDTabTableModal
							items={[
								// { label: "Country", key: "country" },
								{ label: "Operation type", key: "operation_type" },
								{ label: "Well purpose", key: "well_purpose" },
								{ label: "Well status", key: "well_status" },
							]}
							tabkey={"global-lookup"}
							user={props.params?.user}
							readOnly={isTenantAdmin && !isSuperAdmin}
							headerName={"Configurations (Global)"}
						/>
					);
				} else if (secondaryTabKey == "1") {
					return (
						<CRUDTabTableModal
							items={[
								{ label: "Company", key: "company" },
								{ label: "Operating office", key: "operating_office" },

								{
									label: "Tag manager",
									key: "project_tags",
									directoryName: "Tag manager",
								},
								{ label: "Region", key: "region" },
								{ label: "Software", key: "software" },
								// {
								// 	label: "Strategic business unit",
								// 	key: "strategic_business_unit",
								// },
							]}
							tabkey={"tenant-lookup"}
							user={props.params?.user}
							headerName={"Configurations (Tenant specific)"}
						/>
					);
				} else {
					return null;
				}

			case "roles-manager":
				return (
					<CRUDTabTableModal
						items={[
							{
								label: "Role",
								// key: "roles_permissions",
								key: "general_roles",
								directoryName: "Role directory",
								configurationName: "Role configuration",
							},
						]}
						tabkey={tabkey}
						user={props.params?.user}
					/>
				);
			case "template":
				return (
					<CRUDTabTableModal
						items={[
							{
								label: "Well programme",
								key: "well_programme_template",
								directoryName: "Well programme directory",
								configurationName: "Well programme configuration",
								tableAuditLog: {
									identifierKeys: {},
									label: "Well programme template",
									type: "well_programme_template",
								},
							},
						]}
						tabkey={tabkey}
						user={props.params?.user}
					/>
				);

			default:
				return <></>;
		}
	};

	const handleTabDropdown = async (key: any, secondaryKey: any) => {
		try {
			await context.handlePageChange();
			setTabKey(key);
			console.log(secondaryKey);
			setSecondaryTabkey(secondaryKey);
			sessionStorage.setItem("admin-tabkey", key);
			sessionStorage.setItem("secondary-tabkey", secondaryKey);
			sessionStorage.removeItem("tabkey");
		} catch {}
	};

	const handleTabChange = async (key: any) => {
		if (!key.includes("dropdown")) {
			try {
				await context.handlePageChange();
				setTabKey(key);
				sessionStorage.setItem("admin-tabkey", key);
				sessionStorage.removeItem("tabkey");
				sessionStorage.removeItem("secondary-tabkey");
			} catch {}
		}
	};

	return (
		<>
			<div className="generic-container" style={{ gap: "10px" }}>
				<Space direction="vertical">
					<span
						style={{
							fontSize: "20px",
							fontWeight: "600",
							color: "rgba(255, 255, 255, 0.85)",
						}}
					>
						{/* {props.params.proptitle} */}
					</span>
				</Space>

				<Tabs
					onChange={handleTabChange}
					activeKey={tabkey}
					items={tabItems}
				></Tabs>

				<>{pageContent()}</>
			</div>

			<TenantModuleConfigModal
				open={tenantModuleConfigModalOpen}
				setOpen={setTenantModuleConfigModalOpen}
				tenantModuleRecord={tenantModuleRecord}
				setTenantModuleRecord={setTenantModuleRecord}
				extraData={{ modules: allModules || [] }}
			/>
		</>
	);
}

export default AdminPage;
