import { Button, Tooltip } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import TimeEntryModalRevamp from "./Modal/TimeEntryModalRevamp";

interface CalendarCellProps {
	day: string;
	timeEntries?: any;
	className?: string;
	item?: any;
	closeFutureDate?: boolean;
	cellClickable?: boolean;
}

function TimeEntryButton({
	timeEntry,
	showModal,
	setTimeEntry,
}: {
	timeEntry: any;
	showModal: (isOpen: boolean) => void;
	setTimeEntry: (timeEntry: any) => void;
}) {
	return (
		<Button
			className={`${
				!timeEntry.software_switch || timeEntry.software_switch === false
					? "ant-btn-primary"
					: "ant-btn-software"
			} ${
				["submitted", "approved", "resubmitted", "rejected"].includes(
					timeEntry.status
				) && "timewriting-submitted"
			}`}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				showModal(true);
				setTimeEntry(timeEntry);
			}}
			type="primary"
			style={{ width: "86px" }}
		>
			{timeEntry.duration_hours}h {timeEntry.duration_mins}m
		</Button>
	);
}

function CalendarCell({
	day,
	timeEntries,
	className,
	item,
	closeFutureDate = false,
	cellClickable = true,
}: CalendarCellProps) {
	const [openModal, setOpenModal] = useState<boolean>(false);

	const isFutureDate = closeFutureDate
		? dayjs(day).isAfter(dayjs().add(10, "days"))
		: false;

	const [activeRecord, setActiveRecord] = useState<any>(null);

	const showModal = (isOpen: boolean) => {
		setOpenModal(isOpen);
		!isOpen && setActiveRecord(null);
	};

	return (
		<>
			<TimeEntryModalRevamp
				timeEntry={activeRecord}
				openModal={openModal}
				showModal={showModal}
			/>
			<Tooltip
				title={
					isFutureDate
						? "Time entry is restricted to past and current dates only. Future dates are not permitted."
						: ""
				}
			>
				<div
					className={`cell ${className} ${isFutureDate && "cell-disabled"}`}
					onClick={(e) => {
						if (isFutureDate) return;
						if (!cellClickable) return;
						e.preventDefault();
						showModal(true);
						console.log("cell clicked", activeRecord);
						const defaultTimeEntry = {
							tenant_key: item.tenant_key,
							project_id: item._id,
							date: day,
							duration_hours: 0,
							duration_mins: 0,
						};
						setActiveRecord(defaultTimeEntry);
					}}
				>
					{timeEntries.length > 0 &&
						timeEntries.map((timeEntry: any) => (
							<TimeEntryButton
								timeEntry={timeEntry}
								showModal={showModal}
								setTimeEntry={setActiveRecord}
							/>
						))}
				</div>
			</Tooltip>
		</>
	);
}

export default CalendarCell;
