import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Flex, Modal, Space } from "antd";
import { useEffect, useState } from "react";
import Emitter from "../utils/emitter";

function LeaveWithChangesModal(props: any) {
	const [open, setOpen] = useState<any>(false);

	useEffect(() => {
		// if a form is updating and page has change, trigger the open modal
		if (props.isFormUpdating && props.pageChange) {
			setOpen(true);
		}
	}, [props.isFormUpdating, props.pageChange]);

	return (
		<Modal
			open={open}
			closable={false}
			maskClosable={false}
			cancelButtonProps={{ ghost: true }}
			cancelText="Leave"
			okText="Continue editing"
			okType="default"
			rootClassName="leave-changes-modal"
			onOk={() => {
				setOpen(false);
				Emitter.emit("stay", null);
			}}
			onCancel={() => {
				setOpen(false);
				props.setFormUpdating(false);
				Emitter.emit("leave", null);
			}}
		>
			<Space>
				<Flex align="center" gap={20}>
					<ExclamationCircleOutlined style={{ color: "grey" }} />
					Leave without saving? Changes will not be saved.
				</Flex>
			</Space>
		</Modal>
	);
}

export default LeaveWithChangesModal;
