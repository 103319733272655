import { Form, Tabs } from "antd";
import { useForm } from "antd/es/form/Form";
import { useContext, useEffect, useState } from "react";
import CustomForm from "../../components/CustomForm";
import FormButtonCancel from "../../components/FormButtonCancel";
import FormButtonSave from "../../components/FormButtonSave";
import { HomeContext } from "../Home";
import useUpdateAllData from "../../hooks/useUpdateAllData";
import { updateDeliverableRoles } from "../../services/api-server/deliverables";
import { updateProjectItem } from "../../services/api-server/projects";
import Emitter from "../../utils/emitter";
import { getCrudaClass } from "../../utils/lookup_list";
import { getUserRole } from "../../services/api-server/roles_permissions";
import { Help } from "@mui/icons-material";

const ProjectTeam = (props: any) => {
	const context: any = useContext(HomeContext);
	const currentProject = props.currentProject;
	const [allData, setAllData] = useState<any>(props.data || {});

	const { updateAllData } = useUpdateAllData();

	const [formRef]: any = useForm();
	const [formReset, setFormReset] = useState<boolean>(true);
	const [initialForm, setInitialForm] = useState<any>(null);
	const [filteredDataOptions, setFilteredDataOptions] = useState<any>(null);
	const [tabKey, setTabKey] = useState<string>("roles-configuration");

	const extraTabItems = [
		{ label: "Team roles", key: "roles-configuration" },
		{ label: "Time writing", key: "timewriting_approval" },
	];

	useEffect(() => {
		if (currentProject) {
			let project = allData?.projects?.find(
				(item: any) => item.project_code == currentProject
			);
			let initialFormData: any = {
				project_manager: project?.project_manager,
				role_assignments: project?.role_assignments,
				general_members: project?.general_members,
				main_approver: project?.main_approver,
				secondary_approver: project?.secondary_approver,
				software: project?.software,
			};

			let usersData = {
				project_managers: [...allData.users],
				role_assignments: [...allData.users],
				general_members: [...allData.users],
				approvers: [...allData.users],
				softwares: [...context.allData.software],
			};

			// console.log("usersData", usersData);

			setAllData((prevData: any) => ({
				...prevData,
				...usersData,
			}));

			// console.log("allData", allData, "props.data: ", props.data);

			resetForm(initialFormData, true, { ...allData, ...usersData });
		}
	}, [tabKey]);

	const handleFormCancel = () => {
		if (currentProject) {
			let project = allData.projects.find(
				(item: any) => item.project_code == currentProject
			);
			const initialData = {
				project_manager: project.project_manager,
				role_assignments: project.role_assignments,
				general_members: project.general_members,
				main_approver: project?.main_approver,
				secondary_approver: project?.secondary_approver,
				software: project?.software,
			};
			resetForm(initialData);
		} else {
			resetForm();
		}
	};

	const handleFormSave = (values: any, type: string) => {
		const unlinkRoleInDeliverables = (deletedRoles: any) => {
			deletedRoles.map((role: any) => {
				const roleId = role.role_id;
				updateDeliverableRoles(context?.currentTenantKey, roleId).catch(
					(error: any) => {
						console.log("Error of update deliverable roles", error);
					}
				);
			});
		};

		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				values[key] = values[key].trim();
			}
		});

		Emitter.emit("loading", true);

		getCrudaClass(tabKey).then((crudaClass: any) => {
			const form = crudaClass.getFormList(tabKey);
			const formList: any = [];
			form.forEach((element: any) => {
				if (element.children && !("name" in element)) {
					element.children.forEach((child_element: any) => {
						if (child_element.name) formList.push(child_element.name);
					});
				} else {
					if (element.name) formList.push(element.name);
				}
			});

			if (currentProject) {
				// console.log(
				// 	"values: ",
				// 	values,
				// 	"Initial form",
				// 	initialForm,
				// 	"formList",
				// 	formList
				// );
				updateProjectItem(
					context?.currentTenantKey,
					currentProject,
					values,
					initialForm,
					props.user,
					formList
				)
					.then((updatedItem: any) => {
						console.log("updatedItem: ", updatedItem);
						let project = context.allData.projects.find(
							(item: any) => item.project_code == currentProject
						);
						project.role_assignments = values?.role_assignments;
						context.allData.projects = context.allData.projects.map(
							(item: any) =>
								item.project_code == currentProject ? updatedItem : item
						);
						allData.projects = allData.projects.map((item: any) =>
							item.project_code == currentProject ? updatedItem : item
						);
						setAllData(allData);

						Emitter.emit("alert", {
							type: "success",
							message: "Project has been successfully updated.",
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						resetForm(values);
						props?.handleProjectConfigSave();

						if (tabKey === "roles-configuration") {
							const missingRoles =
								values?.role_assignments?.filter((role: any) => {
									if (context.allData?.roles) {
										return !context.allData?.roles.find(
											(assignment: any) => assignment?._id == role?.role_id
										);
									}
								}) || [];

							// compare initial data and values and get the removed roles from initial data if any
							const removedRoles =
								initialForm?.role_assignments?.filter(
									(role: any) =>
										!values?.role_assignments?.find(
											(assignment: any) => assignment?.role_id == role?.role_id
										)
								) || [];

							// combine missing roles and removed roles
							removedRoles?.push(...missingRoles);
							unlinkRoleInDeliverables(removedRoles);
						}
						updateAllData(updatedItem, "update", "projects");
					})
					.finally(() => {
						Emitter.emit("loading", false);
					});
			}
		});

		getUserRole(context?.currentTenantKey, props.user.email)
			.then((data: any) => {
				context.setUserRole(data);
			})
			.catch((error) => {
				console.log("Fail to update user role!", error);
			});
	};

	const resetForm = (
		values: any = null,
		reset: any = true,
		_allData: any = null
	) => {
		if (formRef) {
			setFormReset(true);
			formRef.resetFields();
			formRef.setFieldsValue(values);

			if (values) {
				const data = { ...(_allData || allData) };
				if (values["general_members"] && data["general_members"]) {
					data["general_members"] = data["general_members"].filter(
						(member: any) => !values["general_members"]?.includes(member.mail)
					);
				}
				if (tabKey === "timewriting_approval") {
					data["approvers"] = data["approvers"].filter(
						(member: any) =>
							!values["main_approver"]?.includes(member.mail) &&
							!values["secondary_approver"]?.includes(member.mail)
					);
				}
				setFilteredDataOptions({ ...data });
			}
			formRef.setFieldsValue(values);
		}
	};

	const changeExtraTabContent = (tabKey: string) => {
		switch (tabKey) {
			case "roles-configuration":
				return (
					<CustomForm
						setFormReset={setFormReset}
						tabKey={"roles-configuration"}
						formRef={formRef}
						dataOptions={allData}
						filteredDataOptions={filteredDataOptions || allData}
					/>
				);
			case "timewriting_approval":
				return (
					<CustomForm
						setFormReset={setFormReset}
						tabKey={"timewriting_approval"}
						formRef={formRef}
						dataOptions={allData}
						filteredDataOptions={filteredDataOptions || allData}
					/>
				);
			default:
				return <>No content</>;
		}
	};

	return (
		<div
			className="main-container"
			style={{
				width: "70%",
				display: "flex",
				flexDirection: "column",
				borderRadius: "3px",
			}}
		>
			<div className="generic-header" style={{ height: "50px" }}>
				Settings
			</div>
			<div
				className="generic-content"
				style={{
					flex: "1",
					gap: "0px",
					maxHeight: "100%",
					overflow: "hidden",
					flexWrap: "nowrap",
				}}
			>
				<Tabs
					tabPosition="left"
					activeKey={tabKey}
					onChange={(key: any) => setTabKey(key)}
					items={extraTabItems}
				></Tabs>
				<div
					style={{
						flex: "1",
						height: "100%",
						display: "flex",
						maxWidth: "100%",
						overflow: "auto",
						flexDirection: "column",
					}}
				>
					{changeExtraTabContent(tabKey)}
				</div>
			</div>
			<div className="generic-footer">
				<>
					<FormButtonCancel
						form={formRef}
						handleFormCancel={handleFormCancel}
						formReset={formReset}
					></FormButtonCancel>
					<FormButtonSave
						form={formRef}
						setInitialForm={setInitialForm}
						handleFormSave={handleFormSave}
						formReset={formReset}
					></FormButtonSave>
				</>
			</div>
		</div>
	);
};

export default ProjectTeam;
